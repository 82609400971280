<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card mb-4">
          <div class="card-header pb-0">
            <h6 class="text-capitalize">
              {{ $t("Files.fileTitle", { element: route.params.element }) }}
            </h6>
          </div>
          <div class="card-body px-0 pt-0">
            <NDataTable :columns="columns" :data="data" :loading="inProgress" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, onMounted, h, ref } from "vue";
import { useRoute } from "vue-router";
import { NDataTable } from "naive-ui";
import { useI18n } from "vue-i18n";
import {
  USER_FILES,
  inProgress,
  getUserFiles,
} from "../../composables/files/list";
import TableCell from "../../components/FilesTable/TableCell";
import TableCellDownload from "../../components/FilesTable/TableCellDownload";
import TableHeader from "../../components/FilesTable/TableHeader";

const { t } = useI18n();
const route = useRoute();

const filesInSeries = ref([]);
const columns = computed(() => {
  return [
    {
      key: "name",
      title(column) {
        return h(TableHeader, {
          text: t("Files.fileHeader"),
        });
      },
      render: (row) => {
        return h(TableCell, {
          text: row.title,
          url: row.url,
          type: row.type,
        });
      },
    },
    {
      key: "action",
      title: null,
      render: (row) => {
        return h(TableCellDownload, {
          text: row.title,
          url: row.url,
          id: row.id,
        });
      },
    },
  ];
});
const data = computed(() => {
  const files = filesInSeries.value || [];
  return files.map((nth) => {
    return {
      title: nth.displayName,
      type: nth.fileType,
      url: nth.fileUrl,
      id: nth.id,
    };
  });
});

onMounted(async () => {
  if (!USER_FILES.value?.files) await getUserFiles();

  const filesInElements =
    (USER_FILES.value?.files || []).find(
      (nth) => nth.element === route.params.element
    ).files || [];
  filesInSeries.value = filesInElements.filter(
    (file) => file.fileSeries === route.params.series
  );
});
</script>
