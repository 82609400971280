import { ref, computed, watch } from "vue";
import { query } from "gql-query-builder";
import HttpClient from "project-blue-http-client";
import { GQL_QUERIES } from "../../utils/APIs";
//import { BE_SERVICE_URL } from "../utils/constants";

export const MOUNTED_PUBLIC_SUPPLY_DATA = ref(null);
export const inProgress = ref(false);
export const reqSignal = ref(new AbortController());

export const getPublicSupplyData = async ({company, country, element, product}) => {
    const gqlQuery = GQL_QUERIES.GET_PUBLIC_SUPPLY_DATA;
    reqSignal.value.abort();
    reqSignal.value = new AbortController();
    inProgress.value = true;
    const payload = query([
      {
        operation: gqlQuery.operation,
        variables: {
          input: {
            value: {
                company,
                country,
              element,
              product
            },
            type: gqlQuery.input,
            required: true,
          },
        },
        fields: gqlQuery.fields,
      },
    ]);

    inProgress.value = false;
};