<template>
  <NPopselect
    v-model:value="SELECTED_ELEMENT_TO_MAP"
    :options="MAP_ELEMENT_OPTS"
    size="medium"
    scrollable
    :style="`width: ${width}px`"
    :show="showMenu"
    @update:value="newElementSelected"
  >
    <div class="card" ref="card">
      <div class="p-3 card-body">
        <div class="d-flex flex-row-reverse justify-content-between">
          <div
            class="avatar avatar-md proxima-blue-bg-gradient border-radius-md"
          >
            <span class="text-lg text-bold text-white">{{
              getElementSymbol(selectedElement?.value)
            }}</span>
          </div>
          <div>
            <p
              class="mb-0 text-sm text-capitalize font-weight-bold proxima-blue-text"
            >
              {{ $t("ProximaList.elementCard.title") }}
            </p>
            <h5
              class="mb-0 font-weight-bolder proxima-blue-text"
              v-if="selectedElement"
            >
              {{ selectedElement?.label }}
            </h5>
            <span v-else class="text-sm text-muted">
              {{ $t("ProximaList.elementCard.notFound") }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </NPopselect>
</template>

<script setup>
import { ref, onMounted, nextTick, computed } from "vue";
import { NPopselect } from "naive-ui";
import { useI18n } from "vue-i18n";
import {
  MAP_ELEMENT_OPTS,
  SELECTED_ELEMENT_TO_MAP,
} from "../../composables/maps";

const { t } = useI18n();
const card = ref(null);
const width = ref(null);
const showMenu = ref(undefined);
const selectedElement = computed(() => {
  return MAP_ELEMENT_OPTS.find(
    (nth) => nth.value === SELECTED_ELEMENT_TO_MAP.value
  );
});

onMounted(() => {
  width.value = card.value?.offsetWidth || 200;
});

const newElementSelected = () => {
  nextTick(() => {
    showMenu.value = undefined;
  });
};

const getElementSymbol = (code) => {
  if (code === "Graphite") return t("ProximaList.elementCard.GraphiteCode");
  else if (code === "SALT") return t("ProximaList.elementCard.SaltCode");
  return code;
};
</script>
