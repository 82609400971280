<template>
  <div
    :class="[
      route.name === ROUTES.FILES.name
        ? 'min-height-400 proxima-blue-banner-image'
        : 'min-height-300',
    ]"
    class="position-absolute w-100 proxima-blue-banner"
  ></div>
  <aside
    class="d-none d-xl-block my-3 overflow-auto border-0 sidenav navbar navbar-vertical navbar-expand-xs border-radius-xl fixed-start ms-3 bg-white"
    id="sidenav-main"
  >
    <div class="sidenav-header">
      <RouterLink class="m-0 navbar-brand" to="/">
        <img
          :src="logo"
          class="navbar-brand-img w-100"
          :alt="t('App.sidebarMenu.appName')"
        />
      </RouterLink>
    </div>
    <hr class="mt-0 horizontal dark" />
    <SidenavList />
  </aside>
</template>

<script setup>
import SidenavList from "./SidenavList.vue";
import logo from "../../assets/proxima-logo-new.png";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";
import { ROUTES } from "../../routes/names";

const route = useRoute();
const { t } = useI18n();
</script>

<style lang="scss" scoped>
.sidenav-header {
  height: fit-content !important;
}
.sidenav .navbar-brand {
  padding: 1.5rem 2rem;
}
.navbar-vertical .navbar-brand > img,
.navbar-vertical .navbar-brand-img {
  height: 2.2rem;
  max-height: none;
}
</style>
