import { ref, computed } from "vue";
import { query } from "gql-query-builder";
import HttpClient from "project-blue-http-client";
import { GQL_QUERIES } from "../../utils/APIs";
import { ROUTES } from "../../routes/names";
import {
  SUBSCRIPTION_ELEMENTS_OPTS,
} from "../../composables/subscription";

export const MOUNTED_COUNTRY_PROFILE = ref(null);
export const MOUNTED_COUNTRY_PROFILES = ref(null);
export const MOUNTED_GENERAL_COUNTRY_PROFILE = ref(null);
export const COUNTRY_COMMODITIES = ref(null);
export const MOUNTED_COUNTRIES = ref(null);
export const COUNTRY_ID = ref(null);
export const inProgress = ref(false);
export const reqSignal = ref(new AbortController());

export const fields = [
  "id",
  "uuid",
  "alias",
  "countryCode",
  "countryName",
  "geology",
  "other",
  "ppd",
  "production",
];

export const getCountryProfile = async ({ element, uuid, code }) => {
  const gqlQuery = GQL_QUERIES.GET_SUBSCRIBED_COUNTRY_PROFILE;
  reqSignal.value.abort();
  reqSignal.value = new AbortController();
  inProgress.value = true;

  let client = new HttpClient(gqlQuery.baseUrl);
  client.get(`${gqlQuery.controller}/${gqlQuery.operation}/${uuid ?? code}`)
    .then(response => {
      MOUNTED_COUNTRY_PROFILE.value = response;
      inProgress.value = false;
    });
};

export const getPublicCountries = async (element) => {
  const gqlQuery = GQL_QUERIES.GET_PUBLIC_COUNTRIES;
  reqSignal.value.abort();
  reqSignal.value = new AbortController();
  inProgress.value = true;
 
  let client = new HttpClient(gqlQuery.baseUrl);
  client.get(`${gqlQuery.controller}/${gqlQuery.operation}`)
  .then(response => {
    MOUNTED_COUNTRIES.value = response;
    inProgress.value = false;
  });
};

export const getCountryProfiles = async (element) => {
  const gqlQuery = GQL_QUERIES.GET_COUNTRY_PROFILES;
  reqSignal.value.abort();
  reqSignal.value = new AbortController();
  inProgress.value = true;

  let url = `${gqlQuery.controller}/${gqlQuery.operation}`
  if (element != null) {
    url = `${gqlQuery.controller}/${gqlQuery.operation}/${element}`;
  }

  let client = new HttpClient(gqlQuery.baseUrl);
  client.get(url)
    .then(response => {
      MOUNTED_COUNTRY_PROFILES.value = response.data;
      inProgress.value = false;
    });
};

const getElementName = (el) => {
  return SUBSCRIPTION_ELEMENTS_OPTS.value.filter(e => e.value === el).map(r => r.label)[0]
}

export const getCountryCommodities = async (country) => {
  let opts = MOUNTED_COUNTRY_PROFILES.value?.profiles?.filter(r => r.country === country).map(r => {
    if (r.commodity === 'Cathode') {
      return { label: "Cathode", value: "Cathode" }
    }
    return {
      label: getElementName(r.commodity),
      value: r.commodity
    }
  }) || []
  COUNTRY_COMMODITIES.value = opts.sort((a, b) => a.label.localeCompare(b.label))
}

export const getGeneralCountryProfile = async ({ code, element, uuid }) => {

  const gqlQuery = GQL_QUERIES.GET_GENERAL_COUNTRY_PROFILE;
  reqSignal.value.abort();
  reqSignal.value = new AbortController();
  inProgress.value = true;

  let client = new HttpClient(gqlQuery.baseUrl);
  client.get(`${gqlQuery.controller}/${gqlQuery.operation}/${code}`)
    .then(response => {
      MOUNTED_GENERAL_COUNTRY_PROFILE.value = response;
      inProgress.value = false;
    });
}

export const mountProfile = async (
  router,
  { profile, element, uuid = null } = {}
) => {
  MOUNTED_COUNTRY_PROFILE.value = profile;
  router.push({
    name: ROUTES.COUNTRY_PROFILE.name,
    params: { id: profile?.uuid || uuid },
    query: { element },
  });
};
