
export const MARKET_SERVICE_CATEGORY = {
  EV_AND_BATTERY: "EV_AND_BATTERY",
  STEEL_AND_ALLOYS: "STEEL_AND_ALLOYS",
  INDUSTRY_MINERALS: "INDUSTRY_MINERALS",
  TECHNOLOGY_METALS: "TECHNOLOGY_METALS",
  CRITICAL_MATERIALS: "CRITICAL_MATERIALS",
  ENERGY_TRANSITION: "ENERGY_TRANSITION",
  GEOPOLITICS: "GEOPOLITICS",
  ESG_AND_SUSTAINABILITY: "ESG_AND_SUSTAINABILITY",
  AEROSPACE_METALS: "AEROSPACE_METALS",
}

export const marketServices = [
  "antimony",
  "chromium",
  "cobalt",
  "fluorspar",
  "fluorine",
  "gallium",
  "graphite",
  "germanium",
  "iron",
  "lithium",
  "lithium_industry_cost_service",
  // "lithium_ion_batteries",
  "manganese",
  "molybdenum",
  "nickel",
  "niobium",
  "scandium",
  "vanadium",
  "silicon",
  "ree",
  "salt",
  "magnesium",
  "tantalum",
  "tin",
  "titanium",
  "tungsten",
];
export const generalSEOs = [
  "magnesium shortage",
  "uranium news (this can be made into a category)",
  "china oil tariff (can be made into a tag/category under news related to the keyword)",
  "russia oil exports (can be made into a tag/category under news related to the keyword)",
  "natural gas storage report",
  "utility index",
  "what are indices",
  "turmoil exchange trading nickel used lithiumion",
  "diesel price forecast long-term",
  "market for steel",
  "what are rins",
  "esg regulations",
  "the global mine metal future",
  "the role of critical minerals in clean energy transitions",
];
export const marketServicesSEOs = {
  antimony: [
    "what is antimony",
    "what is antimony used for",
    "what is antimony used for in everyday life",
    "how much does antimony cost",
    "how much does antimony cost per gram",
  ],
  chromium: [
    "what is chromium",
    "chromium sulfide",
    "what is the charge of chromium",
    "what is chromium element",
    "how many electrons does chromium have",
  ],
  cobalt: [
    "cobalt",
    "cobalt price",
    "covid cobalt",
    "cobalt prices",
    "cobalt mineral",
    "raw cobalt",
    "cobalt and coral",
    "price of cobalt per kg",
  ],
  fluorine: [
    "fluorine",
    "fluorine uses",
    "fluorine price",
    "fluorine stocks",
    "fluorine mining stocks",
    "fluorine market",
  ],
  fluorspar: [
    "fluorspar",
    "fluorspar uses",
    "fluorspar price",
    "fluorspar stocks",
    "fluorspar mining stocks",
    "fluorspar market",
  ],
  gallium: [
    "what is gallium",
    "what is gallium used for",
    "how much is gallium worth",
    "where to buy gallium",
    "what is gallium worth",
    "how many electrons does gallium have",
    "how much is gallium worth per gram",
    "how much is gallium worth per ounce",
    "what is the cost of gallium per gram",
    "why is gallium so expensive",
  ],
  graphite: [
    "graphite",
    "steel vs graphite irons",
    "graphite mineral",
    "is graphite a mineral",
    "is graphite an element",
    "is graphite a metal",
    "is graphite magnetic",
    "what is graphite made of",
    "where does graphite come from",
    "how is graphite made",
  ],
  iron: [
    "what is iron",
    "what does iron look like",
    "where is iron ore found",
    "how to identify iron",
    "what color is iron",
    "how to turn iron into iron",
    "how to make iron",
  ],
  lithium: [
    "lithium",
    "where is lithium mined",
    "how does lithium interact with other elements",
    "where does lithium come from",
    "what is a lithium battery",
    "how do lithium ion batteries work",
    "how to mine lithium",
    "which element has similar properties to lithium",
  ],
  lithium_industry_cost_service: [
    "lithium industry cost service"
  ],
  lithium_ion_batteries: [
    "how to dispose of lithium ion batteries",
    "how long do lithium ion batteries last",
    "usa lithium battery manufacturers",
    "what are lithium ion batteries made of",
    "price of lithium per ton",
    "how to store lithium ion batteries",
    "how much is lithium worth",
    "how to store lithium-ion batteries safely",
    "historical lithium prices",
    "investing in lithium batteries",
    "lithium in africa",
    "how to fix lithium ion batteries",
    "what is in lithium ion batteries",
    "how do you dispose of lithium ion batteries",
  ],
  manganese: [
    "electron configuration for manganese",
    "magnesium vs manganese",
    "manganese battery",
    "manganese fertilizer",
    "what is the difference between magnesium and manganese",
    "what color is manganese",
    "how to fix manganese deficiency in plants",
    "how to test for manganese in water",
    "how much is manganese worth",
  ],
  molybdenum: [
    "molybdenum",
    "what is molybdenum used for",
    "molybdenum price",
    "molybdenum in food",
    "molybdenum price per pound",
    "molybdenum mining",
    "molybdenum mines",
    "molybdenum grease vs lithium grease",
    "how many electrons does molybdenum have",
  ],
  nickel: [
    "how many grams does a nickel weigh",
    "where is nickel mined",
    "why is nickel up",
    "future of nickel",
    "nickel price lme",
    "nickel price forecast",
    "why are nickel prices going up",
  ],
  niobium: [
    "niobium element",
    "niobium price",
    "niobium news",
    "niobium stock",
    "what is niobium used for",
    "niobium vs titanium",
  ],
  scandium: [
    "scandium",
    "what is scandium used for",
    "scandium prices",
    "is scandium a metal",
    "scandium facts",
    "is scandium a transition metal",
    "scandium interesting facts",
    "what does scandium look like",
  ],
  vanadium: [
    "vanadium electron configuration",
    "vanadium supplement",
    "what is vanadium",
    "vanadium steel",
    "vanadium price",
    "vanadium benefits",
    "is vanadium a metal or nonmetal",
  ],
  silicon: ["silicon valence electrons", "what type of element is silicon"],
  ree: [],
  salt: ["is salt a compound or element"],
  magnesium: [],
  tantalum: [],
  tin: [],
  titanium: [],
  tungsten: [],
};
export const marketServicesCategories = {
  antimony: [
    MARKET_SERVICE_CATEGORY.INDUSTRY_MINERALS,
    MARKET_SERVICE_CATEGORY.TECHNOLOGY_METALS,
  ],
  chromium: [
    MARKET_SERVICE_CATEGORY.STEEL_AND_ALLOYS,
    MARKET_SERVICE_CATEGORY.AEROSPACE_METALS,
  ],
  cobalt: [
    MARKET_SERVICE_CATEGORY.EV_AND_BATTERY,
    MARKET_SERVICE_CATEGORY.AEROSPACE_METALS,
  ],
  fluorspar: [
    MARKET_SERVICE_CATEGORY.EV_AND_BATTERY,
    MARKET_SERVICE_CATEGORY.INDUSTRY_MINERALS,
  ],
  fluorine: [
    MARKET_SERVICE_CATEGORY.EV_AND_BATTERY,
    MARKET_SERVICE_CATEGORY.INDUSTRY_MINERALS,
  ],
  gallium: [
    MARKET_SERVICE_CATEGORY.TECHNOLOGY_METALS,
  ],
  graphite: [
    MARKET_SERVICE_CATEGORY.EV_AND_BATTERY,
    MARKET_SERVICE_CATEGORY.INDUSTRY_MINERALS,
  ],
  iron: [
    MARKET_SERVICE_CATEGORY.EV_AND_BATTERY,
    MARKET_SERVICE_CATEGORY.STEEL_AND_ALLOYS,
  ],
  lithium: [
    MARKET_SERVICE_CATEGORY.EV_AND_BATTERY,
  ],
  lithium_industry_cost_service: [
    MARKET_SERVICE_CATEGORY.EV_AND_BATTERY,
  ],
  lithium_ion_batteries: [
    MARKET_SERVICE_CATEGORY.EV_AND_BATTERY,
  ],
  manganese: [
    MARKET_SERVICE_CATEGORY.EV_AND_BATTERY,
    MARKET_SERVICE_CATEGORY.STEEL_AND_ALLOYS,
  ],
  molybdenum: [
    MARKET_SERVICE_CATEGORY.STEEL_AND_ALLOYS,
  ],
  nickel: [
    MARKET_SERVICE_CATEGORY.EV_AND_BATTERY,
    MARKET_SERVICE_CATEGORY.STEEL_AND_ALLOYS,
  ],
  niobium: [
    MARKET_SERVICE_CATEGORY.STEEL_AND_ALLOYS,
    MARKET_SERVICE_CATEGORY.AEROSPACE_METALS,
  ],
  scandium: [
    MARKET_SERVICE_CATEGORY.STEEL_AND_ALLOYS,
    MARKET_SERVICE_CATEGORY.AEROSPACE_METALS,
  ],
  vanadium: [
    MARKET_SERVICE_CATEGORY.STEEL_AND_ALLOYS,
  ],
  silicon: [
    MARKET_SERVICE_CATEGORY.STEEL_AND_ALLOYS,
    MARKET_SERVICE_CATEGORY.TECHNOLOGY_METALS,
  ],
  ree: [
    MARKET_SERVICE_CATEGORY.EV_AND_BATTERY,
    MARKET_SERVICE_CATEGORY.TECHNOLOGY_METALS,
  ],
  salt: [
    MARKET_SERVICE_CATEGORY.INDUSTRY_MINERALS,
  ],
  magnesium: [
    MARKET_SERVICE_CATEGORY.INDUSTRY_MINERALS, 
    MARKET_SERVICE_CATEGORY.AEROSPACE_METALS,
  ],
  tantalum: [
    MARKET_SERVICE_CATEGORY.AEROSPACE_METALS,
    MARKET_SERVICE_CATEGORY.TECHNOLOGY_METALS,
  ],
  tin: [
    MARKET_SERVICE_CATEGORY.TECHNOLOGY_METALS,
  ],
  titanium: [
    MARKET_SERVICE_CATEGORY.AEROSPACE_METALS,
    MARKET_SERVICE_CATEGORY.INDUSTRY_MINERALS,
  ],
  tungsten: [
    MARKET_SERVICE_CATEGORY.AEROSPACE_METALS,
    MARKET_SERVICE_CATEGORY.TECHNOLOGY_METALS,
  ],
}
export const marketServicesTags = {
  antimony: [
    "Antimony",
    "Automotive",
    "Batteries",
  ],
  chromium: [
    "Chromium",
    "Steel & Alloys", 
  ],
  cobalt: [
    "Aerospace",
    "Automotive",
    "Batteries",
    "Cobalt", 
    "Energy storage",
    "Steel & Alloys" 
  ],
  fluorspar: [
    "Fluorine",
    "Steel & Alloys"
  ],
  fluorine: [
    "Fluorine",
    "Steel & Alloys"
  ],
  gallium: [
    "Gallium",
    "Electronics & Semiconductors",
  ],
  graphite: [
    "Graphite",
    "Automotive",
    "Batteries",
    "Steel & Alloys"
  ],
  iron: [
    "Batteries",
    "Steel & Alloys",
    "Iron ore"
  ],
  lithium: [
    "Automotive",
    "Batteries",
    "Energy storage",
    "Lithium",
  ],
  lithium_industry_cost_service: [
    "Automotive",
    "Batteries",
    "Energy storage",
    "Lithium",
  ],
  lithium_ion_batteries: [
    "Automotive",
    "Batteries",
    "Energy Storage",
    "Lithium-ion",
  ],
  manganese: [
    "Manganese",
    "Automotive",
    "Batteries",
    "Steel & Alloys", 
  ],
  molybdenum: [
    "Molybdenum", 
    "Steel & Alloys"
  ],
  nickel: [
    "Automotive",
    "Batteries",
    "Energy Storage",
    "Nickel",
    "Steel & Alloys"
  ],
  niobium: [
    "Automotive",
    "Batteries",
    "Energy Storage",
    "Niobium",
    "Steel & Alloys"
  ],
  scandium: [
    "Aerospace",
    "Scandium",
  ],
  vanadium: [
    "Batteries",
    "Energy Storage",
    "Vanadium",
    "Steel & Alloys"
  ],
  silicon: [
    "Batteries",
    "Silicon",
    "Solar",
  ],
  ree: [
    "Rare Earths",
    "Automotive",
    "Wind", 
  ],
  salt: ["Salt"],
  magnesium: [
    "Magnesium",
    "Aerospace",
  ],
  tantalum: [
    "Tantalum",
    "Electronics & Semiconductors"
  ],
  tin: [
    "Tin",
    "Automotive",
    "Electronics & Semiconductors"
  ],
  titanium: [
    "Titanium",
    "Aerospace",
    "Steel & alloys"
  ],
  tungsten: [
    "Tungsten", 
    "Aerospace",
    "Steel & alloys"
  ],
}

export const CEO_ROLE = "Founder & Director"
export const employeesInformation = [{
  "name": "Jack Bedder",
  "prefix": "Dr",
  "position": CEO_ROLE,
  "elements": ["Co","Mn", "Nb", "Sb", "Sc", "V", ],
  "image": "Jack_Bedder.jpg",
  "link": "https://www.linkedin.com/in/jackbedder/",
  "titles": [
    "PhD, Land Economy, University of Cambridge",
    "MA, International Relations, University College London",
    "BA (Hons) Politics, University College London"
  ],
  "summary": "Jack is an economist and political scientist with over 15 years’ experience of analysing the economics of natural resources and leading commodity research and consultancy.\n\nBefore founding Project Blue, he was Head of Research at Roskill, leading its global team of analysts.  Before that, he worked in public policy in both the private and public sectors.\n\nHe has a PhD from the University of Cambridge where his research explored the behaviours which shape critical materials supply chains.  He is a Fellow of the Royal Geographical Society and a Professional Member of the IOM3."
},
{
  "name": "Nils Backeberg",
  "prefix": "Dr",
  "position": CEO_ROLE,
  "elements": [
    "Cr",
    "F",
    "Mn",
    "Mo",
    "REE",
    "Sb",
    "Si",
  ],
  "image": "Nils_Backeberg.jpg",
  "link": "https://www.linkedin.com/in/nrbackeberg/",
  "titles": [
    "CSci, Institute of Materials, Minerals and Mining",
    "PhD, Earth & Planetary Sciences, McGill University",
    "MSc, Geological Sciences, University of Cape Town"
  ],
  "summary": "Nils is a structural-economic geologist with over 10 years’ experience in mineral economics, incorporating academic posts, research and consulting leadership, and field experience.\n\nBefore co-founding Project Blue, he was Head of Steel Alloys Research at Roskill.  Before that his experience includes a post-doc at University College London, gold market and cost analysis at Metals Focus, and work as an exploration geologist in South Africa, Canada, and Guatemala.\n\nHe holds a PhD from McGill University and gained his Chartered Scientist accreditation from the IOM3 in 2019, reflecting his research expertise and commitment to presenting his research in peer-reviewed journals and at international conferences. "
},
{
  "name": "Steven Seget",
  "suffix": "MBA",
  "position": CEO_ROLE,
  "elements": ["Salt", "Li-ion"],
  "image": "Steven_Seget.jpg",
  "link": "https://www.linkedin.com/in/steven-seget-29b6665/",
  "titles": [
    "MBA, London Business School",
    "BSc, Finance/Accounting, London School of Economics",
  ],
  "summary": "Steven is an economist with over 20 years of experience in the consulting and business information industries, having managed research teams at Datamonitor, Reuters and Informa.\n\nPrior to joining Project Blue, Steven was Director of Strategy and Finance at Roskill, as well as being a member of the company’s global board.  Before that Steven worked in private equity providing financial and forecasting expertise to a number of high-profile investments.\n\nSteven has an MBA from the London Business School.",
},
{
  "name": "Akhil Rampersadh",
  "position": "Research Analyst",
  "elements": ["REE", "Sc"],
  "image": "Akhil_Rampersadh.jpg",
  "link": "https://www.linkedin.com/in/akhil-rampersadh-068682120/",
  "titles": [
    "BSc (Hons), Geological Sciences, University of Cape Town",
    "BSc, Geology/Oceanography, University of Cape Town"
  ],
  "summary": "Akhil joined Project Blue in 2022, where he has focussed on the analysis of REE and scandium supply chains.\n\nAkhil is a graduate geoscientist, currently pursuing his PhD in Geology at the University of Cape Town.​ Prior to joining Project Blue, he completed a petroleum geoscience internship at Tullow Oil, where his work included offshore basin analysis, seismic data interpretation and hydrocarbon lead mapping in West Africa. Akhil has over five years of academic research experience focussing on sedimentology, stratigraphy and Mesozoic basin evolution in southern Africa and has participated in several geoscientific fieldtrips, presented his research at international conferences and co-authored multiple peer-reviewed journal papers.\n\nOutside of academia, he partakes in various outreach initiatives involving science awareness, communication, and education.",
},
{
  "name": "Alison Saxby",
  "position": "Research Director",
  "elements": ["Al2H2O4","B", "C", "F", "Mg", "Salt", "Ti", "Zr"],
  "image": "Alison_Saxby.jpg",
  "link": "https://www.linkedin.com/in/alison-saxby-02957b19/",
  "titles": [
    "BEng Hons in Mineral Engineering",
    "Member IOM3 (MIMMM)"
  ],
  "summary": "Alison is an industry leading expert with over 35 years of experience in industrial minerals and metals.She was previously Managing Director Roskill, and prior to that held roles at Fastmarkets (Industrial Minerals) and as an independent consultant. Alison was recently a member of the UK government’s Critical Materials Global Expert Mission to both Canada and the USA for Innovate UK-KTN and works with Edumine to provide educational courses on critical materials. She also sits on the board of Pensana as Independent Non-Executive Director and are the Vice-Chair of the Technical Communities Board of the industry professional organisation, the IOM3.",
},
{
  "name": "David Merriman",
  "position": "Research Director",
  "elements": [
    "C",
    "Co",
    "Li",
    "Li-ion",
    "REE"
  ],
  "image": "David_Merriman.jpg",
  "link": "https://www.linkedin.com/in/david-merriman-09492726/",
  "titles": [
    "MSc, Mining Geology, Camborne School of Mines",
    "BSc, Geology, University of Birmingham",
  ],
  "summary": "David joined Project Blue in November 2022 and is focussed on developing its coverage of EV and battery materials markets including lithium, cobalt, graphite, and rare earths.\n\nPrior to joining Project Blue, David worked as a Research Director at Wood Mackenzie leading the rare earths market research and as Manager of the Battery and Electric Vehicle Materials division at Roskill.  David has in excess of 10 years’ experience covering the rare earth and battery materials markets and is a frequent speaker at industry conferences.\n\nDavid has also worked as an exploration geologist at a rare earth project in Tanzania and at a copper-gold operation in Chile. ",
},
{
  "name": "Edith Zhang",
  "position": "Research Analyst",
  "elements": ["Mn", "REE"],
  "image": "Edith_Zhang.jpg",
  "link": "https://www.linkedin.com/in/%E4%BC%9A%E6%9D%B0-%E5%BC%A0-6087b9262/",
  "titles": [
    "PhD Candidate, Stellenbosch University",
    "MA, Modern World History, Shanghai Normal University",
    "BA, World History, Henan Normal University",
  ],
  "summary": "Edith joined Project Blue in 2022 and focusses mainly on manganese and REEs supply chains in China with a focus on lithium-ion.\n\nPrior to joining Project Blue, Edith worked as a mandarin teacher at Confucius Institute of University of Botswana. Edith has taken active role in promoting China-Africa cooperation and exchange in culture, business, and tourism.\n\nEdith is a PhD student of the Center for HIV/AIDS Management at Stellenbosch University.",
},
{
  "name": "Erik Sardain",
  "position": "Principal Analyst",
  "elements": [
    "Fe",
    "Mo",
    "Nb",
    "V"
  ],
  "image": "Erik_Sardain.jpg",
  "link": "https://www.linkedin.com/in/erik-sardain-36aa6037/",
  "titles": [
    "MA, Economics, University of Geneva",
    "MA, Politics/International Relations, University of Geneva"
  ],
  "summary": "Erik has over 25 years of experience in the commodity space, with managerial positions in the finance and the mining industry.\n\nPrior joining Project Blue, Erik was Principal Consultant at Roskill, covering steel, iron ore and steel alloys, primarily vanadium and niobium. Before that, Erik was Head of Global Research and analysis for iron ore at BHP in Singapore with a focus on market intelligence, project management and strategy.\n\nErik has also worked as Investment Analyst and Portfolio Manager for major financial institutions in Europe, Asia, and North America, focusing on natural resources and commodities.  He has spent more than fifteen years in Asia and has been involved in many projects in China, India, and Southeast Asia.",
},
// {
//   "name": "Euston Witbooi",
//   "suffix": "MBA",
//   "position": "Research Analyst",
//   "elements": ["F", "Salt"],
//   "image": "Euston_Witbooi.jpg",
//   "link": "https://www.linkedin.com/in/euston-witbooi-54100b33/",
//   "titles": [
//     "MBA, Graduate School of Business, University of Cape Town",
//     "BSc (Hons), Geology, Nelson Mandela University",
//     "BSc, Geology/Environmental Sciences, University of Cape Town",
//   ],
//   "summary": "Euston joined Project Blue as a Research Analyst in 2022 and leads analysis of fluorine and salt supply chains.\n\nHe has 10 years of experience working in geology.  Prior to joining Project Blue, Euston worked as a senior geologist for International Mining and Dredging Holdings (IMDH), a South African-based offshore diamond mining company. Before joining IMDH, Euston worked for Remote Exploration Services as an exploration geologist on various commodity projects across the African continent and internationally.",
// },
{
  "name": "Francesco Iacoviello",
  "prefix": "Dr",
  "position": "Research Analyst",
  "elements": ["Ga", "Sn"],
  "image": "Francesco_Iacoviello.jpg",
  "link": "https://www.linkedin.com/in/francescoiacoviello/",
  "titles": [
    "PhD, Mineralogy and geochemistry, University of Siena",
    "MSc, Environmental geochemistry, University of Siena",
    "BSc, Environmental geochemistry, University of Siena",
  ],
  "summary": "Francesco joined Project Blue as a Research Analyst in 2022, where he focusses on analysis of the gallium and tin supply chains.\n\nFrancesco is an experienced geoscientist with a background in paleoclimate research and mineralogy. His research broadly encompasses the multi-scale X-ray Computed Tomography characterisation of a different array of materials, from electrochemical devices to geomaterials such as shale gas rock, carbon capture and storage systems and micrometeorites.\n\nHe is a Fellow of the Geological Society of London. ",
},
{
  "name": "Howard Head",
  "prefix": "Dr",
  "position": "Research Analyst",
  "elements": ["Mg", "Li-ion", "Li"],
  "image": "Howard_Head.jpg",
  "link": "https://www.linkedin.com/in/howard-head-a6750013b/",
  "titles": [
    "PhD, Geology, University of Cape Town",
    "BSc (Hons), Geology, University of Cape Town"
  ],
  "summary": "Howard joined Project Blue as a Research Analyst in 2022, focusing on magnesium supply chains.  He has also spent time working on lithium-ion battery supply chains, with a focus on the mid- to downstream market.\n\nHoward is a geologist with five years working experience in alluvial diamond mining and exploration in Namibia, where he managed several drilling and sampling projects. He completed his PhD in sedimentology and stratigraphy at the University of Cape Town in 2022 and has taught undergraduate geology courses, run field trips, and has published numerous peer-reviewed articles related to his research on the Jurassic deposits of southern Africa. ",
},
{
  "name": "Jack Anderson",
  "position": "Research Manager",
  "elements": [
    "Mo", 
    "Nb", 
    "Ni", 
    "Ta", 
    "Si", 
    "V", 
  ],
  "image": "Jack_Anderson.jpg",
  "link": "https://www.linkedin.com/in/jack-anderson-492365a1/",
  "titles": [
    "MSci, Geoscience, Royal Holloway, University of London"
  ],
  "summary": "Jack joined Project Blue in 2022 and is responsible for building out nickel market analysis in collaboration with the battery materials team.\n\nPrior to Project Blue, Jack was a Senior Analyst at Wood Mackenzie within the Steel Alloys team focussed on silicon, vanadium, and niobium markets. He also worked at Roskill for seven years during which time he became Deputy Manager of the Steel Alloys division and covered a wide range of metals supply chains for the company’s subscription products and consulting assignments. He also helped develop Roskill’s sustainability research and worked on a major nickel ESG study for a major automotive manufacturer’s raw material procurement team.",
},
{
  "name": "Kirk Jacobs",
  "position": "Research Analyst",
  "elements": ["Mo", "Fe"],
  "image": "Kirk_Jacobs.jpg",
  "link": "https://www.linkedin.com/in/kirk-jacobs-885ab7115/",
  "titles": [
    "MSc, Applied Geology, University of the Western Cape",
    "BSc (Hons), Applied Geology, University of the Western Cape"
  ],
  "summary": "Kirk joined Project Blue as a Research Analyst in 2022, focussing mainly on the analysis of the molybdenum and iron ore supply chains.\n\nPrior to joining Project Blue, Kirk worked for a start-up, Wave Innovate, as a database and online store manager. During his undergraduate and postgraduate studies, Kirk was an active member in student chapters such as the American Association of Petroleum Geologists (AAPG), Geological Society of South Africa (GSSA) and Society of Petroleum Engineers (SPE).  ",
},
{
  "name": "Kyle Pronk",
  "position": "Research Analyst",
  "elements": ["Li-ion", "Salt"],
  "image": "Kyle_Pronk.jpg",
  "link": "https://www.linkedin.com/in/kyle-pronk-20a6a21b6/",
  "titles": [
    "MSc Candidate, Geology, Stellenbosch University",
    "BSc, Earth Sciences/Geology, Stellenbosch University",
  ],
  "summary": "Kyle began joined Project Blue in 2022, focussing mainly on the lithium-ion battery supply chain, with a focus on downstream assets.  He has also spent time working on salt market analysis.\n\nKyles is currently undertaking an MSc in Earth Sciences/Geology, working on heavy mineral placer deposits on the West Coast of Southern Africa",
},
{
  "name": "Ludmila Centkova",
  "position": "Marketing and CRM Administrator",
  "elements": null,
  "image": "Ludmila_Centkova.jpg",
  "link": "https://www.linkedin.com/in/ludmila-centkova-564b2b127/",
  "titles": [
    "MA, Corporate Business and Marketing, University of Economics in Bratislava",
  ],
  "summary": "Ludmila joined Project Blue in 2023 as a Marketing and CRM Administrator. Before Project Blue, Ludmila worked as Marketing & Database Administrator at Roskill and was responsible for maintaining a company CRM, data entry and onboarding clients. She also worked as Account Assistant in a manufacturing company and has extensive experience in providing sales and administrative support.",
},
{
  "name": "Luke Adriaans",
  "position": "Research Analyst",
  "elements": ["W", "Sn"],
  "image": "Luke_Adriaans.jpg",
  "link": "https://www.linkedin.com/in/luke-adriaans-b37a25137/",
  "titles": [
    "MSc, Applied Geology, University of the Western Cape",
    "BSc, Applied Geology, University of the Western Cape",
  ],
  "summary": "Luke joined Project Blue as a Research Analyst in 2022, focussing mainly on mainly on analysis of the tungsten supply chain. Before joining Project Blue, Luke worked for five years alongside his studies as a physics and geology tutor and physical science practical coordinator.  His background includes igneous and metamorphic petrology, geochemistry, structural geology, and economic geology.  He has presented his research at local conferences and has published in an international journal.",
},
{
  "name": "Luke Allum",
  "position": "Research Analyst",
  "elements": ["Co", 
    "Li", 
    "Li-ion",
    "Ni", ],
  "image": "Luke_allum.jpg",
  "link": "https://www.linkedin.com/in/lukeallum/",
  "titles": [
    "MSc, Sustainable Resources, University College London",
    "BSc, Geology, Cardiff University"
  ],
  "summary": "Luke joined Project Blue in July 2022 as its first intern, focussing on the lithium-ion battery supply chain and specifically recycling market dynamics.\n\nPrior to joining Project Blue, Luke was self-employed as an outdoor pursuits guide alongside his studies and operated in Europe and the Middle East.\n\nLuke is a fellow at the Geological Society of London and a student member of the Institute of Materials, Minerals and Mining (IOM3).",
},
{
  "name": "Marcio Goto",
  "position": "Business Development – Latin America",
  "elements": null,
  "image": "Marcio_Goto.jpg",
  "link": "https://www.linkedin.com/in/marcio-goto-00275a5/",
  "titles": [
    "Business Management and MBA, FGV, FIA, Brazil",
    "Mine Feasibility Specialization, Paris School of Mines",
    "Mining Engineer, University of São Paulo",
  ],
  "summary": "Marcio joined Project Blue in 2022, leading business development and customer support in Latin America.  He is based in São Paulo, Brazil.\n\nPrior to joining Project Blue, Marcio worked in similar roles at CRU International and Roskill.  He has also worked in engineering and consulting at Multigeo, at mining companies including Camargo Corrêa and Alcoa, and at Brazil’s National Mining Agency.\n\nMarcio is registered as a mining engineer with the Brazil Engineering Council (CREA) and speaks fluent English, Spanish and Portuguese. ",
},
{
  "name": "Melissa Oosthuizen",
  "position": "Research Analyst",
  "elements": null,
  "image": "Melissa_Oosthuizen.jpg",
  "link": "https://www.linkedin.com/in/melissa-oosthuizen-geology/",
  "titles": [
    "BSc (Hons), Geology, University of Cape Town", 
    "BSc, Geology/Environmental and Geographical Science, University of Cape Town" 
  ],
  "summary": "Melissa joined Project Blue as a Research Analyst in 2023, focusing on the tin supply chain.She is an experienced academic researcher, copy editor, and writer with over three years of experience in science communication and publishing and almost a decade of research experience across various fields.Before joining Project Blue, she worked as a subject matter expert while providing her services as an ESL editor to international clients and high-impact, peer-reviewed academic journals.",
},
{
  "name": "Michael Dixon",
  "position": "Business Development – APAC",
  "elements": null,
  "image": "Michael_Dixon.jpg",
  "link": "https://www.linkedin.com/in/michaeldixonnrgandresources/",
  "titles": [
    "Institute of Chartered Accountants, England & Wales", 
    "BSc (Hons), Zoology, University of Newcastle upon Tyne" 
  ],
  "summary": "Michael joined Project Blue to run business development and commercial activities for APAC in 2022.  He is based in Sydney, Australia.\n\nPrior to joining Project Blue, Michael had many years of activity in metals and mining business and commercial development, market analysis, consultancy, and private equity, including expert witness work in corporate arbitrations, as well as producing market reports for major transactions in copper, gold, rare earths, iron ore, coal, and base metals. Michael’s experience includes employment at Bloomberg, AME Research, Roskill, and Wood Mackenzie. ",
},
{
  "name": "Naomi Harrisankar",
  "position": "Research Analyst",
  "elements": ["Ti"],
  "image": "Naomi_Harrisankar.jpg",
  "link": "https://www.linkedin.com/in/naomi-harrisankar/",
  "titles": [
    "PhD Candidate, Chem. Engineering, University of Cape Town",
    "MSc, Chemical Engineering, University of Cape Town",
    "BSc, Chemical Engineering, University of Cape Town",
  ],
  "summary": "Naomi joined Project Blue as a Research Analyst in November 2022, focussing mainly on analysis of the titanium supply chain.\n\nPrior to joining Project Blue, Naomi worked on a part-time basis for Yellow Tree Environmental, an environmental engineering consulting firm as a junior environmental engineer and consultant. On a full-time basis, Naomi is currently completing her PhD in chemical engineering in the catalysis and green engineering fields.",
},
{
  "name": "Qiling Ying",
  "prefix": "Dr",
  "position": "Research Analyst",
  "elements": ["Li", "Ni"],
  "image": "Qiling_Ying.jpg",
  "link": "https://www.linkedin.com/in/qiling-ying-b721614a/",
  "titles": [
    "PhD, Chemistry/Fuel Cell, University of the Western Cape",
    "MSc, Chemistry/Fuel Cell, University of the Western Cape",
    "BSc, Chem. Eng., Guangxi University of Science & Tech",
  ],
  "summary": "Qiling joined Project Blue in 2022 and focuses mainly on the analysis of the lithium and nickel supply chains.\n\nQiling has over 15 years of research experience in synthesising multicomponent metal nano-clusters with different structures, which applies to fuel cells. She worked as a post-doctoral researcher at the Chemical Engineering Department of the University of Cape Town, where she designed and developed high-performance nano-catalysts for EVs.",
},
{
  "name": "Reitumetse Chalale",
  "position": "Research Analyst",
  "elements": ["C", "Co"],
  "image": "Reitumetse_Chalale.jpg",
  "link": "https://www.linkedin.com/in/reitumetse-chalale-a50200162/",
  "titles": [
    "MSc, Economic Geology, University of the Witwatersrand",
    "BSc, Geology, University of the Witwatersrand"
  ],
  "summary": "Reitumetse joined Project Blue as a Research Analyst in 2022, focussing on the cobalt and graphite supply chains.\n\nReitumetse is an economic geologist with work experience in ArcGIS, geology, and research.  Prior to joining Project Blue, she was a Research Associate for CIMERA DSI-NRF at the University of Witwatersrand. Her role required data management, cataloguing and identification of rocks and minerals from different geological settings. ",
},
{
  "name": "Sam Kiri",
  "position": "Business Development - North America",
  "elements": null,
  "image": "Sam_Kiri.jpg",
  "link": "https://www.linkedin.com/in/sam-kiri-948b36143/",
  "titles": [
    "CFA, CFA Institute, Charlottesville"
  ],
  "summary": "Sam joined Project Blue in 2022, leading business development and customer support in North America.  He is based in Toronto, Canada.\n\nSam has over 20 years capital markets and asset management experience in North America, the UK and the Pacific Rim, with primary focus on the mining and oil and gas sectors. He spent four years with Scotia Bank as an equity analyst and was involved in the management of the Scotia Pacific Rim Mutual Fund.  Prior to joining Project Blue, Sam was heading Sales for Roskill in Canada and the USA. He was at CRU Group until 2017 in a similar role.\n\nSam is one of the founders of Proactive Investors, a global media network which provides information on Capital Market activities, with considerable focus on the Mining Sector. In addition, he was involved in cross border listing which entails dual listing of ASX/London AIM companies on the Toronto Stock Exchange.",
},
{
  "name": "Sonja Kriedemann",
  "position": "Operations Manager",
  "elements": null,
  "image": "Sonja_Kriedemann.jpg",
  "link": "https://www.linkedin.com/in/sonjakoekemoer/",
  "titles": [
    "Higher Cert: Economics and Management Science, Unisa",
    "Inf. Tech.: A+, N+ and MSCE, CTI"
  ],
  "summary": "Sonja has over 15 years’ experience in the Information Technology Software environment and crowdsourcing and curating ideas to deliver value to external stakeholders.\n\nSonja joined Project Blue in 2022, with overall responsibility for its IT and data platforms both internally and for clients.  Before Project Blue, Sonja was the Product Manager at GuestRevu, leading an international cloud-based application and responsible for the production of all associated products.  Before that, Sonja was Head of IT at a University in South Africa. ",
},
{
  "name": "Tanisha Schulz",
  "position": "Research Analyst",
  "elements": ["Mn", "Cr"],
  "image": "Tanisha_Schultz.jpg",
  "link": "https://www.linkedin.com/in/tanishagraceschultz/",
  "titles": [
    "MSc, Earth Sciences/Geology, Stellenbosch University",
    "BSc, Earth Sciences/Geology, Stellenbosch University" 
  ],
  "summary": "Tanisha joined Project Blue as a Research Analyst in 2022, focussing mainly on analysis of the manganese and chrome supply chains.\n\nPrior to joining Project Blue, Tanisha worked on a part-time basis for pH Consulting through the collection and digitisation of geological maps and data for nickel-copper projects in Western Australia, and lithium projects in Mozambique.   During her studies, Tanisha took active roles in economic geology student chapters with the aim of providing students with knowledge and tools through industry participation.\n\nTanisha is a member of the Society of Economic Geologists and the Geological Society of South Africa. ",
},
{
  "name": "Dominic Wells",
  "position": "Research Manager",
  "elements": [
    "Co",
    "Ni",
    "Li",
    "Li-ion"
  ],
  "image": "Dominic_Wells.jpg",
  "link": "https://www.linkedin.com/in/dominic-wells-0b234386/",
  "titles": [
    "MSc, Advanced Mineral Resources Development, China University of Mining and Technology",
    "BSc (Hons), Geology, University of Brighton"
  ],
  "summary": "Dominic joined Project Blue in 2022 as a Research Manager, leading Project Blue’s cost and ESG analysis.\n\nPrior to joining Project Blue, Dominic worked as a Research Manager at Wood Mackenzie, managing the smelting and refining cost team.  Before that he was a Senior Analyst at Roskill covering the battery metals suite.\n\nDominic has four years of experience in the commodities sector and in that career has covered a wide range of industries. Predominantly he has focused on covering the lithium, cobalt, and nickel sectors though he has also covered several base metals sectors. Through this, he has extensive experience in large-scale consulting projects, working with a wide range of clients across the value chain, as well as cost and ESG analysis and product development in the battery metals sector.",
},
{
  "name": "George Coles",
  "position": "Business Development – Europe and South Africa ",
  "elements": null,
  "image": "George_Coles.jpg",
  "link": "https://www.linkedin.com/in/george-coles-a385aa12/",
  "titles": [
    "BA, Economics, University of Reading"
  ],
  "summary": "George looks after European and South African sales at Project Blue.  Before joining in 2023, George managed global sales at Skarn Associates for one year and European sales at Roskill for four years.\n\nPrior to that he worked in an analytical capacity for 10 years, most recently at Metals Focus, covering gold, silver and PGM markets, with a focus on global mine supply, cost modelling and price forecasts.  Previously, at Thomson Reuters and GFMS, George worked predominantly on their gold cost model service as well as contributing to various publications.",
},
{
  "name": "Rod Zamora",
  "position": "Business Development Manager",
  "elements": null,
  "image": "Rod_Zamora.jpg",
  "link": "https://www.linkedin.com/in/rodzamora/",
  "titles": [
    "PG Dip, Molecular Biology, University of Westminster",
    "BSc, Applied Biology, Liverpool John Moores University",
  ],
  "summary": "Rod joined Project Blue as a Business Development Manager in 2023, with the remit for China, India, the Middle East (except Turkey) Africa (except South Africa) & South East Asia (except Singapore). He is based in London.\n\nPrior to joining Project Blue, Rod worked as a business developer for consultancies such as CRU International and GFMS Reuters in the mining and metals sector as well as Transporeon Group (TIM Consult) and Drewry Maritime Consultants within the logistics consultancy space.\n\nRod originally trained as a scientist before being headhunted to work in The City in the metals and mining sector. Rod speaks fluent Spanish and English.",
},
{
  "name": "Adean Ladia",
  "position": "Development",
  "elements": null,
  "image": "Adean.jpg",
  "link": "https://www.linkedin.com/in/adean-ladia/",
  "titles": [
    "Bsc Information Technology, Mindanao State Univeristy"
  ],
  "summary": "Adean has extensive experience in full-stack and web development. He started as a dev consultant before joining full time in 2022. Adean is mainly responsibility for developing new features and maintaining Proxima Blue's core code-base.\n\nBefore Project Blue, Adean was a senior product developer at AnyMind Group which develops various top-tier influencer platform.",
},
{
  "name": "Jamal Aden",
  "position": "Business Development – North America",
  "elements": null,
  "image": "Jamal.jpg",
  "link": null,
  "titles": [
    "BA, Economics, Wilfrid Laurier University"
  ],
  "summary": "Jamal joined Project Blue in 2022, focussing on business development and customer support in North America.  He is based in Toronto, Canada.\n\nPrior to joining Project Blue, Jamal was covering Sales for Roskill in Canada and the USA.  His experience in sales dates its origins to the insurance industry, where he previously headed insurance sales for Allstate Insurance.  After an initial ten years in sales, he switched to accounting and worked as a financial analyst and accountant in the insurance and reinsurance industry.   ",
},
{
  "name": "Fiona Macdonald",
  "position": "Research Analyst",
  "elements": ["Si", "Li-ion"],
  "image": "Fiona.jpg",
  "link": "https://www.linkedin.com/in/fiona-macdonald-196a10129/",
  "titles": [
    "BSc, Earth Science, Stellenbosch University",
    "BSc, (Hons) Earth Science, Stellenbosch University",
  ],
  "summary": "Fiona joined Project Blue as a Research Analyst in 2022, focussing on a range of supply chains including chromium, lithium, and silicon.  Before and during her time with Project Blue, Fiona worked as a field geologist and consultant in the exploration industry in Zimbabwe.",
},
]

export const COUNTRIES_LIST = [
  {
    name: "Afghanistan",
    code: "AF",
    timezone: "Afghanistan Standard Time",
    utc: "UTC+04:30",
    mobileCode: "+93",
  },
  {
    name: "Åland Islands",
    code: "AX",
    timezone: "FLE Standard Time",
    utc: "UTC+02:00",
    mobileCode: "+358-18",
  },
  {
    name: "Albania",
    code: "AL",
    timezone: "Central Europe Standard Time",
    utc: "UTC+01:00",
    mobileCode: "+355",
  },
  {
    name: "Algeria",
    code: "DZ",
    timezone: "W. Central Africa Standard Time",
    utc: "UTC+01:00",
    mobileCode: "+213",
  },
  {
    name: "American Samoa",
    code: "AS",
    timezone: "UTC-11",
    utc: "UTC-11:00",
    mobileCode: "+1-684",
  },
  {
    name: "Andorra",
    code: "AD",
    timezone: "W. Europe Standard Time",
    utc: "UTC+01:00",
    mobileCode: "+376",
  },
  {
    name: "Angola",
    code: "AO",
    timezone: "W. Central Africa Standard Time",
    utc: "UTC+01:00",
    mobileCode: "+244",
  },
  {
    name: "Anguilla",
    code: "AI",
    timezone: "SA Western Standard Time",
    utc: "UTC-04:00",
    mobileCode: "+1-264",
  },
  {
    name: "Antarctica",
    code: "AQ",
    timezone: "Pacific SA Standard Time",
    utc: "UTC-03:00",
    mobileCode: "+",
  },
  {
    name: "Antigua and Barbuda",
    code: "AG",
    timezone: "SA Western Standard Time",
    utc: "UTC-04:00",
    mobileCode: "+1-268",
  },
  {
    name: "Argentina",
    code: "AR",
    timezone: "Argentina Standard Time",
    utc: "UTC-03:00",
    mobileCode: "+54",
  },
  {
    name: "Armenia",
    code: "AM",
    timezone: "Caucasus Standard Time",
    utc: "UTC+04:00",
    mobileCode: "+374",
  },
  {
    name: "Aruba",
    code: "AW",
    timezone: "SA Western Standard Time",
    utc: "UTC-04:00",
    mobileCode: "+297",
  },
  {
    name: "Australia",
    code: "AU",
    timezone: "AUS Eastern Standard Time",
    utc: "UTC+10:00",
    mobileCode: "+61",
  },
  {
    name: "Austria",
    code: "AT",
    timezone: "W. Europe Standard Time",
    utc: "UTC+01:00",
    mobileCode: "+43",
  },
  {
    name: "Azerbaijan",
    code: "AZ",
    timezone: "Azerbaijan Standard Time",
    utc: "UTC+04:00",
    mobileCode: "+994",
  },
  {
    name: "Bahamas, The",
    code: "BS",
    timezone: "Eastern Standard Time",
    utc: "UTC-05:00",
    mobileCode: "+1-242",
  },
  {
    name: "Bahrain",
    code: "BH",
    timezone: "Arab Standard Time",
    utc: "UTC+03:00",
    mobileCode: "+973",
  },
  {
    name: "Bangladesh",
    code: "BD",
    timezone: "Bangladesh Standard Time",
    utc: "UTC+06:00",
    mobileCode: "+880",
  },
  {
    name: "Barbados",
    code: "BB",
    timezone: "SA Western Standard Time",
    utc: "UTC-04:00",
    mobileCode: "+1-246",
  },
  {
    name: "Belarus",
    code: "BY",
    timezone: "Belarus Standard Time",
    utc: "UTC+03:00",
    mobileCode: "+375",
  },
  {
    name: "Belgium",
    code: "BE",
    timezone: "Romance Standard Time",
    utc: "UTC+01:00",
    mobileCode: "+32",
  },
  {
    name: "Belize",
    code: "BZ",
    timezone: "Central America Standard Time",
    utc: "UTC-06:00",
    mobileCode: "+501",
  },
  {
    name: "Benin",
    code: "BJ",
    timezone: "W. Central Africa Standard Time",
    utc: "UTC+01:00",
    mobileCode: "+229",
  },
  {
    name: "Bermuda",
    code: "BM",
    timezone: "Atlantic Standard Time",
    utc: "UTC-04:00",
    mobileCode: "+1-441",
  },
  {
    name: "Bhutan",
    code: "BT",
    timezone: "Bangladesh Standard Time",
    utc: "UTC+06:00",
    mobileCode: "+975",
  },
  {
    name: "Bolivarian Republic of Venezuela",
    code: "VE",
    timezone: "Venezuela Standard Time",
    utc: "UTC-04:30",
    mobileCode: "+58",
  },
  {
    name: "Bolivia",
    code: "BO",
    timezone: "SA Western Standard Time",
    utc: "UTC-04:00",
    mobileCode: "+591",
  },
  {
    name: "Bonaire, Sint Eustatius and Saba",
    code: "BQ",
    timezone: "SA Western Standard Time",
    utc: "UTC-04:00",
    mobileCode: "+599",
  },
  {
    name: "Bosnia and Herzegovina",
    code: "BA",
    timezone: "Central European Standard Time",
    utc: "UTC+01:00",
    mobileCode: "+387",
  },
  {
    name: "Botswana",
    code: "BW",
    timezone: "South Africa Standard Time",
    utc: "UTC+02:00",
    mobileCode: "+267",
  },
  {
    name: "Bouvet Island",
    code: "BV",
    timezone: "UTC",
    utc: "UTC",
    mobileCode: "+",
  },
  {
    name: "Brazil",
    code: "BR",
    timezone: "E. South America Standard Time",
    utc: "UTC-03:00",
    mobileCode: "+55",
  },
  {
    name: "British Indian Ocean Territory",
    code: "IO",
    timezone: "Central Asia Standard Time",
    utc: "UTC+06:00",
    mobileCode: "+246",
  },
  {
    name: "Brunei",
    code: "BN",
    timezone: "Singapore Standard Time",
    utc: "UTC+08:00",
    mobileCode: "+673",
  },
  {
    name: "Bulgaria",
    code: "BG",
    timezone: "FLE Standard Time",
    utc: "UTC+02:00",
    mobileCode: "+359",
  },
  {
    name: "Burkina Faso",
    code: "BF",
    timezone: "Greenwich Standard Time",
    utc: "UTC",
    mobileCode: "+226",
  },
  {
    name: "Burundi",
    code: "BI",
    timezone: "South Africa Standard Time",
    utc: "UTC+02:00",
    mobileCode: "+257",
  },
  {
    name: "Cabo Verde",
    code: "CV",
    timezone: "Cape Verde Standard Time",
    utc: "UTC-01:00",
    mobileCode: "+238",
  },
  {
    name: "Cambodia",
    code: "KH",
    timezone: "SE Asia Standard Time",
    utc: "UTC+07:00",
    mobileCode: "+855",
  },
  {
    name: "Cameroon",
    code: "CM",
    timezone: "W. Central Africa Standard Time",
    utc: "UTC+01:00",
    mobileCode: "+237",
  },
  {
    name: "Canada",
    code: "CA",
    timezone: "Eastern Standard Time",
    utc: "UTC-05:00",
    mobileCode: "+1",
  },
  {
    name: "Cayman Islands",
    code: "KY",
    timezone: "SA Pacific Standard Time",
    utc: "UTC-05:00",
    mobileCode: "+1-345",
  },
  {
    name: "Central African Republic",
    code: "CF",
    timezone: "W. Central Africa Standard Time",
    utc: "UTC+01:00",
    mobileCode: "+236",
  },
  {
    name: "Chad",
    code: "TD",
    timezone: "W. Central Africa Standard Time",
    utc: "UTC+01:00",
    mobileCode: "+235",
  },
  {
    name: "Chile",
    code: "CL",
    timezone: "Pacific SA Standard Time",
    utc: "UTC-03:00",
    mobileCode: "+56",
  },
  {
    name: "China",
    code: "CN",
    timezone: "China Standard Time",
    utc: "UTC+08:00",
    mobileCode: "+86",
  },
  {
    name: "Christmas Island",
    code: "CX",
    timezone: "SE Asia Standard Time",
    utc: "UTC+07:00",
    mobileCode: "+61",
  },
  {
    name: "Cocos (Keeling) Islands",
    code: "CC",
    timezone: "Myanmar Standard Time",
    utc: "UTC+06:30",
    mobileCode: "+61",
  },
  {
    name: "Colombia",
    code: "CO",
    timezone: "SA Pacific Standard Time",
    utc: "UTC-05:00",
    mobileCode: "+57",
  },
  {
    name: "Comoros",
    code: "KM",
    timezone: "E. Africa Standard Time",
    utc: "UTC+03:00",
    mobileCode: "+269",
  },
  {
    name: "Congo",
    code: "CG",
    timezone: "W. Central Africa Standard Time",
    utc: "UTC+01:00",
    mobileCode: "+242",
  },
  {
    name: "Congo (DRC)",
    code: "CD",
    timezone: "W. Central Africa Standard Time",
    utc: "UTC+01:00",
    mobileCode: "+243",
  },
  {
    name: "Cook Islands",
    code: "CK",
    timezone: "Hawaiian Standard Time",
    utc: "UTC-10:00",
    mobileCode: "+682",
  },
  {
    name: "Costa Rica",
    code: "CR",
    timezone: "Central America Standard Time",
    utc: "UTC-06:00",
    mobileCode: "+506",
  },
  {
    name: "Côte d'Ivoire",
    code: "CI",
    timezone: "Greenwich Standard Time",
    utc: "UTC",
    mobileCode: "+225",
  },
  {
    name: "Croatia",
    code: "HR",
    timezone: "Central European Standard Time",
    utc: "UTC+01:00",
    mobileCode: "+385",
  },
  {
    name: "Cuba",
    code: "CU",
    timezone: "Eastern Standard Time",
    utc: "UTC-05:00",
    mobileCode: "+53",
  },
  {
    name: "Curaçao",
    code: "CW",
    timezone: "SA Western Standard Time",
    utc: "UTC-04:00",
    mobileCode: "+599",
  },
  {
    name: "Cyprus",
    code: "CY",
    timezone: "E. Europe Standard Time",
    utc: "UTC+02:00",
    mobileCode: "+357",
  },
  {
    name: "Czech Republic",
    code: "CZ",
    timezone: "Central Europe Standard Time",
    utc: "UTC+01:00",
    mobileCode: "+420",
  },
  {
    name: "Democratic Republic of Timor-Leste",
    code: "TL",
    timezone: "Tokyo Standard Time",
    utc: "UTC+09:00",
    mobileCode: "+670",
  },
  {
    name: "Denmark",
    code: "DK",
    timezone: "Romance Standard Time",
    utc: "UTC+01:00",
    mobileCode: "+45",
  },
  {
    name: "Djibouti",
    code: "DJ",
    timezone: "E. Africa Standard Time",
    utc: "UTC+03:00",
    mobileCode: "+253",
  },
  {
    name: "Dominica",
    code: "DM",
    timezone: "SA Western Standard Time",
    utc: "UTC-04:00",
    mobileCode: "+1-767",
  },
  {
    name: "Dominican Republic",
    code: "DO",
    timezone: "SA Western Standard Time",
    utc: "UTC-04:00",
    mobileCode: "+1-809 and 1-829",
  },
  {
    name: "Ecuador",
    code: "EC",
    timezone: "SA Pacific Standard Time",
    utc: "UTC-05:00",
    mobileCode: "+593",
  },
  {
    name: "Egypt",
    code: "EG",
    timezone: "Egypt Standard Time",
    utc: "UTC+02:00",
    mobileCode: "+20",
  },
  {
    name: "El Salvador",
    code: "SV",
    timezone: "Central America Standard Time",
    utc: "UTC-06:00",
    mobileCode: "+503",
  },
  {
    name: "Equatorial Guinea",
    code: "GQ",
    timezone: "W. Central Africa Standard Time",
    utc: "UTC+01:00",
    mobileCode: "+240",
  },
  {
    name: "Eritrea",
    code: "ER",
    timezone: "E. Africa Standard Time",
    utc: "UTC+03:00",
    mobileCode: "+291",
  },
  {
    name: "Estonia",
    code: "EE",
    timezone: "FLE Standard Time",
    utc: "UTC+02:00",
    mobileCode: "+372",
  },
  {
    name: "Ethiopia",
    code: "ET",
    timezone: "E. Africa Standard Time",
    utc: "UTC+03:00",
    mobileCode: "+251",
  },
  {
    name: "Falkland Islands (Islas Malvinas)",
    code: "FK",
    timezone: "SA Eastern Standard Time",
    utc: "UTC-03:00",
    mobileCode: "+500",
  },
  {
    name: "Faroe Islands",
    code: "FO",
    timezone: "GMT Standard Time",
    utc: "UTC",
    mobileCode: "+298",
  },
  {
    name: "Fiji Islands",
    code: "FJ",
    timezone: "Fiji Standard Time",
    utc: "UTC+12:00",
    mobileCode: "+679",
  },
  {
    name: "Finland",
    code: "FI",
    timezone: "FLE Standard Time",
    utc: "UTC+02:00",
    mobileCode: "+358",
  },
  {
    name: "France",
    code: "FR",
    timezone: "Romance Standard Time",
    utc: "UTC+01:00",
    mobileCode: "+33",
  },
  {
    name: "French Guiana",
    code: "GF",
    timezone: "SA Eastern Standard Time",
    utc: "UTC-03:00",
    mobileCode: "+594",
  },
  {
    name: "French Polynesia",
    code: "PF",
    timezone: "Hawaiian Standard Time",
    utc: "UTC-10:00",
    mobileCode: "+689",
  },
  {
    name: "French Southern and Antarctic Lands",
    code: "TF",
    timezone: "West Asia Standard Time",
    utc: "UTC+05:00",
    mobileCode: "+",
  },
  {
    name: "Gabon",
    code: "GA",
    timezone: "W. Central Africa Standard Time",
    utc: "UTC+01:00",
    mobileCode: "+241",
  },
  {
    name: "Gambia, The",
    code: "GM",
    timezone: "Greenwich Standard Time",
    utc: "UTC",
    mobileCode: "+220",
  },
  {
    name: "Georgia",
    code: "GE",
    timezone: "Georgian Standard Time",
    utc: "UTC+04:00",
    mobileCode: "+995",
  },
  {
    name: "Germany",
    code: "DE",
    timezone: "W. Europe Standard Time",
    utc: "UTC+01:00",
    mobileCode: "+49",
  },
  {
    name: "Ghana",
    code: "GH",
    timezone: "Greenwich Standard Time",
    utc: "UTC",
    mobileCode: "+233",
  },
  {
    name: "Gibraltar",
    code: "GI",
    timezone: "W. Europe Standard Time",
    utc: "UTC+01:00",
    mobileCode: "+350",
  },
  {
    name: "Greece",
    code: "GR",
    timezone: "GTB Standard Time",
    utc: "UTC+02:00",
    mobileCode: "+30",
  },
  {
    name: "Greenland",
    code: "GL",
    timezone: "Greenland Standard Time",
    utc: "UTC-03:00",
    mobileCode: "+299",
  },
  {
    name: "Grenada",
    code: "GD",
    timezone: "SA Western Standard Time",
    utc: "UTC-04:00",
    mobileCode: "+1-473",
  },
  {
    name: "Guadeloupe",
    code: "GP",
    timezone: "SA Western Standard Time",
    utc: "UTC-04:00",
    mobileCode: "+590",
  },
  {
    name: "Guam",
    code: "GU",
    timezone: "West Pacific Standard Time",
    utc: "UTC+10:00",
    mobileCode: "+1-671",
  },
  {
    name: "Guatemala",
    code: "GT",
    timezone: "Central America Standard Time",
    utc: "UTC-06:00",
    mobileCode: "+502",
  },
  {
    name: "Guernsey",
    code: "GG",
    timezone: "GMT Standard Time",
    utc: "UTC",
    mobileCode: "+44-1481",
  },
  {
    name: "Guinea",
    code: "GN",
    timezone: "Greenwich Standard Time",
    utc: "UTC",
    mobileCode: "+224",
  },
  {
    name: "Guinea-Bissau",
    code: "GW",
    timezone: "Greenwich Standard Time",
    utc: "UTC",
    mobileCode: "+245",
  },
  {
    name: "Guyana",
    code: "GY",
    timezone: "SA Western Standard Time",
    utc: "UTC-04:00",
    mobileCode: "+592",
  },
  {
    name: "Haiti",
    code: "HT",
    timezone: "Eastern Standard Time",
    utc: "UTC-05:00",
    mobileCode: "+509",
  },
  {
    name: "Heard Island and McDonald Islands",
    code: "HM",
    timezone: "Mauritius Standard Time",
    utc: "UTC+04:00",
    mobileCode: "+ ",
  },
  {
    name: "Honduras",
    code: "HN",
    timezone: "Central America Standard Time",
    utc: "UTC-06:00",
    mobileCode: "+504",
  },
  {
    name: "Hong Kong SAR",
    code: "HK",
    timezone: "China Standard Time",
    utc: "UTC+08:00",
    mobileCode: "+852",
  },
  {
    name: "Hungary",
    code: "HU",
    timezone: "Central Europe Standard Time",
    utc: "UTC+01:00",
    mobileCode: "+36",
  },
  {
    name: "Iceland",
    code: "IS",
    timezone: "Greenwich Standard Time",
    utc: "UTC",
    mobileCode: "+354",
  },
  {
    name: "India",
    code: "IN",
    timezone: "India Standard Time",
    utc: "UTC+05:30",
    mobileCode: "+91",
  },
  {
    name: "Indonesia",
    code: "ID",
    timezone: "SE Asia Standard Time",
    utc: "UTC+07:00",
    mobileCode: "+62",
  },
  {
    name: "Iran",
    code: "IR",
    timezone: "Iran Standard Time",
    utc: "UTC+03:30",
    mobileCode: "+98",
  },
  {
    name: "Iraq",
    code: "IQ",
    timezone: "Arabic Standard Time",
    utc: "UTC+03:00",
    mobileCode: "+964",
  },
  {
    name: "Ireland",
    code: "IE",
    timezone: "GMT Standard Time",
    utc: "UTC",
    mobileCode: "+353",
  },
  {
    name: "Israel",
    code: "IL",
    timezone: "Israel Standard Time",
    utc: "UTC+02:00",
    mobileCode: "+972",
  },
  {
    name: "Italy",
    code: "IT",
    timezone: "W. Europe Standard Time",
    utc: "UTC+01:00",
    mobileCode: "+39",
  },
  {
    name: "Jamaica",
    code: "JM",
    timezone: "SA Pacific Standard Time",
    utc: "UTC-05:00",
    mobileCode: "+1-876",
  },
  {
    name: "Jan Mayen",
    code: "SJ",
    timezone: "W. Europe Standard Time",
    utc: "UTC+01:00",
    mobileCode: "+47",
  },
  {
    name: "Japan",
    code: "JP",
    timezone: "Tokyo Standard Time",
    utc: "UTC+09:00",
    mobileCode: "+81",
  },
  {
    name: "Jersey",
    code: "JE",
    timezone: "GMT Standard Time",
    utc: "UTC",
    mobileCode: "+44-1534",
  },
  {
    name: "Jordan",
    code: "JO",
    timezone: "Jordan Standard Time",
    utc: "UTC+02:00",
    mobileCode: "+962",
  },
  {
    name: "Kazakhstan",
    code: "KZ",
    timezone: "Central Asia Standard Time",
    utc: "UTC+06:00",
    mobileCode: "+7",
  },
  {
    name: "Kenya",
    code: "KE",
    timezone: "E. Africa Standard Time",
    utc: "UTC+03:00",
    mobileCode: "+254",
  },
  {
    name: "Kiribati",
    code: "KI",
    timezone: "UTC+12",
    utc: "UTC+12:00",
    mobileCode: "+686",
  },
  {
    name: "Korea",
    code: "KR",
    timezone: "Korea Standard Time",
    utc: "UTC+09:00",
    mobileCode: "+82",
  },
  {
    name: "Kosovo",
    code: "XK",
    timezone: "Central European Standard Time",
    utc: "UTC+01:00",
    mobileCode: "+",
  },
  {
    name: "Kuwait",
    code: "KW",
    timezone: "Arab Standard Time",
    utc: "UTC+03:00",
    mobileCode: "+965",
  },
  {
    name: "Kyrgyzstan",
    code: "KG",
    timezone: "Central Asia Standard Time",
    utc: "UTC+06:00",
    mobileCode: "+996",
  },
  {
    name: "Laos",
    code: "LA",
    timezone: "SE Asia Standard Time",
    utc: "UTC+07:00",
    mobileCode: "+856",
  },
  {
    name: "Latvia",
    code: "LV",
    timezone: "FLE Standard Time",
    utc: "UTC+02:00",
    mobileCode: "+371",
  },
  {
    name: "Lebanon",
    code: "LB",
    timezone: "Middle East Standard Time",
    utc: "UTC+02:00",
    mobileCode: "+961",
  },
  {
    name: "Lesotho",
    code: "LS",
    timezone: "South Africa Standard Time",
    utc: "UTC+02:00",
    mobileCode: "+266",
  },
  {
    name: "Liberia",
    code: "LR",
    timezone: "Greenwich Standard Time",
    utc: "UTC",
    mobileCode: "+231",
  },
  {
    name: "Libya",
    code: "LY",
    timezone: "E. Europe Standard Time",
    utc: "UTC+02:00",
    mobileCode: "+218",
  },
  {
    name: "Liechtenstein",
    code: "LI",
    timezone: "W. Europe Standard Time",
    utc: "UTC+01:00",
    mobileCode: "+423",
  },
  {
    name: "Lithuania",
    code: "LT",
    timezone: "FLE Standard Time",
    utc: "UTC+02:00",
    mobileCode: "+370",
  },
  {
    name: "Luxembourg",
    code: "LU",
    timezone: "W. Europe Standard Time",
    utc: "UTC+01:00",
    mobileCode: "+352",
  },
  {
    name: "Macao SAR",
    code: "MO",
    timezone: "China Standard Time",
    utc: "UTC+08:00",
    mobileCode: "+853",
  },
  {
    name: "Macedonia, Former Yugoslav Republic of",
    code: "MK",
    timezone: "Central European Standard Time",
    utc: "UTC+01:00",
    mobileCode: "+389",
  },
  {
    name: "Madagascar",
    code: "MG",
    timezone: "E. Africa Standard Time",
    utc: "UTC+03:00",
    mobileCode: "+261",
  },
  {
    name: "Malawi",
    code: "MW",
    timezone: "South Africa Standard Time",
    utc: "UTC+02:00",
    mobileCode: "+265",
  },
  {
    name: "Malaysia",
    code: "MY",
    timezone: "Singapore Standard Time",
    utc: "UTC+08:00",
    mobileCode: "+60",
  },
  {
    name: "Maldives",
    code: "MV",
    timezone: "West Asia Standard Time",
    utc: "UTC+05:00",
    mobileCode: "+960",
  },
  {
    name: "Mali",
    code: "ML",
    timezone: "Greenwich Standard Time",
    utc: "UTC",
    mobileCode: "+223",
  },
  {
    name: "Malta",
    code: "MT",
    timezone: "W. Europe Standard Time",
    utc: "UTC+01:00",
    mobileCode: "+356",
  },
  {
    name: "Man, Isle of",
    code: "IM",
    timezone: "GMT Standard Time",
    utc: "UTC",
    mobileCode: "+44-1624",
  },
  {
    name: "Marshall Islands",
    code: "MH",
    timezone: "UTC+12",
    utc: "UTC+12:00",
    mobileCode: "+692",
  },
  {
    name: "Martinique",
    code: "MQ",
    timezone: "SA Western Standard Time",
    utc: "UTC-04:00",
    mobileCode: "+596",
  },
  {
    name: "Mauritania",
    code: "MR",
    timezone: "Greenwich Standard Time",
    utc: "UTC",
    mobileCode: "+222",
  },
  {
    name: "Mauritius",
    code: "MU",
    timezone: "Mauritius Standard Time",
    utc: "UTC+04:00",
    mobileCode: "+230",
  },
  {
    name: "Mayotte",
    code: "YT",
    timezone: "E. Africa Standard Time",
    utc: "UTC+03:00",
    mobileCode: "+262",
  },
  {
    name: "Mexico",
    code: "MX",
    timezone: "Central Standard Time (Mexico)",
    utc: "UTC-06:00",
    mobileCode: "+52",
  },
  {
    name: "Micronesia",
    code: "FM",
    timezone: "West Pacific Standard Time",
    utc: "UTC+10:00",
    mobileCode: "+691",
  },
  {
    name: "Moldova",
    code: "MD",
    timezone: "GTB Standard Time",
    utc: "UTC+02:00",
    mobileCode: "+373",
  },
  {
    name: "Monaco",
    code: "MC",
    timezone: "W. Europe Standard Time",
    utc: "UTC+01:00",
    mobileCode: "+377",
  },
  {
    name: "Mongolia",
    code: "MN",
    timezone: "Ulaanbaatar Standard Time",
    utc: "UTC+08:00",
    mobileCode: "+976",
  },
  {
    name: "Montenegro",
    code: "ME",
    timezone: "Central European Standard Time",
    utc: "UTC+01:00",
    mobileCode: "+382",
  },
  {
    name: "Montserrat",
    code: "MS",
    timezone: "SA Western Standard Time",
    utc: "UTC-04:00",
    mobileCode: "+1-664",
  },
  {
    name: "Morocco",
    code: "MA",
    timezone: "Morocco Standard Time",
    utc: "UTC",
    mobileCode: "+212",
  },
  {
    name: "Mozambique",
    code: "MZ",
    timezone: "South Africa Standard Time",
    utc: "UTC+02:00",
    mobileCode: "+258",
  },
  {
    name: "Myanmar",
    code: "MM",
    timezone: "Myanmar Standard Time",
    utc: "UTC+06:30",
    mobileCode: "+95",
  },
  {
    name: "Namibia",
    code: "NA",
    timezone: "Namibia Standard Time",
    utc: "UTC+01:00",
    mobileCode: "+264",
  },
  {
    name: "Nauru",
    code: "NR",
    timezone: "UTC+12",
    utc: "UTC+12:00",
    mobileCode: "+674",
  },
  {
    name: "Nepal",
    code: "NP",
    timezone: "Nepal Standard Time",
    utc: "UTC+05:45",
    mobileCode: "+977",
  },
  {
    name: "Netherlands",
    code: "NL",
    timezone: "W. Europe Standard Time",
    utc: "UTC+01:00",
    mobileCode: "+31",
  },
  {
    name: "New Caledonia",
    code: "NC",
    timezone: "Central Pacific Standard Time",
    utc: "UTC+11:00",
    mobileCode: "+687",
  },
  {
    name: "New Zealand",
    code: "NZ",
    timezone: "New Zealand Standard Time",
    utc: "UTC+12:00",
    mobileCode: "+64",
  },
  {
    name: "Nicaragua",
    code: "NI",
    timezone: "Central America Standard Time",
    utc: "UTC-06:00",
    mobileCode: "+505",
  },
  {
    name: "Niger",
    code: "NE",
    timezone: "W. Central Africa Standard Time",
    utc: "UTC+01:00",
    mobileCode: "+227",
  },
  {
    name: "Nigeria",
    code: "NG",
    timezone: "W. Central Africa Standard Time",
    utc: "UTC+01:00",
    mobileCode: "+234",
  },
  {
    name: "Niue",
    code: "NU",
    timezone: "UTC-11",
    utc: "UTC-11:00",
    mobileCode: "+683",
  },
  {
    name: "Norfolk Island",
    code: "NF",
    timezone: "Central Pacific Standard Time",
    utc: "UTC+11:00",
    mobileCode: "+672",
  },
  {
    name: "North Korea",
    code: "KP",
    timezone: "Korea Standard Time",
    utc: "UTC+09:00",
    mobileCode: "+850",
  },
  {
    name: "Northern Mariana Islands",
    code: "MP",
    timezone: "West Pacific Standard Time",
    utc: "UTC+10:00",
    mobileCode: "+1-670",
  },
  {
    name: "Norway",
    code: "NO",
    timezone: "W. Europe Standard Time",
    utc: "UTC+01:00",
    mobileCode: "+47",
  },
  {
    name: "Oman",
    code: "OM",
    timezone: "Arabian Standard Time",
    utc: "UTC+04:00",
    mobileCode: "+968",
  },
  {
    name: "Pakistan",
    code: "PK",
    timezone: "Pakistan Standard Time",
    utc: "UTC+05:00",
    mobileCode: "+92",
  },
  {
    name: "Palau",
    code: "PW",
    timezone: "Tokyo Standard Time",
    utc: "UTC+09:00",
    mobileCode: "+680",
  },
  {
    name: "Palestinian Authority",
    code: "PS",
    timezone: "Egypt Standard Time",
    utc: "UTC+02:00",
    mobileCode: "+970",
  },
  {
    name: "Panama",
    code: "PA",
    timezone: "SA Pacific Standard Time",
    utc: "UTC-05:00",
    mobileCode: "+507",
  },
  {
    name: "Papua New Guinea",
    code: "PG",
    timezone: "West Pacific Standard Time",
    utc: "UTC+10:00",
    mobileCode: "+675",
  },
  {
    name: "Paraguay",
    code: "PY",
    timezone: "Paraguay Standard Time",
    utc: "UTC-04:00",
    mobileCode: "+595",
  },
  {
    name: "Peru",
    code: "PE",
    timezone: "SA Pacific Standard Time",
    utc: "UTC-05:00",
    mobileCode: "+51",
  },
  {
    name: "Philippines",
    code: "PH",
    timezone: "Singapore Standard Time",
    utc: "UTC+08:00",
    mobileCode: "+63",
  },
  {
    name: "Pitcairn Islands",
    code: "PN",
    timezone: "Pacific Standard Time",
    utc: "UTC-08:00",
    mobileCode: "+870",
  },
  {
    name: "Poland",
    code: "PL",
    timezone: "Central European Standard Time",
    utc: "UTC+01:00",
    mobileCode: "+48",
  },
  {
    name: "Portugal",
    code: "PT",
    timezone: "GMT Standard Time",
    utc: "UTC",
    mobileCode: "+351",
  },
  {
    name: "Puerto Rico",
    code: "PR",
    timezone: "SA Western Standard Time",
    utc: "UTC-04:00",
    mobileCode: "+1-787 and 1-939",
  },
  {
    name: "Qatar",
    code: "QA",
    timezone: "Arab Standard Time",
    utc: "UTC+03:00",
    mobileCode: "+974",
  },
  {
    name: "Reunion",
    code: "RE",
    timezone: "Mauritius Standard Time",
    utc: "UTC+04:00",
    mobileCode: "+262",
  },
  {
    name: "Romania",
    code: "RO",
    timezone: "GTB Standard Time",
    utc: "UTC+02:00",
    mobileCode: "+40",
  },
  {
    name: "Russia",
    code: "RU",
    timezone: "Russian Standard Time",
    utc: "UTC+03:00",
    mobileCode: "+7",
  },
  {
    name: "Rwanda",
    code: "RW",
    timezone: "South Africa Standard Time",
    utc: "UTC+02:00",
    mobileCode: "+250",
  },
  {
    name: "Saint Barthélemy",
    code: "BL",
    timezone: "SA Western Standard Time",
    utc: "UTC-04:00",
    mobileCode: "+590",
  },
  {
    name: "Saint Helena, Ascension and Tristan da Cunha",
    code: "SH",
    timezone: "Greenwich Standard Time",
    utc: "UTC",
    mobileCode: "+290",
  },
  {
    name: "Saint Kitts and Nevis",
    code: "KN",
    timezone: "SA Western Standard Time",
    utc: "UTC-04:00",
    mobileCode: "+1-869",
  },
  {
    name: "Saint Lucia",
    code: "LC",
    timezone: "SA Western Standard Time",
    utc: "UTC-04:00",
    mobileCode: "+1-758",
  },
  {
    name: "Saint Martin (French part)",
    code: "MF",
    timezone: "SA Western Standard Time",
    utc: "UTC-04:00",
    mobileCode: "+590",
  },
  {
    name: "Saint Pierre and Miquelon",
    code: "PM",
    timezone: "Greenland Standard Time",
    utc: "UTC-03:00",
    mobileCode: "+508",
  },
  {
    name: "Saint Vincent and the Grenadines",
    code: "VC",
    timezone: "SA Western Standard Time",
    utc: "UTC-04:00",
    mobileCode: "+1-784",
  },
  {
    name: "Samoa",
    code: "WS",
    timezone: "Samoa Standard Time",
    utc: "UTC+13:00",
    mobileCode: "+685",
  },
  {
    name: "San Marino",
    code: "SM",
    timezone: "W. Europe Standard Time",
    utc: "UTC+01:00",
    mobileCode: "+378",
  },
  {
    name: "São Tomé and Príncipe",
    code: "ST",
    timezone: "Greenwich Standard Time",
    utc: "UTC",
    mobileCode: "+239",
  },
  {
    name: "Saudi Arabia",
    code: "SA",
    timezone: "Arab Standard Time",
    utc: "UTC+03:00",
    mobileCode: "+966",
  },
  {
    name: "Senegal",
    code: "SN",
    timezone: "Greenwich Standard Time",
    utc: "UTC",
    mobileCode: "+221",
  },
  {
    name: "Serbia",
    code: "RS",
    timezone: "Central Europe Standard Time",
    utc: "UTC+01:00",
    mobileCode: "+381",
  },
  {
    name: "Seychelles",
    code: "SC",
    timezone: "Mauritius Standard Time",
    utc: "UTC+04:00",
    mobileCode: "+248",
  },
  {
    name: "Sierra Leone",
    code: "SL",
    timezone: "Greenwich Standard Time",
    utc: "UTC",
    mobileCode: "+232",
  },
  {
    name: "Singapore",
    code: "SG",
    timezone: "Singapore Standard Time",
    utc: "UTC+08:00",
    mobileCode: "+65",
  },
  {
    name: "Sint Maarten (Dutch part)",
    code: "SX",
    timezone: "SA Western Standard Time",
    utc: "UTC-04:00",
    mobileCode: "+599",
  },
  {
    name: "Slovakia",
    code: "SK",
    timezone: "Central Europe Standard Time",
    utc: "UTC+01:00",
    mobileCode: "+421",
  },
  {
    name: "Slovenia",
    code: "SI",
    timezone: "Central Europe Standard Time",
    utc: "UTC+01:00",
    mobileCode: "+386",
  },
  {
    name: "Solomon Islands",
    code: "SB",
    timezone: "Central Pacific Standard Time",
    utc: "UTC+11:00",
    mobileCode: "+677",
  },
  {
    name: "Somalia",
    code: "SO",
    timezone: "E. Africa Standard Time",
    utc: "UTC+03:00",
    mobileCode: "+252",
  },
  {
    name: "South Africa",
    code: "ZA",
    timezone: "South Africa Standard Time",
    utc: "UTC+02:00",
    mobileCode: "+27",
  },
  {
    name: "South Georgia and the South Sandwich Islands",
    code: "GS",
    timezone: "UTC-02",
    utc: "UTC-02:00",
    mobileCode: "+",
  },
  {
    name: "South Sudan",
    code: "SS",
    timezone: "E. Africa Standard Time",
    utc: "UTC+03:00",
    mobileCode: "+211",
  },
  {
    name: "Spain",
    code: "ES",
    timezone: "Romance Standard Time",
    utc: "UTC+01:00",
    mobileCode: "+34",
  },
  {
    name: "Sri Lanka",
    code: "LK",
    timezone: "Sri Lanka Standard Time",
    utc: "UTC+05:30",
    mobileCode: "+94",
  },
  {
    name: "Sudan",
    code: "SD",
    timezone: "E. Africa Standard Time",
    utc: "UTC+03:00",
    mobileCode: "+249",
  },
  {
    name: "Suriname",
    code: "SR",
    timezone: "SA Eastern Standard Time",
    utc: "UTC-03:00",
    mobileCode: "+597",
  },
  {
    name: "Svalbard",
    code: "SJ",
    timezone: "W. Europe Standard Time",
    utc: "UTC+01:00",
    mobileCode: "+47",
  },
  {
    name: "Swaziland",
    code: "SZ",
    timezone: "South Africa Standard Time",
    utc: "UTC+02:00",
    mobileCode: "+268",
  },
  {
    name: "Sweden",
    code: "SE",
    timezone: "W. Europe Standard Time",
    utc: "UTC+01:00",
    mobileCode: "+46",
  },
  {
    name: "Switzerland",
    code: "CH",
    timezone: "W. Europe Standard Time",
    utc: "UTC+01:00",
    mobileCode: "+41",
  },
  {
    name: "Syria",
    code: "SY",
    timezone: "Syria Standard Time",
    utc: "UTC+02:00",
    mobileCode: "+963",
  },
  {
    name: "Taiwan",
    code: "TW",
    timezone: "Taipei Standard Time",
    utc: "UTC+08:00",
    mobileCode: "+886",
  },
  {
    name: "Tajikistan",
    code: "TJ",
    timezone: "West Asia Standard Time",
    utc: "UTC+05:00",
    mobileCode: "+992",
  },
  {
    name: "Tanzania",
    code: "TZ",
    timezone: "E. Africa Standard Time",
    utc: "UTC+03:00",
    mobileCode: "+255",
  },
  {
    name: "Thailand",
    code: "TH",
    timezone: "SE Asia Standard Time",
    utc: "UTC+07:00",
    mobileCode: "+66",
  },
  {
    name: "Togo",
    code: "TG",
    timezone: "Greenwich Standard Time",
    utc: "UTC",
    mobileCode: "+228",
  },
  {
    name: "Tokelau",
    code: "TK",
    timezone: "Tonga Standard Time",
    utc: "UTC+13:00",
    mobileCode: "+690",
  },
  {
    name: "Tonga",
    code: "TO",
    timezone: "Tonga Standard Time",
    utc: "UTC+13:00",
    mobileCode: "+676",
  },
  {
    name: "Trinidad and Tobago",
    code: "TT",
    timezone: "SA Western Standard Time",
    utc: "UTC-04:00",
    mobileCode: "+1-868",
  },
  {
    name: "Tunisia",
    code: "TN",
    timezone: "W. Central Africa Standard Time",
    utc: "UTC+01:00",
    mobileCode: "+216",
  },
  {
    name: "Turkey",
    code: "TR",
    timezone: "Turkey Standard Time",
    utc: "UTC+02:00",
    mobileCode: "+90",
  },
  {
    name: "Turkmenistan",
    code: "TM",
    timezone: "West Asia Standard Time",
    utc: "UTC+05:00",
    mobileCode: "+993",
  },
  {
    name: "Turks and Caicos Islands",
    code: "TC",
    timezone: "Eastern Standard Time",
    utc: "UTC-05:00",
    mobileCode: "+1-649",
  },
  {
    name: "Tuvalu",
    code: "TV",
    timezone: "UTC+12",
    utc: "UTC+12:00",
    mobileCode: "+688",
  },
  {
    name: "U.S. Minor Outlying Islands",
    code: "UM",
    timezone: "UTC-11",
    utc: "UTC-11:00",
    mobileCode: "+1",
  },
  {
    name: "Uganda",
    code: "UG",
    timezone: "E. Africa Standard Time",
    utc: "UTC+03:00",
    mobileCode: "+256",
  },
  {
    name: "Ukraine",
    code: "UA",
    timezone: "FLE Standard Time",
    utc: "UTC+02:00",
    mobileCode: "+380",
  },
  {
    name: "United Arab Emirates",
    code: "AE",
    timezone: "Arabian Standard Time",
    utc: "UTC+04:00",
    mobileCode: "+971",
  },
  {
    name: "United Kingdom",
    code: "GB",
    timezone: "GMT Standard Time",
    utc: "UTC",
    mobileCode: "+44",
  },
  {
    name: "United States",
    code: "US",
    timezone: "Pacific Standard Time",
    utc: "UTC-08:00",
    mobileCode: "+1",
  },
  {
    name: "Uruguay",
    code: "UY",
    timezone: "Montevideo Standard Time",
    utc: "UTC-03:00",
    mobileCode: "+598",
  },
  {
    name: "Uzbekistan",
    code: "UZ",
    timezone: "West Asia Standard Time",
    utc: "UTC+05:00",
    mobileCode: "+998",
  },
  {
    name: "Vanuatu",
    code: "VU",
    timezone: "Central Pacific Standard Time",
    utc: "UTC+11:00",
    mobileCode: "+678",
  },
  {
    name: "Vatican City",
    code: "VA",
    timezone: "W. Europe Standard Time",
    utc: "UTC+01:00",
    mobileCode: "+379",
  },
  {
    name: "Vietnam",
    code: "VN",
    timezone: "SE Asia Standard Time",
    utc: "UTC+07:00",
    mobileCode: "+84",
  },
  {
    name: "Virgin Islands, U.S.",
    code: "VI",
    timezone: "SA Western Standard Time",
    utc: "UTC-04:00",
    mobileCode: "+1-340",
  },
  {
    name: "Virgin Islands, British",
    code: "VG",
    timezone: "SA Western Standard Time",
    utc: "UTC-04:00",
    mobileCode: "+1-284",
  },
  {
    name: "Wallis and Futuna",
    code: "WF",
    timezone: "UTC+12",
    utc: "UTC+12:00",
    mobileCode: "+681",
  },
  {
    name: "Yemen",
    code: "YE",
    timezone: "Arab Standard Time",
    utc: "UTC+03:00",
    mobileCode: "+967",
  },
  {
    name: "Zambia",
    code: "ZM",
    timezone: "South Africa Standard Time",
    utc: "UTC+02:00",
    mobileCode: "+260",
  },
  {
    name: "Zimbabwe",
    code: "ZW",
    timezone: "South Africa Standard Time",
    utc: "UTC+02:00",
    mobileCode: "+263",
  },
];

export const sectorsList = [
  "Aluminium",
  "Antimony",
  "Boron",
  "Chromium",
  "Cobalt",
  "Copper",
  "Fluorine",
  "Gallium",
  "Germanium",
  "Graphite",
  "Rare Earths Elements",
  "Indium",
  "Iron",
  "Lithium",
  "Magnesium",
  "Manganese",
  "Molybdenum",
  "Nickel",
  "Niobium",
  "PGMs",
  "Rhenium",
  "Salt",
  "Scandium",
  "Silicon",
  "Tantalum",
  "Tellurium",
  "Tin",
  "Titanium",
  "Tungsten",
  "Vanadium",
]
  .sort()
  .map((nth) => {
    return { label: nth, value: nth };
  });
export const interestsList = [
  "Aerospace",
  "Automotive",
  "Batteries",
  "Electricity networks",
  "Energy storage",
  "Hydrogen and fuel cells",
  "Electronics and semiconductors",
  "Magnets and motors",
  "Solar",
  "Steel and alloys",
  "Wind",
]
  .sort()
  .map((nth) => {
    return { label: nth, value: nth };
  });

// NOTE: this is jsut a work around to maintain n lines of texts in vue-snip
export const HTML_PADDING_TEXT = "<p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;...</p>"