<template>
  <a
    href="javascript:;"
    class="p-0 nav-link text-white"
    @click="openNotesWindow"
  >
    <i class="cursor-pointer fa fa-edit fa-lg"></i>
  </a>
  <NotesWindow />
</template>

<script setup>
import { NOTES_WINDOW, SHOW_ALL_NOTES } from "../../composables/notes/list";
import NotesWindow from "../../pages/Notes/NotesWindow.vue";

const openNotesWindow = () => {
  NOTES_WINDOW.value = true;
  SHOW_ALL_NOTES.value = true;
};
</script>
