<template>
  <main>
    <div class="py-4 container-fluid">
      <div class="card shadow-lg">
        <div class="card-body p-3">
          <div class="row gx-4">
            <div class="col-auto">
              <div
                class="avatar avatar-lg bg-gradient-dark border-radius-md position-relative"
              >
                <span class="text-2xl text-bold text-white">{{
                  route.params.element
                }}</span>
              </div>
            </div>
            <div class="col-auto my-auto">
              <div class="h-100">
                <h5 class="mb-0">{{ $t("Files.title") }}</h5>
                <p class="mb-0 font-weight-bold text-xs">
                  {{ $t("Files.secondaryTitle") }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="py-4 container-fluid">
      <div class="card shadow-lg mb-3">
        <div class="card-body p-4">
          <h5 class="pb-2">{{ $t(`Files.seriesNames.series1Title`) }}</h5>
          <div class="row">
            <div
              class="col-4 col-lg-3"
              v-for="series in SERIES_100"
              :key="series"
            >
              <div class="card cursor-pointer" @click="viewFiles(series)">
                <div class="p-3 card-body">
                  <div class="d-flex">
                    <div
                      class="p-2 avatar avatar-sm bg-gradient-dark border-radius-md"
                    >
                      <span class="text-lg text-bold text-white">{{
                        route.params.element
                      }}</span>
                    </div>
                    <div
                      class="mt-1 ms-3 text-truncate font-weight-bold text-lg"
                    >
                      {{ $t(`Files.seriesNames.${series}`) }}
                    </div>
                  </div>
                  <hr class="horizontal dark" />
                  <p class="mt-4 text-xs">
                    {{ $t(`Files.description.${series}`) }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="card shadow-lg mb-3">
        <div class="card-body p-4">
          <h5 class="pb-2">{{ $t(`Files.seriesNames.series2Title`) }}</h5>
          <div class="row">
            <div
              class="col-lg-3 col-md-4"
              v-for="series in SERIES_200"
              :key="series"
            >
              <div class="card cursor-pointer" @click="viewFiles(series)">
                <div class="p-3 card-body">
                  <div class="d-flex">
                    <div
                      class="p-2 avatar avatar-sm bg-gradient-dark border-radius-md"
                    >
                      <span class="text-lg text-bold text-white">{{
                        route.params.element
                      }}</span>
                    </div>
                    <div
                      class="mt-1 ms-3 text-truncate font-weight-bold text-lg"
                    >
                      {{ $t(`Files.seriesNames.${series}`) }}
                    </div>
                  </div>
                  <hr class="horizontal dark" />
                  <p class="mt-4 text-xs">
                    {{ $t(`Files.description.${series}`) }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="card shadow-lg mb-3">
        <div class="card-body p-4">
          <h5 class="pb-2">{{ $t(`Files.seriesNames.series3Title`) }}</h5>
          <div class="row">
            <div
              class="col-lg-3 col-md-4"
              v-for="series in SERIES_OTHERS"
              :key="series"
            >
              <div class="card cursor-pointer" @click="viewFiles(series)">
                <div class="p-3 card-body">
                  <div class="d-flex">
                    <div
                      class="p-2 avatar avatar-sm bg-gradient-dark border-radius-md"
                    >
                      <span class="text-lg text-bold text-white">{{
                        route.params.element
                      }}</span>
                    </div>
                    <div
                      class="mt-1 ms-3 text-truncate font-weight-bold text-lg"
                    >
                      {{ $t(`Files.seriesNames.${series}`) }}
                    </div>
                  </div>
                  <hr class="horizontal dark" />
                  <p class="mt-4 text-xs">
                    {{ $t(`Files.description.${series}`) }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
  <NDrawer v-model:show="showFiles" :width="600" placement="right">
    <NDrawerContent>
      <div class="row">
        <div class="col-12">
          <div class="card mb-4">
            <div class="card-header pb-0">
              <h6 class="text-capitalize">
                {{
                  $t(`Files.seriesNames.seriesTitle`, {
                    series: selectedSeries,
                  })
                }}
              </h6>
            </div>
            <div class="card-body px-0 pt-0">
              <NDataTable :columns="columns" :data="data" />
            </div>
          </div>
        </div>
      </div>
    </NDrawerContent>
  </NDrawer>
</template>

<script setup>
import { onMounted, ref, computed, h } from "vue";
import { NDrawer, NDrawerContent, NDataTable } from "naive-ui";
import { useRoute, useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import { ROUTES } from "../../routes/names";
import { USER_FILES, getUserFiles } from "../../composables/files/list";
import TableCell from "../../components/FilesTable/TableCell";
import TableCellDownload from "../../components/FilesTable/TableCellDownload";
import TableHeader from "../../components/FilesTable/TableHeader";
import SeriesNames from "../../utils/series.json";

const router = useRouter();
const route = useRoute();
const { t } = useI18n();
const showFiles = ref(false);
const selectedSeries = ref(null);
const folders = ["101", "102", "103", "201", "202", "203", "DATA"];

const SERIES_100 = ["101", "102", "103"];
const SERIES_200 = ["201", "202", "203"];
const SERIES_OTHERS = ["DATA"];

const filesInSeries = ref([]);
const columns = computed(() => {
  return [
    {
      key: "name",
      title(column) {
        return h(TableHeader, {
          text: t("Files.fileHeader"),
        });
      },
      render: (row) => {
        return h(TableCell, {
          text: row.title,
          url: row.url,
          type: row.type,
        });
      },
    },
    {
      key: "action",
      title: null,
      render: (row) => {
        return h(TableCellDownload, {
          text: row.title,
          url: row.url,
          id: row.id,
        });
      },
    },
  ];
});
const data = computed(() => {
  let el =
    (USER_FILES.value?.files || []).find(
      (nth) => nth.element === route.params.element
    ) || [];

  const files = filesInSeries.value || [];
  return files.map((nth) => {
    let displayName =
      el.elementName +
      " " +
      selectedSeries.value +
      ":" +
      nth.displayName.slice(nth.displayName.indexOf(":") + 1);
    return {
      title: displayName,
      type: nth.fileType,
      url: nth.fileUrl,
      id: nth.id,
    };
  });
});

onMounted(async () => {
  if (!USER_FILES.value?.files) await getUserFiles();
});

const viewFiles = (series) => {
  // router.push({
  //   name: ROUTES.FILE.name,
  //   params: { series, element: route.params.element },
  // });
  showFiles.value = true;
  // selectedSeries.value = series;
  selectedSeries.value = SeriesNames.series
    .filter((r) => r.code === series)
    .map((r) => r.name)[0];

  const filesInElements =
    (USER_FILES.value?.files || []).find(
      (nth) => nth.element === route.params.element
    ).files || [];
  filesInSeries.value = filesInElements.filter(
    (file) => file.fileSeries === series
  );
};
</script>
