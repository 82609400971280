<template>
  <NPopselect
    v-model:value="selected"
    :options="options"
    size="medium"
    scrollable
    :style="`width: ${width}px`"
    :disabled="props.hideDropdown"
  >

  <div v-if="props.mapComponent" style="width: 120px;" class="card" ref="card">
      <div class="p-2 card-body">
        <div class="row g-0">
          <div class="col-12 d-flex d-flex-row justify-content-between">
            <p
              class="mb-0 mt-2 text-sm text-capitalize font-weight-bold proxima-blue-text"
            >
              {{ props.title }}
            </p>
            <div
              class="avatar avatar-sm proxima-blue-bg-gradient border-radius-sm"
            >
              <span class="text-sm text-light text-white">
                {{ props.profiles.length }}
                {{
                  props.count > 0 && props.count !== props.profiles?.length
                    ? `/ ${props.count}`
                    : ""
                }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else-if="props.profileComponent" style="width: 200px;" class="card mb-3" ref="card">
      <div class="p-2 card-body">
        <div class="row g-0">
          <div class="col-12 d-flex d-flex-row justify-content-between">
            <p
              class="mb-0 mt-2 text-sm text-capitalize font-weight-bold proxima-blue-text"
            >
              {{ props.title }}
            </p>
            <div
              class="avatar avatar-sm proxima-blue-bg-gradient border-radius-sm"
            >
              <span class="text-sm text-light text-white">
                {{ props.profiles.length }}
                {{
                  props.count > 0 && props.count !== props.profiles?.length
                    ? `/ ${props.count}`
                    : ""
                }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-else class="card" ref="card">
      <div class="p-3 card-body">
        <div class="d-flex flex-row-reverse justify-content-between">
          <slot></slot>
          <div>
            <p
              class="mb-0 text-sm text-capitalize font-weight-bold proxima-blue-text"
            >
              {{ props.title }}
            </p>
            <h5
              class="mb-0 font-weight-bolder proxima-blue-text"
              v-if="props.profiles?.length"
            >
              {{ props.profiles.length }}
              {{
                props.count > 0 && props.count !== props.profiles?.length
                  ? `/ ${props.count}`
                  : ""
              }}
            </h5>
            <span v-else class="text-sm text-muted">
              {{ props.error }}
            </span>
          </div>
        </div>
      </div>
    </div>



    
  </NPopselect>
</template>

<script setup>
import { ref, onMounted, computed, watch } from "vue";
import { NCard, NPopselect } from "naive-ui";
import { sortBy } from "lodash";
import { LAST_SELECTED_ELEMENT_TO_VIEW } from "../../composables/generics";
import { SUBSCRIPTION_ELEMENTS_OPTS } from "../../composables/subscription";

const props = defineProps({
  title: {
    type: String,
    default: null,
  },
  profiles: {
    type: Array,
    default: () => [],
  },
  count: {
    type: Number,
    default: null,
  },
  error: {
    type: String,
    default: null,
  },
  hideDropdown: {
    type: Boolean,
    default: false,
  },
  mapComponent: {
    type: Boolean,
    default: false,
  },
  profileComponent: {
    type: Boolean,
    default: false,
  },
});
const emit = defineEmits(["mountProfile"]);
const selected = ref(null);
const card = ref(null);
const width = ref(null);
const options = computed(() => {
  const opts = (props.profiles || []).map((nth) => {
    return {
      label: nth.alias,
      value: nth.uuid,
    };
  });
  return sortBy(opts, "label");
});

watch(selected, (uuid) => {
  const profile = (props.profiles || []).find((nth) => nth.uuid === uuid);
  if (profile) emit("mountProfile", profile);
});

onMounted(() => {
  width.value = card.value?.offsetWidth || 200;
});
</script>
