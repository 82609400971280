<template>
  <main>
    <!-- <div class="py-4 container-fluid">
      <div class="card shadow-lg">
        <div class="card-body p-3">
          <div class="row gx-4">
            <div class="col-auto">
              <div
                class="avatar avatar-lg bg-gradient-dark border-radius-md position-relative"
              >
                <i class="ni ni-folder-17 text-white text-2xl"></i>
              </div>
            </div>
            <div class="col-auto my-auto">
              <div class="h-100">
                <h5 class="mb-0">{{ $t("Files.title") }}</h5>
                <p class="mb-0 font-weight-bold text-xs">
                  {{ $t("Files.secondaryTitle") }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->
    <div class="py-4 container-fluid">
      <div class="row">
        <div class="mb-4 col-12 col-lg-6 col-md-6">
          <div class="card">
            <div class="card-body px-0">
              <VideoPlayer class="video-player" :src="videoUrl" controls />
            </div>
          </div>
        </div>
        <div class="mb-4 col-12 col-lg-6 col-md-6">
          <div class="card">
            <div class="p-3 card-body">
              <div class="row">
                <div
                  class="col-12 mb-2"
                  v-for="nth of STREAMS || []"
                  :key="nth"
                  @click="showVideo(nth.fileUrl)"
                >
                  <div class="cursor-pointer px-2 py-1 rounded">
                    <span class="me-3 font-weight-bold">
                      {{ nth.displayName }}
                    </span>
                    <span class="text-xs text-muted"
                      >[{{ nth.uploadedDate }}]</span
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script setup>
import { onMounted, ref, computed, h } from "vue";
import { useRouter } from "vue-router";
import videojs from "video.js";
import { ROUTES } from "../../routes/names";
import { STREAMS, getStreams } from "../../composables/files/list";
import { useI18n } from "vue-i18n";
import { VideoPlayer } from "@videojs-player/vue";
import "video.js/dist/video-js.css";

const router = useRouter();
const { t } = useI18n();
const videoUrl = ref(null);

onMounted(async () => {
  if (!STREAMS.value?.length) {
    await getStreams();
    videoUrl.value = STREAMS.value?.[0]?.fileUrl || null;
  }
});

const showVideo = (url) => {
  videoUrl.value = url;
};
</script>

<style lang="scss" scoped>
.video-player {
  width: inherit;
  max-height: 500px;
}
</style>
