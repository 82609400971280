<template>
    <section>
      <!-- CONTACT FORM START -->
      <div class="container mt-5 mb-5">
        <div class="row">
          <div class="col-md-8 mx-auto">
            <div class="contact-title">
                <h3>Thank you for contacting us.</h3>
                <p>A member of the team will be in touch shortly. </p>
                <button
                  class="btn btn-default"
                    @click="goBack"
                >
                  Go Back
                </button>
            </div>
          </div>
        </div>
      </div>
      <!-- CONTACT FORM END -->
    </section>
</template>

<script setup>
import { ROUTES } from "../../routes/names";
import { useRouter } from "vue-router";
const router = useRouter();

const goBack = () => {
    router.push({
      name: ROUTES.FILES.name
    });
}
</script>

<style lang="scss" scoped>
:deep(.n-base-selection) {
  border: none !important;
  --n-border: none !important;
  --n-border-active: none !important;
  --n-border-focus: none !important;
  --n-border-hover: none !important;
  --n-box-shadow-active: none !important;
  --n-box-shadow-focus: none !important;
  --n-color: none !important;
  --n-color-active: none !important;
  --n-height: none !important;
  --n-padding-single: 0 26px 0 0 !important;
  --n-placeholder-color: #6c757d !important;
  font-family: "Gill Sans MT", sans-serif;
  padding: 8px 25px;
  top: 5px;
}

:deep(.n-select) {
  padding: 0 !important;
  height: 46px;
}

.custom-form {
  color: #020202;
}

.proxima-number {
  :deep(.n-base-selection-label) {
    // display: flex !important;
    // padding-top: 20px;
  }

  :deep(.n-base-selection-input) {
    // top: -20px !important;
    // position: inherit;
  }
}
</style>
