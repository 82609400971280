<template>
  <main class="main-content mt-0">
    <div
      class="page-header align-items-start min-vh-50 pt-5 pb-11 m-3 border-radius-lg"
      style="
        background-image: url('https://raw.githubusercontent.com/creativetimofficial/public-assets/master/argon-dashboard-pro/assets/img/signup-cover.jpg');
        background-position: top;
      "
    >
      <span class="mask bg-gradient-dark opacity-6"></span>
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-5 text-center mx-auto">
            <h1 class="text-white mb-2 mt-5">
              {{ $t("ResetPassword.title") }}
            </h1>
            <p class="text-lead text-white">
              {{ $t("ResetPassword.message") }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row mt-lg-n10 mt-md-n11 mt-n10 justify-content-center">
        <div class="col-xl-4 col-lg-5 col-md-7 mx-auto">
          <div class="card z-index-0">
            <div class="card-header text-center pt-4">
              <h5>{{ $t("ResetPassword.resetPasswordLabel") }}</h5>
            </div>
            <div class="card-body">
              <input
                type="password"
                placeholder="Reset password"
                class="form-control"
                v-model="password"
                @keyup.enter="requestPasswordChange"
              />
              <NSpace v-if="errorMessages" class="errors">
                <NText type="error" v-for="msg in errorMessages" :key="msg">{{
                  msg
                }}</NText>
              </NSpace>
              <div class="text-center">
                <button
                  class="btn btn-lg proxima-blue-bg-gradient bg-gradient-success text-white w-100 mb-2 my-4"
                  :disabled="!isReadyToSubmit"
                  @click="requestPasswordChange"
                >
                  {{ $t("ResetPassword.resetButton") }}
                </button>
              </div>
              <p class="text-sm mt-3 mb-0">
                {{ $t("ResetPassword.alreadyHaveAnAccount") }}
                <RouterLink
                  :to="{ name: ROUTES.LOGIN.name }"
                  class="text-dark font-weight-bolder"
                  >{{ $t("ResetPassword.signInLink") }}</RouterLink
                >
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script setup>
import { computed, onBeforeUnmount, onMounted, ref } from "vue";
import { NSpace, NText } from "naive-ui";
import { useRoute, useRouter } from "vue-router";
import { resetPassword } from "../composables/auth";
import { ROUTES } from "../routes/names";
const body = document.getElementsByTagName("body")[0];

const route = useRoute();
const router = useRouter();
const password = ref(null);
const errorMessages = ref([]);

onMounted(() => {
  body.classList.remove("bg-gray-100");
  errorMessages.value = [];
});

onBeforeUnmount(() => {
  body.classList.add("bg-gray-100");
  errorMessages.value = [];
});

const isReadyToSubmit = computed(() => {
  return password.value?.length && route.query.code;
});

const requestPasswordChange = async () => {
  if (isReadyToSubmit.value) {
    errorMessages.value = await resetPassword(route.query.code, password.value);
    password.value = null;
    if (!errorMessages.value) {
      router.push({ name: ROUTES.LOGIN.name, query: { _r: Math.random() } });
    }
  }
};
</script>

<style lang="scss" scoped>
.errors {
  margin-top: 15px;
  font-size: 0.9rem;
}
</style>
