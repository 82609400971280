<template>
  <div class="card p-1 proxima-bg-light z-index-2">
    <div class="text-start proxima-bg-light p-1">
      <h3 class="card-title">Latest News:</h3>
    </div>
    <div v-if="inProgress" class="col-12 mt-1 d-flex justify-content-center">
      <NSpace>
        <NSpin size="medium" class="custom-spin-color" />
      </NSpace>
    </div>
    <div v-else class="overflow-auto" style="height: 410px">
      <div v-if="articles?.length" class="row gx-0">
        <div v-for="(nth, i) in articles" :key="i" class="col-12 m-0 p-0">
          <div
            class="proxima_recent_news__col d-flex p-3"
            style="cursor: pointer; border-bottom: 1px solid lightgray"
          >
            <div class="flex-shrink-1">
              <img
                :src="nth.bannerImage"
                onerror="this.src=`https://via.placeholder.com/992x700?text=...`"
                style="width: 150px"
                alt="sample image"
                class="rounded shadow"
              />
            </div>
            <div class="flex-grow-1 ms-1 px-1">
              <p
                class="flex-grow-1 proxima_recent_news--date mb-0 latest-news-card text-black-50"
              >
                <span
                  @click="goToLinkType(nth.type)"
                  class="badge badge-custom"
                >
                  {{ nth.type }}
                </span>
                |
                {{ new Date(nth.publish_Date).toDateString() }}
                |
                <span v-for="(author, i) in nth.authors" :key="i">
                  {{ author.name + " " }}
                </span>
              </p>
              <h6>
                <span
                  class="proxima_recent_news--title"
                  @click="viewArticle(nth)"
                >
                  {{ nth.name }}
                </span>
              </h6>
              <client-only>
                <div
                  class="text-justify proxima_recent_news_desc"
                  v-html="nth?.description"
                />
              </client-only>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="col-12 mt-1 d-flex justify-content-center">
        <h5>No news to display.</h5>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, onMounted, ref } from "vue";
import { NSpin, NSpace } from "naive-ui";
import {
  getPublicArticles,
  PUBLIC_ARTICLES,
  inProgress,
} from "../../composables/profiles/articles";
import { SUBSCRIPTION_INFO } from "../../composables/subscription";

const pbDomain = `https://projectblue.com`;
let data = ref([]);

const articles = computed(() => {
  let stringArray = SUBSCRIPTION_INFO.elements.map((r) => r.code);
  data.value = PUBLIC_ARTICLES.value?.filter((r) => {
    for (let i = 0; i < r.elements.length; i++) {
      if (stringArray.includes(r.elements[i])) {
        return r;
      }
    }
  });
  return data.value;
});

const viewArticle = (nth) => {
  if (nth.type === "NEWS") {
    window
      .open(
        `${pbDomain}/blue/news-analysis/${nth.id}/${nth.name
          ?.replaceAll(" ", "-")
          .toLowerCase()}`,
        "_blank"
      )
      .focus();
  } else if (nth.type === "OPINION") {
    window
      .open(
        `${pbDomain}/blue/opinion-pieces/${nth.id}/${nth.name
          ?.replaceAll(" ", "-")
          .toLowerCase()}`,
        "_blank"
      )
      .focus();
  } else if (nth.type === "VIDEO") {
    window.open(`${pbDomain}/blue/opinion-pieces/${nth.id}`, "_blank").focus();
  }
};

const goToLinkType = (type) => {
  if (type === "NEWS") {
    window.open(`${pbDomain}/blue/news-analysis`, "_blank").focus();
  } else if (type === "OPINION") {
    window.open(`${pbDomain}/blue/opinion-pieces`, "_blank").focus();
  } else if (type === "VIDEO") {
    window.open(`${pbDomain}/blue/videos`, "_blank").focus();
  }
};

onMounted(async () => {
  if (!PUBLIC_ARTICLES.value) await getPublicArticles();
});
</script>

<style lang="scss" scoped>
@import url("../../assets/styles/home-page-styles.scss");
</style>
