<template>
  <div class="py-4 container-fluid main-dashboard">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body p-0">
            <h1>Market Data</h1>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup></script>

<style></style>
