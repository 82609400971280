<template>
  <main>
    <div class="py-4 container-fluid">
      <div class="row">
        <div class="col-12">
          <NCard>
            <!-- TODO: make multiple tabs -->
            <Trades />
          </NCard>
        </div>
      </div>
    </div>
  </main>
</template>

<script setup>
import { ref } from "vue";
import { NCard, NTabs, NTabPane } from "naive-ui";
import Trades from "../../components/TradeToolTab/Trades";

const panels = ref([1, 2, 3, 4, 5]);
</script>
