<template>
    <div class="row px-4">
        <div class="col-12 col-md-3 mb-3">
            <div class="col-12 mb-3">
                <NSelect v-model:value="COUNTRY_ID" :options="COUNTRIES_OPTS" filterable clearable :placeholder="t('ProximaList.countryProfiles.countryPlaceholder')
                    " :loading="COUNTRIES_OPTS.length ? false : true" :input-props="{ autocomplete: Math.random() }"
                    @update:value="(COUNTRY_ID ? getCountryDetails() : ''), clearRoute(), COMMODITY_ID = null;"
                    :style="'width: 200px;'" />
            </div>

            <div class="col-12">
                <div class="row">
                    <div class="col-12 mb-3">
                        <h4>{{ MOUNTED_COUNTRY_PROFILE?.country_Name }}</h4>
                        <SummaryCard v-if="COUNTRY_ID" :title="$t('ProximaList.assetCard.title')"
                            :profiles="countryAssets || []" :count="countryAssets?.length"
                            :error="$t('ProximaList.assetCard.notFound')" :hideDropdown="true" :mapComponent="false"
                            :profileComponent="true">
                            <div class="text-center shadow icon icon-shape bg-gradient-primary rounded-circle">
                                <i class="text-lg opacity-10 ni ni-money-coins"></i>
                            </div>
                        </SummaryCard>

                        <!-- <div v-if="COUNTRY_ID" class="col-12 mb-3">
                            <NSelect v-model:value="STATUS_ID" :options="ASSET_STATUS_OPTS" filterable clearable
                                :placeholder="t('ProximaList.countryProfiles.statusPlaceholder')
                                    " :loading="inProgress" :input-props="{ autocomplete: Math.random() }"
                                @update:value="filterAssetStatus()" :style="'width: 200px;'" />
                        </div> -->

                        <div class="row mb-4" v-if="MOUNTED_COUNTRY_PROFILE?.country_Name">
                            <div class="overflow-auto" style="min-height: 100px; max-height: 300px;"
                                v-if="countryAssets">
                                <div class="col-12 text-sm" v-for="(nth, key) of countryAssets" :key="key"
                                    :style="getCursorStyle(nth)">
                                    <span @click="checkAccess(nth), PROFILE_TO_VIEW_IN_DRAWER.type = 'ASSET_PROFILE'">
                                        <span>{{ nth.short_Name }} </span>
                                        <!-- <span v-if="nth.owners"> {{ getOwners(nth.owners) }} - </span>
                                        <span v-else> No Operator - </span> -->
                                        <span>{{ nth.stage }}</span>
                                    </span>

                                </div>
                            </div>
                            <div class="row mt-4" v-if="companyProfiles">
                                <div class="col-12">
                                    <h5 class="proxima-blue-text">
                                        {{ $t("CountryProfile.listOfCompaniesHeader") }}
                                    </h5>
                                    <SummaryCard v-if="COUNTRY_ID" :title="$t('ProximaList.companyCard.title')"
                                        :profiles="filteredCompanyProfiles || []"
                                        :count="filteredCompanyProfiles.length"
                                        :error="$t('ProximaList.companyCard.notFound')" :hideDropdown="true"
                                        :mapComponent="false" :profileComponent="true">
                                        <div
                                            class="text-center shadow icon icon-shape bg-gradient-primary rounded-circle">
                                            <i class="text-lg opacity-10 ni ni-money-coins"></i>
                                        </div>
                                    </SummaryCard>
                                </div>
                                <div class="overflow-auto" style="min-height: 200px; max-height: 500px;">
                                    <div class="col-12 text-sm" v-for="nth of filteredCompanyProfiles" :key="nth.uuid"
                                        @click="checkAccess(nth), PROFILE_TO_VIEW_IN_DRAWER.type = 'COMPANY_PROFILE'"
                                        :style="getCursorStyle(nth)">
                                        {{ nth.alias }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 col-md-9 mb-3">
            <div class="row">
                <div class="col-12 mb-3" v-if="MOUNTED_GENERAL_COUNTRY_PROFILE?.overview">
                    <h5 class="proxima-blue-text">{{ $t('ProximaList.countryProfiles.countryOverviewTitle') }}</h5>
                    <p class="justify-center" v-html="MOUNTED_GENERAL_COUNTRY_PROFILE?.overview"></p>
                </div>

                <div class="col-12 mb-3" v-if="MOUNTED_GENERAL_COUNTRY_PROFILE?.policies">
                    <h5 class="proxima-blue-text">{{ $t('ProximaList.countryProfiles.generalPolicies') }}</h5>
                    <p class="justify-center" v-html="MOUNTED_GENERAL_COUNTRY_PROFILE?.policies"></p>
                </div>
            </div>

            <div v-if="COUNTRY_ID">
                <div class="col-12 mb-3">
                    <div class="d-flex">
                        <span class="text-muted" style="font-style: italic;margin-right: 10px;margin-top: 5px;">
                            {{ t('ProximaList.countryProfiles.selectCommodityPlaceholder') }}
                        </span>
                        <!-- TODO -->
                        <NSelect v-model:value="COMMODITY_ID" :options="COUNTRY_COMMODITY" filterable clearable
                            :placeholder="t('ProximaList.countryProfiles.commodityPlaceholder')
                                " :loading="inProgress" :input-props="{ autocomplete: Math.random() }"
                            :style="'width: 200px;'"
                            @update:value="showCountryDetails(), getPublicSupplyData({ company: null, country: COUNTRY_ID, element: selectedCountryElement, product: null })" />
                    </div>

                    <div class="mt-4 mb-4" v-if="COMMODITY_ID">
                        <NDataTable v-if="hasData" id="info-table" size="small" :columns="tableColumns" :data="patData"
                            :loading="inProgress" striped />
                        <span v-if="hasData" class="text-muted" style="font-size: 13px; font-style: italic;">
                            {{ t('ProximaList.countryProfiles.annualEstimatedLabel') }}
                        </span>
                    </div>

                    <InfoText class="col-12 mb-3" v-if="COUNTRY_DETAILS?.geology"
                        :title="$t('CountryProfile.geologyHeader')" :contents="COUNTRY_DETAILS?.geology" />

                    <InfoText class="col-12 mb-3" v-if="COUNTRY_DETAILS?.commoditySpecificPolicies"
                        :title="$t('ProximaList.countryProfiles.commoditySpecificPolicies')"
                        :contents="COUNTRY_DETAILS?.commoditySpecificPolicies" />

                    <InfoText class="col-12 mb-3" v-if="COUNTRY_DETAILS?.production"
                        :title="$t('ProximaList.countryProfiles.production')" :contents="COUNTRY_DETAILS?.production" />

                    <InfoText class="col-12 mb-3" v-if="COUNTRY_DETAILS?.ppd"
                        :title="$t('ProximaList.countryProfiles.policyPoliticsAndDuties')"
                        :contents="COUNTRY_DETAILS?.ppd" />

                    <InfoText class="col-12 mb-3" v-if="COUNTRY_DETAILS?.other"
                        :title="$t('ProximaList.countryProfiles.other')" :contents="COUNTRY_DETAILS?.other" />
                </div>
            </div>
        </div>


        <NDrawer v-model:show="showAssetInfo" :width="400" :height="200" placement="right">
            <NDrawerContent>
                <div class="row gap-y-2" v-if="PROFILE_TO_VIEW_IN_DRAWER.type === 'ASSET_PROFILE'">
                    <div class="col-12">
                        <button class="btn btn-xs proxima-blue-bg-gradient bg-gradient-success text-white px-2"
                            style="float: right" :disabled="inProgress" @click="
                                goToAssetProfile({ assetUUID: PROFILE_TO_VIEW_IN_DRAWER?.uuid })
                                ">
                            Go to profile
                        </button>
                    </div>
                    <div class="col-12">
                        <h5>{{ PROFILE_TO_VIEW_IN_DRAWER.title }}</h5>
                        <br />
                    </div>

                    <InfoText class="col-12 mb-3" v-if="PROFILE_TO_VIEW_IN_DRAWER.profile" title="Profile"
                        :contents="PROFILE_TO_VIEW_IN_DRAWER.profile" />

                    <InfoText class="col-12 mb-3" v-if="PROFILE_TO_VIEW_IN_DRAWER.project" title="Project"
                        :contents="PROFILE_TO_VIEW_IN_DRAWER.project" />
                </div>
                <div class="row gap-y-2" v-else-if="PROFILE_TO_VIEW_IN_DRAWER.type === 'COMPANY_PROFILE'">
                    <div class="col-12">
                        <button class="btn btn-xs proxima-blue-bg-gradient bg-gradient-success text-white px-2"
                            style="float: right" :disabled="inProgress"
                            @click="goToCompanyProfile(PROFILE_TO_VIEW_IN_DRAWER?.uuid)">
                            Go to profile
                        </button>
                    </div>
                    <div class="col-12">
                        <h5>{{ PROFILE_TO_VIEW_IN_DRAWER.title }}</h5>
                        <br />
                    </div>
                    <InfoText class="col-12 mb-3" v-if="PROFILE_TO_VIEW_IN_DRAWER.overview" title="Overview"
                        :contents="PROFILE_TO_VIEW_IN_DRAWER.overview" />

                    <InfoText class="col-12 mb-3" v-if="PROFILE_TO_VIEW_IN_DRAWER.profile" title="Profile"
                        :contents="PROFILE_TO_VIEW_IN_DRAWER.profile" />
                </div>
            </NDrawerContent>
        </NDrawer>
    </div>
</template>

<script setup>
import { computed, h, nextTick, onMounted, ref, watch, defineEmits, defineProps } from "vue";
import {
    NDataTable,
    NSelect,
    NDrawer,
    NDrawerContent,
    NDropdown,
} from "naive-ui";
import { useI18n } from "vue-i18n";
import { useRouter, useRoute } from "vue-router";
import { ROUTES } from "../../routes/names";
import {
    SUBSCRIPTION_INFO
} from "../../composables/subscription";
import { PRODUCT_GROUP_OPTS, MARKET_SERVICES_OPTS } from "../../utils/constants";
import {
    PUBLISHED_ALL_PROFILES,
    getAllPublishedProfiles,
    inProgress
} from "../../composables/profiles/list";
import {
    mountProfile as _mountCountryProfile,
    getCountryProfile,
    MOUNTED_COUNTRY_PROFILE,
    MOUNTED_COUNTRIES,
    getPublicCountries,
    COUNTRY_ID,
    getGeneralCountryProfile,
    MOUNTED_GENERAL_COUNTRY_PROFILE,
    MOUNTED_COUNTRY_PROFILES
} from "../../composables/profiles/country";
import {
    getCompanyProfile,
    MOUNTED_COMPANY_PROFILE,
} from "../../composables/profiles/company";
import {
    getPublicSupplyData,
    MOUNTED_PUBLIC_SUPPLY_DATA
} from "../../composables/profiles/supplyData";
import TableHeader from "../Profiles/ProfileTable/TableHeader.vue";
import TableCell from "../Profiles/ProfileTable/TableCell";
import SummaryCard from "../../components/dumb/SummaryCard.vue";
import InfoText from "../../components/dumb/ProfileInfoText";
import { sortBy, uniqBy, groupBy, mapValues, sumBy, chain, map, find, forEach, flattenDeep, omit } from "lodash";

const { t } = useI18n();
const emit = defineEmits();
const router = useRouter();
const route = useRoute();
const props = defineProps(['countryProfileData']);

const COMMODITY_ID = ref(null);
const STATUS_ID = ref(null);
const COUNTRY_DETAILS = ref(null);
// const patData = ref([]);
const showAssetInfo = ref(false)
const selectedCountryElement = ref(null);
const PROFILE_TO_VIEW_IN_DRAWER = ref();

const publicSupplyData = computed(() => {
    return MOUNTED_PUBLIC_SUPPLY_DATA.value || [];
})

const patData = computed(() => {
    let groupedData = groupBy(publicSupplyData.value, 'productName')

    const summedData = map(omit(groupedData, [null]), (group, productName) => {
        const firstNonNullunits = find(group, 'units');
        return {
            productName: `${productName} (${firstNonNullunits ? firstNonNullunits.units : ''})`,
            yearlyData: chain(group)
                .map('yearlyData')
                .flatten()
                .groupBy('year')
                .map((values, year) => ({
                    year: parseInt(year), value: parseFloat(
                        sumBy(values, (entry) => parseFloat(entry.value))
                            .toFixed(1)
                    ).toLocaleString('en-US', { minimumFractionDigits: 1, maximumFractionDigits: 1 }).replace(/,/g, ' ')
                }))
                .value()
        }
    });
    return summedData || []
})

const hasData = computed(() => {
    // Check if all yearlyData arrays are empty
    const allEmpty = patData.value.every(item => item.yearlyData.length === 0);

    // Example usage:
    if (allEmpty) {
        return false
    } else {
        return true
    }

});

const tableColumns = computed(() => {
    if (COMMODITY_ID.value) {
        let columns = [];

        // Product Name Column
        columns.push({
            align: "left",
            key: 'productName',
            fixed: 'left',
            width: 150,
            title(column) {
                return h(TableHeader, {
                    text: 'Product',
                });
            },
            render: (row) => {
                // return row.productName;
                return h(TableCell, {
                    text: row.productName
                });
            },

        });

        // Yearly Data Columns
        const uniqueYears = Array.from(new Set(patData.value.flatMap(entry => entry.yearlyData.map(data => data.year))));
        const yearCol = uniqueYears.sort();

        const currentDate = new Date();
        let currentYear = currentDate.getFullYear();

        for (let i = 0; i < yearCol.length; i++) {
            if (yearCol[i] >= 2018 && yearCol[i] <= currentYear) {
                columns.push({
                    align: "center",
                    key: yearCol[i],
                    width: 100,
                    title(column) {
                        return h(TableHeader, {
                            text: yearCol[i] === currentYear ? `${yearCol[i]}*` : yearCol[i],
                        });
                    },
                    render: (row) => {
                        const yearlyData = row.yearlyData.find(data => data.year === yearCol[i]);
                        return h(TableCell, {
                            className: "rightAlign",
                            text: yearlyData ? yearlyData.value : 0
                        });
                    }
                });
            }
        }
        return columns;
    } else {
        return [];
    }
});

const ASSET_STATUS_OPTS = computed(() => {
    const tmp = [
        ...new Set(
            (PUBLISHED_ALL_PROFILES.value?.assetProfiles || []).map((nth) => nth.status)
        ),
    ];
    const opts = tmp
        .filter((status) => status?.length > 0)
        .map((status) => {
            return {
                label: status,
                value: status,
            };
        });
    let unique = uniqBy(opts, 'label')
    return sortBy(unique, "label");
})

const COUNTRIES_OPTS = computed(() => {
    let countries = (MOUNTED_COUNTRY_PROFILES?.value || []).map(r => {
        return {
            label: r.country_Name,
            value: r.country_Code
        }
    }) || [];
    let unique = uniqBy(countries, 'label');
    return sortBy(unique, 'label')
})

const getElementName = (el) => {
    return MARKET_SERVICES_OPTS.filter(e => e.value === el).map(r => r.label)[0]
}

const COUNTRY_COMMODITY = computed(() => {

    if (!MOUNTED_COUNTRY_PROFILES?.value) {
        return [];
    }

    let commodities = (MOUNTED_COUNTRY_PROFILES?.value || []).filter(r => r.code === COUNTRY_ID.value).map(r => {
        return r.publishDetails?.map(r => {
            return {
                element: r.element,
                label: getElementName(r.element) || r.element,
                value: r.uuid
            }
        }).filter(r => SUBSCRIPTION_INFO.elements?.map((nth) => nth.code).includes(r.element))
    })[0] || [];

    return sortBy(commodities, 'label')
})

const countryAssets = computed(() => {
    if (COUNTRY_ID.value) {
        const assets = (PUBLISHED_ALL_PROFILES.value?.assetProfiles || []).filter(
            (nth) => nth.countryCode === COUNTRY_ID.value
        ).sort((a, b) => a.short_Name.trim().localeCompare(b.short_Name.trim()))

        if (STATUS_ID.value) {
            return assets.filter(r => r.status == STATUS_ID.value)
        }
        else if (COMMODITY_ID.value) {
            return assets.filter(r => (r.byProductCommodities?.includes(selectedCountryElement.value) || r.nonCommercialCommodities?.includes(selectedCountryElement.value) || r.primaryCommodities?.includes(selectedCountryElement.value)) && r.countryCode === COUNTRY_ID.value)
        }
        else {
            return assets
        }
    }
})

const filterAssetStatus = () => {
    return countryAssets.value.filter(r => r.status === STATUS_ID.value)
}

const countryProfiles = computed(() => {
    return PUBLISHED_ALL_PROFILES.value?.countryProfiles || []
})

const companyProfiles = computed(() => {

    return PUBLISHED_ALL_PROFILES.value?.companyProfiles || []
})

const filteredCompanyProfiles = computed(() => {

    let companies = []
    // COLLECT THE OPERATOR AND OWNER OF EACH ASSETS //
    forEach(countryAssets.value, function (value) {
        companies.push({ companyId: value.companyId, companyName: value.companyName })
        let owners = value.owners.map(r => { return { companyId: r.id, companyName: r.companyName } })
        companies.push(owners)
    });

    // REMOVE DUPLICATES
    let unique = uniqBy(flattenDeep(companies), 'companyId');
    let result = []
    forEach(unique, function (value) {
        let a = companyProfiles.value?.filter(r => {
            // if(COMMODITY_ID.value){
            //     return (r.countryCode === COUNTRY_ID.value && r.profileOverviews?.map(r => r.element).includes(selectedCountryElement.value)) || r.companyId === value.companyId
            // }else{
            //     return r.countryCode === COUNTRY_ID.value || r.companyId === value.companyId
            // }
            return r.companyId === value.companyId
        })[0] || value

        a.alias = value.companyName
        if (value.companyId != null) {
            result.push(a)
        }

    });

    let res = result.sort((a, b) => a.companyName?.trim().localeCompare(b.companyName?.trim()));
    return res;
})

const showCountryDetails = async () => {

    COUNTRY_DETAILS.value = countryProfiles.value?.filter(r => {
        return r.uuid === COMMODITY_ID.value
    })[0] || {}

    // THIS WILL BE USED TO GET THE ASSETS OF SELECTED COUNTRY AND COMMODITY
    selectedCountryElement.value = COUNTRY_COMMODITY.value.filter(r => r.value === COMMODITY_ID.value).map(r => r.element)[0];

}

const showDrawer = (nth) => {
    showAssetInfo.value = true
    PROFILE_TO_VIEW_IN_DRAWER.value = {
        type: PROFILE_TO_VIEW_IN_DRAWER.value?.type,
        uuid: nth.uuid,
        title: nth.name || nth.alias,
        profile: nth.profile || nth.companyOverview,
        project: nth.projects || null,
    };
}

const goToAssetProfile = (nth) => {
    if (nth?.assetUUID) {
        router.push({
            name: ROUTES.ASSET_PROFILE.name,
            params: { id: nth?.assetUUID },
            query: { element: selectedCountryElement.value },
        });
        cleanup()
    }
};

// const goToCompanyProfile = (companyUUID) => {
//   if (companyUUID) {
//     router.push({
//       name: ROUTES.COMPANY_PROFILE.name,
//       params: { id: companyUUID },
//       query: { element: selectedCountryElement.value },
//     });
//     cleanup()
//   }
// };

const goToCompanyProfile = async (uuid) => {
    showAssetInfo.value = false
    let data = {
        uuid: uuid,
        selectedElement: selectedCountryElement.value
    }
    await emit('showCompanyProfile', data);
    await cleanup()
};

const cleanup = () => {
    patData.value = []
    COUNTRY_ID.value = null;
    COMMODITY_ID.value = null;
    COUNTRY_DETAILS.value = null;
    selectedCountryElement.value = null;
    MOUNTED_COUNTRY_PROFILE.value = null;
    MOUNTED_GENERAL_COUNTRY_PROFILE.value = null;

}

const getCursorStyle = (nth) => {
    let subs = SUBSCRIPTION_INFO?.elements.map(r => r.code)
    const areAllElementsPresent = nth.byProductCommodities?.some(element => subs.includes(element)) || nth.primaryCommodities?.some(element => subs.includes(element)) || nth.profileOverviews?.map(r => r.element).some(element => subs.includes(element));
    return {
        cursor: areAllElementsPresent ? 'pointer' : 'context-menu',
        'font-weight': areAllElementsPresent ? 'bold' : '',
    };
}

const checkAccess = (nth) => {
    showDrawer(nth)
}

const clearRoute = async () => {
    if (route.query.profileType) {
        router.push({ path: route.path, query: { _: Math.random() } });
        COMMODITY_ID.value = null;
        selectedCountryElement.value = null;
        //   COMMODITY_OVERVIEW_DETAILS.value = null;
        publicSupplyData.value = []
        patData.value = []
        await getCountryProfile({ element: null, uuid: null, code: COUNTRY_ID.value })
        await getGeneralCountryProfile({ code: COUNTRY_ID.value, element: null, uuid: null })
    }
}

const getCountryDetails = async () => {
    MOUNTED_PUBLIC_SUPPLY_DATA.value = []
    await getCountryProfile({ element: null, uuid: null, code: COUNTRY_ID.value });
    await getGeneralCountryProfile({ code: COUNTRY_ID.value, element: null, uuid: null })
}

watch(COUNTRY_ID, async () => {
    if (!COUNTRY_ID.value) {
        cleanup()
    }

    if (!props.countryProfileData || !route.query.uuid) {
        // COMMODITY_ID.value = null;
        COUNTRY_DETAILS.value = null;
        selectedCountryElement.value = null;
        MOUNTED_PUBLIC_SUPPLY_DATA.value = []
    }


});

onMounted(async () => {
    await getAllPublishedProfiles(null)
    await getPublicCountries(null)

    if (props.countryProfileData) {
        COMMODITY_ID.value = props.countryProfileData.uuid
        COUNTRY_ID.value = props.countryProfileData.countryCode

        await getCountryDetails()
        await showCountryDetails()
        selectedCountryElement.value = props.countryProfileData.selectedElement
        await getPublicSupplyData({ company: null, country: COUNTRY_ID.value, element: selectedCountryElement.value, product: null })
    }

    if (route.query.uuid || route.query.element) {
        await getCountryProfile({ element: route.query.element, uuid: route.query.uuid, code: null })
        await getGeneralCountryProfile({ code: MOUNTED_COUNTRY_PROFILE.value.countryCode, element: route.query.element, uuid: null })

        COUNTRY_ID.value = MOUNTED_COUNTRY_PROFILE.value?.countryCode
        if (route.query.element) {
            selectedCountryElement.value = route.query.element
            await showCountryDetails()
            await getPublicSupplyData({ company: selectedCountryElement.value, country: null, element: selectedCountryElement.value, product: null });
        }
    }



})

</script>

<style lang="scss" scoped>
// ROUNDED SCROLLBAR - START //
::-webkit-scrollbar {
    width: 6px;
}

::-webkit-scrollbar-thumb {
    background: darkgrey;
    border-bottom-left-radius: 5px;
    border-top-left-radius: 5px;
    border-bottom-right-radius: 5px;
    border-top-right-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
    background: darkgrey;
}

::-webkit-scrollbar-thumb:active {
    background: darkgrey;
}

// ROUNDED SCROLLBAR - END //</style>