import axios from "axios";
import { BE_SERVICE_URL } from "../utils/constants";
import { log, envs, getAuthToken } from "../utils";

const _axios = axios.create({
  baseURL: BE_SERVICE_URL,
});

_axios.interceptors.request.use((config) => {
  window.$loading.start();
  return config;
});

_axios.interceptors.response.use((response) => {
  window.$loading.finish();
  return response;
});

const _request = async (opts, authorize) => {
  try {
    const headers = {};
    if (authorize) headers.Authorization = `Bearer ${getAuthToken()}`;
    return await _axios({
      ...opts,
      headers,
      timeout: parseInt(envs("HTTP_REQUEST_TIMEOUT")),
    });
  } catch (error) {
    window.$loading.error();
    log(`Request failed w/ error: ${error}`);

    if (error?.code === "ERR_BAD_REQUEST") {
      showErrorMessages(error.response?.data);
      return error.response || null;
    } else window.$message.error("Request failed");
  }
  return null;
};

export const gqlRequest = async (schema, signal = null, baseURL = null) => {
  const opts = {
    url: "/graphql",
    method: "POST",
    data: schema,
    signal,
  };
  if (baseURL) opts.baseURL = baseURL;
  const response = await _request(opts, true);
  if (response?.data?.errors) {
    showErrorMessages(response.data.errors[0]);
    return null;
  }
  return response?.data;
};

export const apiRequest = async (
  endpoint,
  method,
  data = {},
  signal = null,
  authorize = true
) => {
  const opts = {
    url: endpoint,
    method,
    data,
    signal,
  };
  return await _request(opts, authorize);
};

export const showErrorMessages = (data) => {
  if (data?.message || data?.extensions) {
    const errorMessage = data?.message;
    const errorExtensions =
      data?.extensions?.response?.message ||
      data?.extensions?.exception?.response?.message ||
      null;
    // Note: for blue, show the generic error message
    if (typeof errorMessage === "string" || errorMessage instanceof String)
      window.$message.error(errorMessage);
    else if (errorExtensions instanceof Array && errorExtensions?.length)
      window.$message.error(errorExtensions[0]);
    else if (errorMessage instanceof Array && errorMessage?.length)
      window.$message.error(errorMessage[0]);

    // Note:
    // error message format might be different for GQL queries/mutations
    // might be better to improve this function in the future
  }
};

export const isSuccess = (status) => [200, 201].includes(status);
