<template>
  <div class="card card-plain shadow-none" id="sidenavCard">
    <div class="p-3 card-body text-center w-100 pt-0">
      <h6 class="mb-0 text-dark up">
        {{ $t("App.sidebarMenu.needHelpTitle") }}
      </h6>
      <p class="text-xs font-weight-bold">
        {{ $t("App.sidebarMenu.sendMessage") }}
      </p>
    </div>
    <a target="_blank" href="mailto:info@theprojectblue.com" class="btn btn-default btn-default-alt text-white">{{ $t("App.sidebarMenu.sendEmail") }}</a>
  </div>
</template>
<script>
import img from "@/argon/assets/img/illustrations/icon-documentation.svg";

export default {
  name: "sidenav-card",
  data() {
    return {
      img,
    };
  },
};
</script>