import app from "./app";
import store from "@/argon/store";
import router from "@/routes";
// import router from "@/argon/router"; // TODO: remove if not used
import i18n from "@/locales";
import argonV3 from "@/argon/argonV3";
import "@/assets/proxima-blue.scss";
import VueVideoPlayer from "@videojs-player/vue";
import "video.js/dist/video-js.css";

app.use(store);
app.use(router);
app.use(i18n);
app.use(argonV3);
app.use(VueVideoPlayer);

app.mount("#app");