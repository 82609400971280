import { createRouter, createWebHashHistory } from "vue-router";
import { applyMetadata, isPrivateOnly, isPublicOnly } from "./guards";
import { ROUTES } from "./names";

import Login from "../pages/Login.vue";
import Register from "../pages/Register.vue";
import ResetPassword from "../pages/ResetPassword.vue";
import AppLayout from "../components/AppLayout.vue";
import Dashboard from "../pages/Home/Dashboard.vue";
import Articles from "../pages/Home/Articles.vue";
import ProximaProfiles from "../pages/ProximaProfiles/List.vue";
import Asset from "../pages/ProximaProfiles/Asset.vue";
import Company from "../pages/ProximaProfiles/Company.vue";
import Country from "../pages/ProximaProfiles/Country.vue";
import Files from "../pages/Files/List.vue";
import FileSeries from "../pages/Files/Series.vue";
import File from "../pages/Files/File.vue";
import Streams from "../pages/Streams/List.vue";
import TradeTool from "../pages/TradeTool/List.vue";
import Subscription from "../pages/Subscription.vue";
import Error404 from "../pages/Errors/PageNotFound.vue";
import Error500 from "../pages/Errors/InternalError.vue";
import MarketSupportSuccess from "../pages/Files/MarketSupportSuccess.vue";
import MarketData from "../pages/MarketData/MarketData.vue";
import CostAnalysis from "../pages/CostAnalysis/CostAnalysis.vue";
import Support from "../pages/Support/Support.vue";



// TODO: remove unused pages
// import Notes from "../pages/Notes/List.vue";
// import Note from "../pages/Notes/Note.vue";

const routes = [
  {
    alias: "",
    ...ROUTES.LOGIN,
    component: Login,
    beforeEnter: isPublicOnly,
  },
  {
    ...ROUTES.REGISTER,
    component: Register,
    beforeEnter: isPublicOnly,
  },
  {
    ...ROUTES.RESET_PASSWORD,
    component: ResetPassword,
    beforeEnter: isPublicOnly,
  },
  {
    path: "/app",
    redirect: { name: ROUTES.PROXIMA_PROFILES.name },
    beforeEnter: isPrivateOnly,
    component: AppLayout,
    children: [
      {
        ...ROUTES.DASHBOARD,
        component: Dashboard,
      },
      {
        ...ROUTES.ARTICLES,
        component: Articles,
      },
      {
        ...ROUTES.PROXIMA_PROFILES,
        component: ProximaProfiles,
      },
      {
        ...ROUTES.ASSET_PROFILE,
        component: Asset,
      },
      {
        ...ROUTES.COMPANY_PROFILE,
        component: Company,
      },
      {
        ...ROUTES.COUNTRY_PROFILE,
        component: Country,
      },
      {
        ...ROUTES.FILES,
        component: Files,
      },
      {
        ...ROUTES.FILE_SERIES,
        component: FileSeries,
      },
      {
        ...ROUTES.FILE,
        component: File,
      },
      {
        ...ROUTES.STREAMS,
        component: Streams,
      },
      // TODO: remove unused routes and componenets
      // {
      //   ...ROUTES.NOTES,
      //   component: Notes,
      // },
      // {
      //   ...ROUTES.NOTE,
      //   component: Note,
      // },
      {
        ...ROUTES.TRADE_TOOL,
        component: TradeTool,
      },
      {
        ...ROUTES.SUBSCRIPTION,
        component: Subscription,
      },
      {
        ...ROUTES.MARKET_DATA,
        component: MarketData,
      },
      {
        ...ROUTES.COST_ANALYSIS,
        component: CostAnalysis,
      },
      {
        ...ROUTES.SUPPORT,
        component: Support,
      },
    ],
  },
  {
    ...ROUTES.PAGE_NOT_FOUND,
    component: Error404,
  },
  {
    ...ROUTES.INTERNAL_ERROR,
    component: Error500,
  },
  {
    ...ROUTES.UNKNOWN_URL,
    component: Error404,
  },
  {
    ...ROUTES.MARKET_SUPPORT_SUCCESS,
    component: MarketSupportSuccess,
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { top: 0 };
  },
});

router.beforeEach((to, from, next) => {
  applyMetadata(to, from, next);
});

export default router;
