<template>
  <main class="main-content mt-0">
    <div class="page-header min-vh-100">
      <span class="mask bg-gradient-warning opacity-4"></span>
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-6 col-md-7 mx-auto text-center">
            <h1
              class="display-1 text-bolder text-white fadeIn1 fadeInBottom mt-5"
            >
              {{ $t("Error500.title") }}
            </h1>
            <h2 class="fadeIn3 fadeInBottom mt-3 text-white">
              {{ $t("Error500.subtitle") }}
            </h2>
            <p class="lead fadeIn2 fadeInBottom text-white">
              {{ $t("Error500.message") }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>
