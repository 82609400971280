import { ref } from "vue";
import { query } from "gql-query-builder";
import HttpClient from "project-blue-http-client";
import { GQL_QUERIES } from "../../utils/APIs";

export const PUBLIC_ARTICLES = ref(null);
export const inProgress = ref(false);
export const reqSignal = ref(new AbortController());

export const fields = [
  "id",
  "name",
  "publishDate",
  "authorName",
  "category",
  "countries",
  "companies",
  "elements",
  "products",
  "description",
  "feed",
  "opinion",
  "bannerImage",
  "externalUrl",
  "type",
  "authorAlias"
];

export const getPublicArticles = async () => {
  const gqlQuery = GQL_QUERIES.GET_PUBLIC_ARTICLES;
  reqSignal.value.abort();
  reqSignal.value = new AbortController();
  inProgress.value = true;

  
  let client = new HttpClient(gqlQuery.baseUrl);
  client.get(`${gqlQuery.controller}/${gqlQuery.operation}`)
  .then(response => {
    PUBLIC_ARTICLES.value = response;
  });
  inProgress.value = false;

};
