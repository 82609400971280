<template>
  <main class="main-content mt-0">
    <div class="page-header min-vh-100">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-6 col-md-7 mx-auto text-center">
            <h1 class="display-1 text-bolder text-primary">
              {{ $t("Error404.title") }}
            </h1>
            <h2>{{ $t("Error404.subtitle") }}</h2>
            <p class="lead">
              {{ $t("Error404.message") }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>
