import { query } from "gql-query-builder";
import { computed, reactive, ref } from "vue";
import { GQL_QUERIES } from "../utils/APIs";
import { BE_CORE_SERVICE_URL } from "../utils/constants";
import HttpClient from "project-blue-http-client";
import { SUBSCRIPTION_INFO } from "./subscription";

export const COMMODITIES = ref([]);
export const COMMODITIES_OPTS = computed(() => {
  const elements = SUBSCRIPTION_INFO.elements?.map((nth) => nth.code);
  return (
    COMMODITIES.value
      ?.filter((nth) => nth.elements?.find((el) => elements.includes(el)))
      .map((nth) => {
        return {
          label: nth.text,
          value: nth.id,
        };
      }) || []
  );
});
export const inProgress = ref(false);
export const reqSignal = ref(new AbortController());

export const getCommodities = async () => {
  const gqlQuery = GQL_QUERIES.GET_COMMODITIES;
  reqSignal.value.abort();
  reqSignal.value = new AbortController();
  inProgress.value = true;
  const payload = query([
    {
      operation: gqlQuery.operation,
      variables: {},
      fields: ["id", "text", "elements"],
    },
  ]);
  const response = await gqlRequest(
    payload,
    reqSignal.value.signal,
    BE_CORE_SERVICE_URL
  );
  inProgress.value = false;

  COMMODITIES.value = response?.data?.[gqlQuery.operation] || [];
};

export const singleFetchTradesParams = (gqlQuery, HSCode, year, tradeFlow) => {
  return {
    operation: {
      name: gqlQuery.operation,
      alias: tradeFlow,
    },
    fields: [
      "reportingType",
      "reporterId",
      "reporterName",
      {
        history: [
          "partnerId",
          "partnerName",
          "isExcluded",
          "monthly",
          { yearly: ["year", "usdValue", "netWeight", "uvp", "priority"] },
        ],
      },
    ],
    variables: {
      [`${tradeFlow}_input`]: {
        value: {
          HSCode,
          tradeFlow,
          reporterIds: null,
          reportingType: null,
          weightConversion: null,
          startYear: year,
          endYear: year,
        },
        type: gqlQuery.input,
        required: true,
        name: "input",
      },
    },
  };
};
