import { has as _has } from "lodash";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import ComponentPreparing from "../components/dumb/LoadingInprogress.vue";
import ComponentFailed from "../components/dumb/LoadingFailed.vue";
import elementsInfo from "./elements.json";

dayjs.extend(relativeTime);

// use in async loaded components
export const preloadComp = {
  loadingComponent: ComponentPreparing,
  errorComponent: ComponentFailed,
};

// get specific env variables defined in .env
export const envs = (env) => {
  const prefix = "VUE_APP";
  return process.env[`${prefix}_${env}`];
};

// log only when in development mode
export const log = (message) => {
  const isDebugEnabled = false || envs("MODE") === "development";
  if (isDebugEnabled) console.log(message);
};

export const getAuthToken = () => {
  return localStorage.getItem("Jwt"); //check if N/A
};

export const setAuthToken = (token) => {
  return localStorage.setItem("Jwt", token);
};

export const removeAuthToken = () => {
  return localStorage.removeItem("Jwt");
};

export const getRefreshToken = () => {
  return localStorage.getItem(envs("REFRESH_TOKEN"));
};

export const setRefreshToken = (token) => {
  return localStorage.setItem(envs("REFRESH_TOKEN"), token);
};

export const removeRefreshToken = () => {
  return localStorage.removeItem(envs("REFRESH_TOKEN"));
};

export const elementInfo = (element) => {
  for (const nth of elementsInfo.elements) {
    if (nth.symbol === element) return nth.summary;
  }
  return null;
};

export const toRelativeTime = (timestamp) => {
  return dayjs().from(timestamp, true).toString() + " ago";
};
