<template>
  <Sidenav />
  <main
    class="main-content position-relative max-height-vh-100 h-100 border-radius-lg"
  >
    <Navbar class="px-0 mx-4" />
    <RouterView v-slot="{ Component }">
      <!-- Note: for now, we only want to cache profiles list -->
      <KeepAlive include="[ProximaProfiles]">
        <component :is="Component" />
      </KeepAlive>
    </RouterView>
  </main>
</template>

<script setup>
import { onBeforeMount, onBeforeUnmount } from "vue";
import { useRoute, useRouter } from "vue-router";
import Sidenav from "./Sidenav";
import Navbar from "./Navbar";
import { validateToken } from "../composables/auth";
import {
  getSubscriptionInfo,
  getNotifications,
} from "../composables/subscription";
import { PUBLISHED_PROFILES } from "../composables/profiles/list";

const router = useRouter();
const route = useRoute();

onBeforeMount(async () => {
  await getSubscriptionInfo();
  await getNotifications();
});

onBeforeUnmount(async () => {
  PUBLISHED_PROFILES.value = {};
});
</script>
