<template>
  <div class="row">
    <div class="col-12">
      <NSpin :show="props.loading">
        <canvas ref="ctx"></canvas>
      </NSpin>
    </div>
  </div>
</template>

<script setup>
import { NSpin } from "naive-ui";
import { nextTick, watch, ref, toRaw } from "vue";
import Chart from "chart.js/auto";
import stc from "string-to-color";

const props = defineProps({
  tradeData: {
    type: Array,
    default: () => [],
  },
  years: {
    type: Array,
    default: () => [],
  },
  monthlyView: {
    type: Boolean,
    default: false,
  },
  loading: {
    type: Boolean,
    default: false,
  },
  topReporters: {
    type: Array,
    default: () => [],
  },
});

const ctx = ref(null);
const stackChart = ref(null);

watch(
  () => props.tradeData,
  (tradeData) => {
    cleanTradeData(tradeData, props.monthlyView);
  }
);

watch(
  () => props.monthlyView,
  (monthlyView) => {
    cleanTradeData(props.tradeData, monthlyView);
  }
);

watch(
  () => props.topReporters,
  (monthlyView) => {
    cleanTradeData(props.tradeData, props.monthlyView);
  }
);

// Note: there may be duplicates depending how it's called. so don't forget to clean data
const cleanTradeData = (tradeData, view) => {
  const cleanTradeData = (tradeData || []).reduce((obj, cur) => {
    if (cur.reporterId in obj) {
      const _tmp = cur;
      _tmp.history.monthly = {
        ...obj[cur.reporterId].history.monthly,
        ..._tmp.history.monthly,
      };
      const yearlyData = _tmp.history.yearly.concat(
        obj[cur.reporterId].history.yearly
      );
      _tmp.history.yearly = [
        ...new Map(yearlyData.map((item) => [item["year"], item])).values(),
      ];
      return { ...obj, [cur.reporterId]: _tmp };
    } else return { ...obj, [cur.reporterId]: cur };
  }, {});
  if (view) generateMonthlyStackBarGraph(cleanTradeData);
  else generateYearlyStackBarGraph(cleanTradeData);
};

const generateYearlyStackBarGraph = (tradeData) => {
  let datasets = [];
  if (props.years?.length !== 2) return;
  const startYear = new Date(props.years?.[0]).getFullYear();
  const endYear = new Date(props.years?.[1]).getFullYear();
  const years = Array.from(
    { length: endYear - startYear + 1 },
    (_, i) => startYear + i
  );
  const othersReporterName = "Others";
  const otherReporters = {
    label: othersReporterName,
    data: years.map((nth) => 0),
    backgroundColor: stc(othersReporterName),
    reporterId: null,
    stack: "uvpStack",
  };

  for (const row of Object.values(tradeData)) {
    if (row.reporterId === "WORLD_TOTAL") continue;

    const nth = {
      label: row.reporterName,
      data: [],
      backgroundColor: stc(row.reporterId),
      reporterId: row.reporterId,
      stack: "uvpStack",
    };

    // Note: plot UVP in graph. maybe better to have option for weight and usd
    for (const year of years) {
      const uvp =
        (row.history.yearly || []).find((ith) => ith.year === year)?.uvp || 0;
      nth.data.push(uvp);
    }

    datasets.push(nth);
  }

  if (props.topReporters?.length > 0) {
    const _datasetToGroup = [];
    const _selected = [];
    for (const nth of datasets) {
      if (props.topReporters.includes(nth.reporterId)) {
        _selected.push(nth);
      } else _datasetToGroup.push(nth);
    }
    datasets = _selected;

    // compute total to make _datasetToGroup
    for (const nth of _datasetToGroup) {
      for (const [index, yearData] of nth.data.entries()) {
        otherReporters.data[index] = otherReporters.data[index] + yearData;
      }
    }
    datasets.push(otherReporters);
  }

  nextTick(() => {
    const data = {
      labels: years,
      datasets,
    };

    toRaw(stackChart.value)?.destroy();
    stackChart.value = new Chart(ctx.value, {
      type: "bar",
      data,
      options: {
        scales: {
          x: {
            stacked: true,
          },
          y: {
            stacked: true,
            beginAtZero: true,
          },
        },
        plugins: {
          legend: {
            display: props.topReporters?.length > 0,
            position: "bottom",
          },
        },
      },
    });
  });
};

const generateMonthlyStackBarGraph = (tradeData) => {
  let datasets = [];
  if (props.years?.length !== 2) return;
  const startYear = new Date(props.years?.[0]).getFullYear();
  const endYear = new Date(props.years?.[1]).getFullYear();
  const years = Array.from(
    { length: endYear - startYear + 1 },
    (_, i) => startYear + i
  );
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sept",
    "Oct",
    "Nov",
    "Dec",
  ];
  const othersReporterName = "Others";
  const otherReporters = {
    label: othersReporterName,
    data: [],
    backgroundColor: stc(othersReporterName),
    reporterId: null,
    stack: "uvpStack",
  };
  const labels = [];
  for (const year of years) {
    for (const [index, month] of months.entries()) {
      labels.push(`${month} ${year}`);
      otherReporters.data.push(0);
    }
  }

  for (const row of Object.values(tradeData)) {
    if (row.reporterId === "WORLD_TOTAL") continue;

    const nth = {
      label: row.reporterName,
      data: [],
      backgroundColor: stc(row.reporterId),
      reporterId: row.reporterId,
      stack: "uvpStack",
    };

    // Note: plot UVP in graph. maybe better to have option for weight and usd
    for (const year of years) {
      for (const [index, month] of months.entries()) {
        const uvp =
          (row.history.monthly?.[year] || []).find(
            (ith) => ith.month === index + 1
          )?.uvp || 0;
        nth.data.push(uvp);
      }
    }

    datasets.push(nth);
  }

  if (props.topReporters?.length > 0) {
    const _datasetToGroup = [];
    const _selected = [];
    for (const nth of datasets) {
      if (props.topReporters.includes(nth.reporterId)) {
        _selected.push(nth);
      } else _datasetToGroup.push(nth);
    }
    datasets = _selected;

    // compute total to make _datasetToGroup
    for (const nth of _datasetToGroup) {
      for (const [index, monthlyData] of nth.data.entries()) {
        otherReporters.data[index] = otherReporters.data[index] + monthlyData;
      }
    }
    datasets.push(otherReporters);
  }

  nextTick(() => {
    const data = {
      labels,
      datasets,
    };

    toRaw(stackChart.value)?.destroy();
    stackChart.value = new Chart(ctx.value, {
      type: "bar",
      data,
      options: {
        scales: {
          x: {
            stacked: true,
          },
          y: {
            stacked: true,
            beginAtZero: true,
          },
        },
        plugins: {
          legend: {
            display: props.topReporters?.length > 0,
            position: "bottom",
          },
        },
      },
    });
  });
};
</script>
