export const PROXIMA_METADATA = "Proxima Blue";

// Note: child pages don't need prefix '/' in "path"

export const ROUTES = {
  LOGIN: {
    path: "/login",
    name: "Login",
    meta: { title: PROXIMA_METADATA },
  },
  REGISTER: {
    path: "/register",
    name: "Register",
    meta: { title: PROXIMA_METADATA },
  },
  RESET_PASSWORD: {
    path: "/reset-password",
    name: "ResetPassword",
    meta: { title: PROXIMA_METADATA },
  },
  DASHBOARD: {
    path: "dashboard",
    name: "Dashboard",
    meta: { title: PROXIMA_METADATA },
  },
  ARTICLES: {
    path: "articles/:id/:name",
    name: "Articles",
    meta: { title: PROXIMA_METADATA },
  },
  PROXIMA_PROFILES: {
    path: "proxima",
    name: "ProximaProfiles", // Assets, Company, Country profiles
    meta: { title: PROXIMA_METADATA },
  },
  ASSET_PROFILE: {
    path: "asset/:id",
    name: "AssetProfile",
    meta: { title: PROXIMA_METADATA },
  },
  COMPANY_PROFILE: {
    path: "company/:id",
    name: "CompanyProfile",
    meta: { title: PROXIMA_METADATA },
  },
  COUNTRY_PROFILE: {
    path: "country/:id",
    name: "CountryProfile",
    meta: { title: PROXIMA_METADATA },
  },
  FILES: {
    path: "files",
    name: "Files",
    meta: { title: PROXIMA_METADATA },
  },
  FILE_SERIES: {
    path: "file-series/:element",
    name: "FileSeries",
    meta: { title: PROXIMA_METADATA },
  },
  FILE: {
    path: "file/:series/:element",
    name: "FilePreview",
    meta: { title: PROXIMA_METADATA },
  },
  STREAMS: {
    path: "streams",
    name: "ProximaStreams",
    meta: { title: PROXIMA_METADATA },
  },
  NOTES: {
    path: "notes",
    name: "Notes",
    meta: { title: PROXIMA_METADATA },
  },
  NOTE: {
    path: "notes/:id",
    name: "NotesPreview",
    meta: { title: PROXIMA_METADATA },
  },
  SUBSCRIPTION: {
    path: "subscription",
    name: "Subscription",
    meta: { title: PROXIMA_METADATA },
  },
  TRADE_TOOL: {
    path: "trade-tool",
    name: "TradeTool",
    meta: { title: PROXIMA_METADATA },
  },
  PAGE_NOT_FOUND: {
    path: "/404",
    name: "NotFound",
    meta: { title: PROXIMA_METADATA },
  },
  INTERNAL_ERROR: {
    path: "/500",
    name: "InternalError",
    meta: { title: PROXIMA_METADATA },
  },
  UNKNOWN_URL: {
    path: "/:pathMatch(.*)*",
    name: "UnknownPage",
    meta: { title: PROXIMA_METADATA },
  },
  MARKET_SUPPORT_SUCCESS: {
    path: "/market-support-success",
    name: "MarketSupportSuccess",
    meta: { title: PROXIMA_METADATA },
  },
  MARKET_DATA: {
    path: "/market-data",
    name: "MarketData",
    meta: { title: PROXIMA_METADATA },
  },
  COST_ANALYSIS: {
    path: "/cost-analysis",
    name: "CostAnalysis",
    meta: { title: PROXIMA_METADATA },
  },
  SUPPORT: {
    path: "/support",
    name: "Support",
    meta: { title: PROXIMA_METADATA },
  },

};
