<template>
  <h3 class="text-center p-3">
    Click to learn more about our other market services
  </h3>
  <div class="card proxima-bg-light">
    <Carousel
      v-if="marketServices.length"
      :items-to-show="2.5"
      :wrap-around="true"
      :breakpoints="breakpoints"
      :autoplay="3000"
      class="mt-3"
    >
      <Slide v-for="(nth, i) in marketServices" :key="i">
        <div @click="goToMarketServices(nth.name)">
          <div class="blog-grid">
            <figure class="project-thumbnail-img">
              <img
                :src="`images/${nth.name.toLowerCase()}/image2.png`"
                onerror="this.src='https://via.placeholder.com//992x800'"
                :alt="nth.name.toLowerCase()"
                style="width: 100%"
                class="rounded shadow"
              />
              <figcaption>
                <h4 style="font-size: 20px">
                  {{
                    nth.name.charAt(0).toUpperCase() +
                    nth.name.slice(1).toLowerCase().replace(/_/g, " ")
                  }}
                </h4>
                <h5 style="font-size: 16px">
                  {{
                    marketServicesCategories[nth?.name]
                      ?.map(
                        (jth) =>
                          $t(
                            `Subscriptions.MarketServices.genericCategories.${jth}`
                          ).toString() || ""
                      )
                      .join("\n\n")
                  }}
                </h5>
              </figcaption>
            </figure>
          </div>
        </div>
      </Slide>
      <template #addons>
        <Navigation />
      </template>
    </Carousel>
    <h5 v-else class="text-center p-3">
      You've already subscribed to all our services. Thank you!
    </h5>
  </div>
</template>

<script setup>
import { Carousel, Navigation, Slide } from "vue3-carousel";
import "vue3-carousel/dist/carousel.css";
import { computed } from "vue";
import {
  SUBSCRIPTION_INFO,
} from "../../composables/subscription";
import {
  marketServicesCategories,
  MARKETING_SITE_SERVICES,
} from "../../composables/generics";

const pbDomain = `https://projectblue.com`;
// BREAK POINTS FOR CAROUSEL //
const breakpoints = {
  // 700px and up
  700: {
    itemsToShow: 4,
    snapAlign: "center",
  },
  // 1024 and up
  1024: {
    itemsToShow: 4,
    snapAlign: "start",
  },
};

const marketServices = computed(() => {
  const subscribedElements = SUBSCRIPTION_INFO.elements;
  const objKeys = Object.keys(MARKETING_SITE_SERVICES);
  const objValues = Object.values(MARKETING_SITE_SERVICES);
  const services = objKeys.map((key, index) => {
    if (key === "lithium_industry_cost_service") key = "LCS";
    if (key === "vanadium_extractive_cost_service") key = "VECS";
    return {
      code: key,
      name: objValues[index],
    };
  });

  let nonSubs = services.filter((service) => {
    // Check if the code of the service is not present in subscribedElements
    return !subscribedElements.some((element) => element.code === service.code);
  });

  return nonSubs;
});

const goToMarketServices = (nth) => {
  window.open(`${pbDomain}/subscriptions/critical-materials/${nth}`);
};
</script>

<style lang="scss" scoped>
@import url("../../assets/styles/home-page-styles.scss");
</style>
