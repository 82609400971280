<template>
  <div
    class="px-3 text-sm text-wrap m-0 font-weight-bold cursor-pointer"
    style="line-height: 1.2"
    @click="showPreview"
  >
  <img :src="getIcon(props.url)" alt="icon" class="image-fluid file-icon">
    {{ props.text || $t("Files.noDisplayName") }} 
  </div>
</template>

<script setup>

function getFileExtension(url) {
  // Match any characters after the last dot before a question mark or end of string
  const match = url.match(/\.([a-zA-Z0-9]+)(?:\?|$)/);
  return match ? match[1].toLowerCase() : null;
}
function getIcon(url) {
  var ext = getFileExtension(url);
  switch (ext) {
    case "pdf":
      return "images/pdf_icon.png";
    default:
      return "images/xls_icon.png"
      break;
  }
}

const props = defineProps({
  text: {
    type: String,
    default: null,
  },
  url: {
    type: String,
    default: false,
  },
  type: {
    type: String,
    default: false,
  },
});

const showPreview = () => {

  if (props.url) {
    window.open(
      `https://docs.google.com/viewer?embedded=true&url=${props.url}`
    );
  }
};
</script>
