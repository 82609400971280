<template>
  <div class="container">
    <div class="row">
      <div class="col-4">
        <n-popover trigger="hover">
          <template #trigger>
            <i @click="sendToMyEmail()" class="fa fa-envelope text-xs download-icon" aria-hidden="true"></i>
          </template>
          <span>Send to email</span>
        </n-popover>
      </div>
      <div class="col-4">
        <n-popover trigger="hover">
          <template #trigger>
            <i @click="viewFile()" class="fa fa-eye text-xs eye-icon" aria-hidden="true"></i>
          </template>
          <span>View</span>
        </n-popover>
      </div>
      <div class="col-4">
        <n-popover trigger="hover">
          <template #trigger>
            <i @click="downloadMyFile()" class="fa fa-download text-xs download-icon" aria-hidden="true"></i>
          </template>
          <span>Download File</span>
        </n-popover>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import { NPopover, useMessage } from "naive-ui";
import { sendFileToEmail, downloadFile } from "../../composables/files/list";

const showPopover = ref(false);
const message = useMessage();

const props = defineProps({
  selectedElement: {
    type: String,
    default: null,
  },
  type: {
    type: String,
    default: null,
  },
  url: {
    type: String,
    default: false,
  },
  id: {
    type: Number,
    default: null,
  },
});
const viewFile = () => {
  var fileExtension = props.url.split('.').pop().split('?')[0].toLowerCase();
  if (fileExtension == ".xls" || fileExtension == ".xlsx") {
    window.open(`https://docs.google.com/viewer?embedded=true&url=${props.url}`);
    return;
  }
  window.open(props.url);
};

const sendToMyEmail = async () => {
  await sendFileToEmail(props.id);
};

const downloadMyFile = async () => {
  await downloadFile(props.id, props.url)
};
</script>

<style lang="css" scoped>
.download-icon {
  color: #192e40;
  cursor: pointer;
}
</style>
