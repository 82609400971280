<template>
  <main class="main-content mt-0">
    <div
      class="page-header align-items-start min-vh-20 pt-5 pb-11 m-3 border-radius-lg"
      style="
        background-image: url('https://raw.githubusercontent.com/creativetimofficial/public-assets/master/argon-dashboard-pro/assets/img/signup-cover.jpg');
        background-position: top;
      "
    >
      <span class="mask bg-gradient-dark opacity-6"></span>
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-5 text-center mx-auto">
            <h1 class="text-white mb-2 mt-5">{{ $t("Register.welcome") }}</h1>
            <p class="text-lead text-white">
              {{ $t("Register.headline") }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row mt-lg-n10 mt-md-n11 mt-n10 justify-content-center">
        <div class="col-xl-5 col-lg-5 col-md-7 mx-auto">
          <div class="card z-index-0">
            <div class="card-header text-center pt-4">
              <h5>
                {{ $t("Register.title") }}
              </h5>
            </div>
            <div class="card-body">
              <div role="form">
                <div class="mb-3">
                  <NInput
                    type="text"
                    v-model:value="credentials.name"
                    :placeholder="$t('Register.fullNamePlaceholder')"
                    @keyup.enter="registerUser()"
                    size="large"
                  />
                </div>
                <div class="mb-3">
                  <NInput
                    type="email"
                    v-model:value="credentials.email"
                    :placeholder="$t('Register.emailPlaceholder')"
                    @keyup.enter="registerUser()"
                    size="large"
                  />
                </div>
                <div class="mb-3">
                  <NInput
                    type="password"
                    v-model:value="credentials.password"
                    :placeholder="$t('Register.passwordPlaceholder')"
                    @keyup.enter="registerUser()"
                    size="large"
                  />
                </div>
                <div class="mb-3">
                  <NInput
                    type="text"
                    v-model:value="credentials.company"
                    :placeholder="$t('Register.companyPlaceholder')"
                    @keyup.enter="registerUser()"
                    size="large"
                  />
                </div>
                <div class="mb-3">
                  <NSelect
                    v-model:value="credentials.country"
                    :options="countriesList"
                    clearable
                    filterable
                    :placeholder="$t('Register.countryPlaceholder')"
                    size="large"
                  />
                </div>
                <div class="mb-3">
                  <NInputGroup>
                    <NInputGroupLabel size="large">{{
                      countryMobileCode
                    }}</NInputGroupLabel>
                    <NInput
                      type="text"
                      v-model:value="credentials.contactNumber"
                      :placeholder="$t('Register.contactNumberPlaceholder')"
                      :allow-input="mobileNumberFormat"
                      @keyup.enter="registerUser()"
                      size="large"
                    />
                  </NInputGroup>
                </div>
                <div class="mb-3">
                  <NSelect
                    v-model:value="credentials.interests"
                    :options="interestsList"
                    multiple
                    clearable
                    filterable
                    :placeholder="$t('Register.interestsPlaceholder')"
                    size="large"
                  />
                </div>
                <div class="mb-3">
                  <NSelect
                    v-model:value="credentials.sectors"
                    :options="sectorsList"
                    multiple
                    clearable
                    filterable
                    :placeholder="$t('Register.sectorsPlaceholder')"
                    size="large"
                  />
                </div>
                <div class="text-center">
                  <button
                    class="btn btn-md proxima-blue-bg-gradient bg-gradient-success text-white w-100 mb-0 mt-4"
                    :disabled="!isReadyToSubmit"
                    @click="submitForCaptcha()"
                  >
                    {{ $t("Register.registerButton") }}
                  </button>
                  <VueHcaptcha
                    ref="hCaptcha"
                    sitekey="de74b7d8-cbfc-42fd-bd06-609e78d5868a"
                    data-size="invisible"
                    @verify="registerUser"
                  />
                </div>
              </div>
              <p class="text-sm mt-3 mb-0">
                Already have an account?
                <RouterLink
                  :to="{ name: ROUTES.LOGIN.name }"
                  class="text-dark font-weight-bolder"
                  >Sign in</RouterLink
                >
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script setup>
import { computed, onMounted, onUnmounted, reactive, ref } from "vue";
import { useRouter } from "vue-router";
import { NSelect, NInput, NInputGroup, NInputGroupLabel } from "naive-ui";
import { ROUTES } from "../routes/names";
import { register } from "../composables/auth";
import { COUNTRIES_LIST } from "../utils/constants";
import VueHcaptcha from "@hcaptcha/vue3-hcaptcha";

const router = useRouter();
const hCaptcha = ref(null);
const body = document.getElementsByTagName("body")[0];

onMounted(() => {
  body.classList.remove("bg-gray-100");
});

onUnmounted(() => {
  body.classList.add("bg-gray-100");
});

const countriesList = COUNTRIES_LIST.map((nth) => {
  return { label: nth.name, value: nth.code, mobileCode: nth.mobileCode };
});
const countryMobileCode = computed(() => {
  return (
    COUNTRIES_LIST.find((nth) => nth.code === credentials.country)
      ?.mobileCode || "+000"
  );
});

const sectorsList = [
  "Aluminium",
  "Antimony",
  "Boron",
  "Chromium",
  "Cobalt",
  "Copper",
  "Fluorspar",
  "Gallium",
  "Germanium",
  "Graphite",
  "Hafnium",
  "Hydrogen",
  "Indium",
  "Iron",
  "Lithium",
  "Magnesium",
  "Manganese",
  "Molybdenum",
  "Nickel",
  "Niobium",
  "PGMs",
  "Rare Earths",
  "Rhenium",
  "Salt",
  "Scandium",
  "Silicon",
  "Strontium",
  "Tantalum",
  "Tellurium",
  "Tin",
  "Titanium",
  "Tungsten",
  "Vanadium",
  "Yttrium",
  "Zirconium",
].map((nth) => {
  return { label: nth, value: nth };
});

const interestsList = [
  "Aerospace",
  "Automotive",
  "Batteries",
  "Electricity Networks",
  "Energy Storage",
  "Hydrogen Economy",
  "ICT",
  "Magnets & Motors",
  "Renewables",
  "Solar Power",
  "Wind Power",
  "Steel & Alloys",
].map((nth) => {
  return { label: nth, value: nth };
});

const credentials = reactive({
  name: null,
  email: null,
  password: null,
  company: null,
  country: null,
  interests: null,
  sectors: null,
  contactNumber: null,
});

const isReadyToSubmit = computed(() => {
  return credentials.name && credentials.email && credentials.password;
});

const registerUser = async () => {
  if (!isReadyToSubmit.value) {
    window.$message.info("Please provide your name, email, and password");
    return;
  }
  const isAuthorized = await register(credentials);
  if (isAuthorized) router.push({ name: ROUTES.LOGIN.name });
};

// https://en.wikipedia.org/wiki/List_of_mobile_telephone_prefixes_by_country
const mobileNumberFormat = (value) =>
  !value || (/^\d+$/.test(value) && value?.length <= 15);

const submitForCaptcha = () => {
  if (isReadyToSubmit.value) hCaptcha.value.execute();
};
</script>
