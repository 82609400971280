<script setup>
import { ref, onMounted, computed, nextTick, defineProps } from "vue";
import { NPopselect, NIcon } from "naive-ui";
import { useI18n } from "vue-i18n";
import { ArrowLeft } from "@vicons/tabler";
import { LAST_SELECTED_ELEMENT_TO_VIEW, COUNTRY_TO_VIEW } from "../../composables/generics";
import {
  SUBSCRIPTION_INFO,
  SUBSCRIPTION_ELEMENTS_OPTS,
  isBundle,
} from "../../composables/subscription";
import { useRouter, useRoute } from "vue-router";
import { ROUTES } from "../../routes/names";

const props = defineProps(['mapComponent'])
const router = useRouter();
const route = useRoute();
const { t } = useI18n();

const card = ref(null);
const width = ref(null);
const showMenu = ref(undefined);
const selectedElement = computed(() => {
  if (route.params.id && route.query.element) {
    LAST_SELECTED_ELEMENT_TO_VIEW.value = route.query.element;
    const element = SUBSCRIPTION_INFO.elements.find(
      (nth) => nth.code === route.query.element
    );
    const bundle = SUBSCRIPTION_INFO.bundles.find(
      (nth) => nth.code === route.query.element
    );
    return element || bundle;
  } else {
    const element = SUBSCRIPTION_INFO.elements.find(
      (nth) => nth.code === LAST_SELECTED_ELEMENT_TO_VIEW.value
    );
    const bundle = SUBSCRIPTION_INFO.bundles.find(
      (nth) => nth.code === LAST_SELECTED_ELEMENT_TO_VIEW.value
    );
    return element || bundle;
  }
});

onMounted(() => {
  width.value = card.value?.offsetWidth || 200;
});

const backToMainMenu = () => {
  LAST_SELECTED_ELEMENT_TO_VIEW.value = null;
};

const newElementSelected = () => {
  nextTick(() => {
    if (isBundle.value) showMenu.value = true;
    else showMenu.value = undefined;
    if (route.query.element) {
      router.push({ name: ROUTES.PROXIMA_PROFILES.name })
    }
  });
};

const getElementSymbol = (code) => {
  if (code === "Graphite") return t("ProximaList.elementCard.GraphiteCode");
  else if (code === "SALT") return t("ProximaList.elementCard.SaltCode");
  return code;
};
</script>
