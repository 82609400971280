<template>
  <div class="py-4 container-fluid pt-0">
    <!-- <ProfilesMenu class="sticky-top pt-3" /> -->
    <div class="row">
      <div class="col-12 mb-3">
        <div class="card">
          <div class="card-header pb-4">
            <h4 class="proxima-blue-text inline-text">
              <span @click="tableView = 'PROFILES'" class="cursor-pointer"
                :class="tableView === 'SUPPLY_CHAIN_AGREEMENTS' || tableView === 'COMPANY_PROFILES' || tableView === 'COUNTRY_PROFILES' ? 'text-muted' : ''">
                {{ $t("ProximaList.profilesTable.title") }}
              </span>
              <span v-if="supplyChainAgreementsData?.length">
                |
                <span @click="tableView = 'SUPPLY_CHAIN_AGREEMENTS'" class="cursor-pointer"
                  :class="tableView === 'PROFILES' || tableView === 'COMPANY_PROFILES' || tableView === 'COUNTRY_PROFILES' ? 'text-muted' : ''">
                  {{ $t("ProximaList.supplyChainAgreementsTable.title") }}
                </span>
              </span>
              <!--              <span>-->
              <!--                |-->
              <!--                <span @click="cleanup(), tableView = 'COMPANY_PROFILES';" class="cursor-pointer"-->
              <!--                  :class="tableView === 'PROFILES' || tableView === 'SUPPLY_CHAIN_AGREEMENTS' || tableView === 'COUNTRY_PROFILES' ? 'text-muted' : ''">-->
              <!--                  {{ $t("ProximaList.companyProfiles.title") }}-->
              <!--                </span>-->
              <!--              </span>-->
              <!--              <span>-->
              <!--                |-->
              <!--                <span @click="cleanup(), tableView = 'COUNTRY_PROFILES'" class="cursor-pointer"-->
              <!--                  :class="tableView === 'PROFILES' || tableView === 'SUPPLY_CHAIN_AGREEMENTS' || tableView === 'COMPANY_PROFILES' ? 'text-muted' : ''">-->
              <!--                  {{ $t("ProximaList.countryProfiles.title") }}-->
              <!--                </span>-->
              <!--              </span>-->
            </h4>
            <p v-if="!LAST_SELECTED_ELEMENT_TO_VIEW" class="text-sm text-muted">
              {{ $t("ProximaList.profilesTable.noElementSelected") }}
            </p>
            <p v-else-if="tableView === 'PROFILES'" class="text-sm text-muted">
              {{ $t("ProximaList.profilesTable.subTitle") }}
            </p>
            <p v-else-if="tableView === 'SUPPLY_CHAIN_AGREEMENTS'" class="text-sm text-muted">
              {{ $t("ProximaList.supplyChainAgreementsTable.subTitle") }}
            </p>
            <p v-else-if="tableView === 'COMPANY_PROFILES' && !PROFILE_ID" class="text-sm text-muted">
              {{ $t("ProximaList.profilesTable.noCompanySelected") }}
            </p>
            <p v-else-if="tableView === 'COUNTRY_PROFILES' && !COUNTRY_ID" class="text-sm text-muted">
              {{ $t("ProximaList.profilesTable.noCountrySelected") }}
            </p>
          </div>
          <div class="card-body px-0 pt-0" v-if="tableView === 'PROFILES'">
            <div class="row px-4">
              <div class="col-12 col-md-3 mb-3">
                <div class="col-12 mb-3">
                  <NSelect v-model:value="LAST_SELECTED_PACKAGE_TO_VIEW" :options="PARENT_SUBSCRIPTION_PACKAGES_OPTS"
                    filterable multiple clearable :placeholder="t('ProximaList.profilesTable.packagesPlaceholder')
                      " :loading="inProgress" :input-props="{ autocomplete: Math.random() }" />
                </div>
                <div class="col-12 mb-3">
                  <NSelect v-model:value="LAST_SELECTED_ELEMENT_TO_VIEW"
                    :options="sortBy(SUBSCRIPTION_ELEMENTS_OPTS, 'label')" filterable clearable :placeholder="t('ProximaList.profilesTable.elementsPlaceholder')
                      " :loading="inProgress" :input-props="{ autocomplete: Math.random() }" />
                </div>
                <div class="col-12 mb-3">
                  <NSelect v-model:value="COUNTRY_TO_VIEW" :options="COUNTRIES_OPTS" filterable clearable multiple
                    :placeholder="t('ProximaList.profilesTable.countriesPlaceholder')
                      " :loading="inProgress" :input-props="{ autocomplete: Math.random() }" />
                </div>
                <div class="col-12 mb-3">
                  <NSelect v-model:value="COMPANY_TO_VIEW" :options="COMPANIES_OPTS" filterable clearable multiple
                    :placeholder="t('ProximaList.profilesTable.companiesPlaceholder')
                      " :loading="inProgress" :input-props="{ autocomplete: Math.random() }" />
                </div>
                <div class="col-12 mb-3">
                  <NSelect v-model:value="ASSET_TO_VIEW" :options="ASSETS_OPTS" filterable clearable multiple
                    :placeholder="t('ProximaList.profilesTable.assetsPlaceholder')
                      " :loading="inProgress" :input-props="{ autocomplete: Math.random() }" />
                </div>
                <div class="col-12 mb-3">
                  <NSelect v-model:value="STATUS_TO_VIEW" :options="STAGES_OPTS" filterable clearable multiple
                    :placeholder="t('ProximaList.profilesTable.statusPlaceholder')
                      " :loading="inProgress" :input-props="{ autocomplete: Math.random() }" />
                </div>
                <div class="col-12 mb-3">
                  <NSelect v-model:value="TYPE_TO_VIEW" :options="TYPES_OPTS" filterable clearable multiple
                    :placeholder="t('ProximaList.profilesTable.typesPlaceholder')
                      " :loading="inProgress" :input-props="{ autocomplete: Math.random() }" />
                </div>
                <div class="col-12 mb-3">
                  <NSelect v-model:value="PRODUCT_GROUP_TO_VIEW" :options="PRODUCT_GROUP_OPTS" filterable clearable
                    multiple :placeholder="t('ProximaList.profilesTable.productGroupPlaceholder')
                      " :loading="inProgress" :input-props="{ autocomplete: Math.random() }" />
                </div>
                <div class="col-12 mb-3">
                  <NSelect v-model:value="PRODUCT_TO_VIEW" :options="PRODUCT_OPTS" filterable clearable multiple
                    :placeholder="t('ProximaList.profilesTable.productsPlaceholder')
                      " :loading="inProgress" :input-props="{ autocomplete: Math.random() }" />
                </div>
                <div class="col-12">
                  <div class="row">
                    <div class="col-4 mb-3">
                      <button class="btn btn-sm proxima-blue-bg-gradient bg-gradient-success text-white w-100 px-0"
                        :disabled="inProgress" @click="getProfiles">
                        {{ $t("ProximaList.profilesTable.search") }}
                      </button>
                    </div>
                    <div class="col-4 mb-3">
                      <button class="btn btn-sm proxima-blue-bg-gradient bg-gradient-success text-white w-100 px-0"
                        :disabled="inProgress" @click="resetFilters">
                        {{ $t("ProximaList.profilesTable.clear") }}
                      </button>
                    </div>
                    <div class="col-4 mb-3">
                      <button class="btn btn-sm proxima-blue-bg-gradient bg-gradient-success text-white w-100 px-0"
                        :disabled="inProgress" @click="saveToPNG">
                        {{ $t("ProximaList.profilesTable.saveToPNG") }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-9 mb-3">
                <div style="height: 550px">
                  <div style="
                        width: 150px;
                        position: absolute;
                        margin: 10px;
                        z-index: 1;
                      ">
                    <button class="btn btn-sm proxima-blue-bg-gradient bg-gradient-success text-white px-0"
                      style="width: 120px; display: block" :disabled="inProgress" @click="
                        mapView = !mapView;
                      legendMenu = 'ALL';
                      ">
                      {{
                        mapView
                          ? $t("ProximaList.profilesTable.mapView")
                          : $t("ProximaList.profilesTable.globeView")
                      }}
                    </button>
                    <button class="btn btn-sm proxima-blue-bg-gradient bg-gradient-success text-white px-0"
                      style="width: 120px; display: block" :disabled="inProgress" @click="
                        grayScale = !grayScale;
                      legendMenu = 'ALL';
                      ">
                      {{ $t("ProximaList.profilesTable.toggleMapColour") }}
                    </button>
                    <button class="btn btn-sm proxima-blue-bg-gradient bg-gradient-success text-white px-0"
                      style="width: 120px; display: block" :disabled="inProgress" @click="scrollToTable">
                      Scroll to Table
                    </button>
                    <NDropdown trigger="click" style="width: 200px" :options="LEGEND_OPTS" @select="handleSelectLegend">
                      <button class="btn btn-sm proxima-blue-bg-gradient bg-gradient-success text-white px-0"
                        style="width: 120px; display: block" :disabled="inProgress">
                        {{
                          legendMenu
                            ? legendMenu === "ALL"
                              ? "All/Type/Status"
                              : $t(
                                "ProximaList.profilesTable.legendOptsPlaceholder",
                                {
                                  legend:
                                    legendMenu
                                      .toLowerCase()
                                      .charAt(0)
                                      .toUpperCase() +
                                    legendMenu.toLowerCase().slice(1),
                                }
                              )
                            : "All/Type/Status"
                        }}
                      </button>
                    </NDropdown>
                    <div class="row">
                      <div class="col-12">
                        <SelectElement :mapComponent="true" />
                      </div>
                      <div class="col-12 pt-3">
                        <SummaryCard :title="$t('ProximaList.assetCard.title')"
                          :profiles="PUBLISHED_PROFILES?.assetProfiles || []" :count="PUBLISHED_PROFILES?.count"
                          :error="$t('ProximaList.assetCard.notFound')" :hideDropdown="true" :mapComponent="true"
                          :profileComponent="false">
                          <div class="text-center shadow icon icon-shape bg-gradient-primary rounded-circle">
                            <i class="text-lg opacity-10 ni ni-money-coins"></i>
                          </div>
                        </SummaryCard>
                      </div>
                      <div class="col-12 pt-3">
                        <div class="card">
                          <div class="p-2 card-body">
                            <div class="block font-weight-bold text-sm">
                              Legend
                            </div>

                            <div class="block" v-if="legendMenu === 'ALL'">
                              <div class="mapbox-legend mapbox-legend--white"></div>
                              <span style="cursor: pointer" class="text-sm" @click="
                                updateMapMarkers();
                              legendMenu = 'ALL';
                              ">
                                All</span>
                            </div>

                            <div class="block" v-if="legendMenu === 'TYPE'">
                              <div class="mapbox-legend mapbox-legend--red"></div>
                              <span style="cursor: pointer" class="text-sm" @click="showMineAndBeneficiation()">Mine &
                                Beneficiation</span>
                            </div>
                            <div class="block" v-if="legendMenu === 'TYPE'">
                              <div class="mapbox-legend mapbox-legend--blue"></div>
                              <span style="cursor: pointer" class="text-sm" @click="showRefineryAndFactory()">Refinery &
                                Factory</span>
                            </div>

                            <div class="block" v-if="legendMenu === 'STATUS'">
                              <div class="mapbox-legend mapbox-legend--red"></div>
                              <span class="text-sm" style="cursor: pointer" @click="showClosed()">Closed/C&M</span>
                            </div>
                            <div class="block" v-if="legendMenu === 'STATUS'">
                              <div class="mapbox-legend mapbox-legend--yellow"></div>
                              <span class="text-sm" style="cursor: pointer" @click="showProject()">Project</span>
                            </div>
                            <div class="block" v-if="legendMenu === 'STATUS'">
                              <div class="mapbox-legend mapbox-legend--green"></div>
                              <span class="text-sm" style="cursor: pointer" @click="showOperating()">Operating</span>
                            </div>
                            <div class="block mt-1">
                              <span style="font-style: italic; font-size: small">Powered by Proxima</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div style="
                        height: 100%;
                        width: 100%;
                        position: relative;
                        overflow: hidden;
                      " id="drawer-target">
                    <div style="height: 100%; width: 100%" v-if="mapView">
                      <iframe id="interactiveMap1" :src="grayScale
                        ? envs('GRAY_FLAT_INTERACTIVE_MAP') + '?version=230'
                        : envs('FLAT_INTERACTIVE_MAP') + '?version=230'
                        " frameborder="0" style="width: 100%; height: 100%"></iframe>
                    </div>
                    <div style="height: 100%; width: 100%" v-else>
                      <iframe id="interactiveMap2" :src="grayScale
                        ? envs('GRAY_GLOBE_INTERACTIVE_MAP') +
                        '?version=230'
                        : envs('GLOBE_INTERACTIVE_MAP') + '?version=230'
                        " frameborder="0" style="width: 100%; height: 100%"></iframe>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <NDataTable id="info-table" :columns="columns" :data="data" :max-height="'700px'" :loading="inProgress" />
          </div>
          <div class="card-body px-0 pt-0" v-else-if="tableView === 'SUPPLY_CHAIN_AGREEMENTS'">
            <div class="p-2 mb-3">
              <div class="row">
                <div class="col-4">
                  <NSelect v-model:value="agreementTypesFilter" :options="agreementTypeOpts" filterable clearable
                    multiple placeholder="Agreement Type" :loading="inProgress"
                    :input-props="{ autocomplete: Math.random() }" />
                </div>
                <div class="col-4">
                  <NSelect v-model:value="companyFilter" :options="companyOpts" filterable clearable multiple
                    placeholder="Company" :loading="inProgress" :input-props="{ autocomplete: Math.random() }" />
                </div>
                <div class="col-4">
                  <NSelect v-model:value="productFilter" :options="productOpts" filterable clearable multiple
                    placeholder="Product" :loading="inProgress" :input-props="{ autocomplete: Math.random() }" />
                </div>
              </div>

            </div>

            <NDataTable :columns="supplyChainAgreementsColumns" :data="supplyChainAgreementsData" :row-props="rowProps"
              :max-height="'700px'" :loading="inProgress" />
          </div>

          <div class="card-body px-0 pt-0" v-else-if="tableView === 'COMPANY_PROFILES'">
            <CompanyProfile :companyProfileData="companyProfileData" />
          </div>

          <div class="card-body px-0 pt-0" v-else-if="tableView === 'COUNTRY_PROFILES'">
            <CountryProfile :countryProfileData="countryProfileData" @showCompanyProfile="showCompanyProfile" />
          </div>

        </div>
      </div>
    </div>
    <NDrawer v-model:show="showSupplyChainAgreementInfo" :width="400" placement="right">
      <NDrawerContent>
        <h6 class="text-capitalize">
          {{ mountedSupplyChainAgreement?.full?.title }}
        </h6>

        <br />
        <div v-if="mountedSupplyChainAgreement?.full?.type">
          Type:&nbsp;{{ mountedSupplyChainAgreement?.full?.type }}
        </div>
        <div v-if="mountedSupplyChainAgreement?.full?.product">
          Product:&nbsp;{{ mountedSupplyChainAgreement?.full?.product }}
        </div>
        <div v-if="capacity">Volumes:&nbsp; {{ capacity }}</div>
        <div v-if="mountedSupplyChainAgreement?.full?.announcedDate">
          Date Announced:&nbsp;{{
            mountedSupplyChainAgreement?.full?.announcedDate
          }}
        </div>
        <div v-if="mountedSupplyChainAgreement?.full?.announcedStartDate">
          Start Date:&nbsp;
          {{ mountedSupplyChainAgreement?.full?.announcedStartDate }}
        </div>
        <div v-if="mountedSupplyChainAgreement?.full?.endDate">
          End Date: &nbsp;{{ mountedSupplyChainAgreement?.full?.endDate }}
        </div>

        <br v-if="suppliers.length" />
        <h6 v-if="suppliers.length">Suppliers</h6>
        <div style="display: block; width: 100%" v-for="nth in suppliers" :key="nth">
          <div>Company:&nbsp;{{ nth?.companyName }}</div>
          <div>
            Asset:&nbsp;
            <span style="cursor: pointer" @click="goToAssetProfile(nth)">{{
              nth?.asset
            }}</span>
          </div>
        </div>

        <br v-if="receivers.length" />
        <h6 v-if="receivers.length">Receivers</h6>
        <div style="display: block; width: 100%" v-for="nth in receivers" :key="nth">
          <div>Company:&nbsp;{{ nth?.companyName }}</div>
          <div>
            Asset:&nbsp;
            <span style="cursor: pointer" @click="goToAssetProfile(nth)">{{
              nth?.asset
            }}</span>
          </div>
        </div>

        <br />
        <h6>Deal Overview</h6>
        <p>
          {{ mountedSupplyChainAgreement?.full?.dealProfile }}
        </p>
      </NDrawerContent>
    </NDrawer>

    <NDrawer v-model:show="showMarkerInfo" :width="400" :height="200" placement="right">
      <NDrawerContent>
        <div class="row gap-y-2" v-if="PROFILE_TO_VIEW_IN_DRAWER.type === 'ASSET_PROFILE'">
          <div class="col-12">
            <button class="btn btn-xs proxima-blue-bg-gradient bg-gradient-success text-white px-2" style="float: right"
              :disabled="inProgress" @click="
                goToAssetProfile({ assetUUID: PROFILE_TO_VIEW_IN_DRAWER?.uuid })
                ">
              Go to profile
            </button>
          </div>
          <div class="col-12">
            <h5>{{ PROFILE_TO_VIEW_IN_DRAWER.title }}</h5>
            <br />
          </div>
          <div class="col-12" v-if="PROFILE_TO_VIEW_IN_DRAWER.profile">
            <h6>Profile</h6>
            <p>{{ PROFILE_TO_VIEW_IN_DRAWER.profile }}</p>
          </div>
          <div class="col-12" v-if="PROFILE_TO_VIEW_IN_DRAWER.project">
            <h6>Project</h6>
            <p>{{ PROFILE_TO_VIEW_IN_DRAWER.project }}</p>
          </div>
        </div>
        <div class="row gap-y-2" v-else-if="PROFILE_TO_VIEW_IN_DRAWER.type === 'COMPANY_PROFILE'">
          <div class="col-12">
            <button class="btn btn-xs proxima-blue-bg-gradient bg-gradient-success text-white px-2" style="float: right"
              :disabled="inProgress" @click="goToCompanyProfile(PROFILE_TO_VIEW_IN_DRAWER?.uuid)">
              Go to profile
            </button>
          </div>
          <div class="col-12">
            <h5>{{ PROFILE_TO_VIEW_IN_DRAWER.title }}</h5>
            <br />
          </div>
          <div class="col-12" v-if="PROFILE_TO_VIEW_IN_DRAWER.overview">
            <h6>Overview</h6>
            <p>{{ PROFILE_TO_VIEW_IN_DRAWER.overview }}</p>
          </div>
          <div class="col-12" v-if="PROFILE_TO_VIEW_IN_DRAWER.profile">
            <h6>Profile</h6>
            <p>{{ PROFILE_TO_VIEW_IN_DRAWER.profile }}</p>
          </div>
        </div>
      </NDrawerContent>
    </NDrawer>
  </div>
</template>

<script>
export default {
  name: "ProximaProfiles",
};
</script>

<script setup>
import { computed, h, nextTick, onMounted, ref, watch } from "vue";
import {
  NDataTable,
  NSelect,
  NDrawer,
  NDrawerContent,
  NDropdown,
} from "naive-ui";
import { useI18n } from "vue-i18n";
import { useRouter, useRoute } from "vue-router";
import { ROUTES } from "../../routes/names";
import { LAST_SELECTED_ELEMENT_TO_VIEW, LAST_SELECTED_PACKAGE_TO_VIEW, COUNTRY_TO_VIEW, COMPANY_TO_VIEW, ASSET_TO_VIEW, STATUS_TO_VIEW, TYPE_TO_VIEW, PRODUCT_GROUP_TO_VIEW, PRODUCT_TO_VIEW } from "../../composables/generics";
import {
  PROFILES_FILTERS,
  PUBLISHED_PROFILES,
  PUBLISHED_ALL_PROFILES,
  getPublishedProfiles,
  getAllPublishedProfiles,
  inProgress,
  ASSETS_OPTS,
  COUNTRIES_OPTS,
  COMPANIES_OPTS,
  TYPES_OPTS,
  STAGES_OPTS,
  PRODUCT_OPTS
} from "../../composables/profiles/list";
import {
  MOUNTED_ASSET_PROFILES,
  mountProfile as _mountAssetProfile,
  getAssetProfiles,
  PROFILE_ID,
  ASSET_STATUS_OPTS
} from "../../composables/profiles/asset";
import {
  MOUNTED_COMPANY_PROFILE,
  mountProfile as _mountCompanyProfile,
  getCompanies,
  getCompanyProfiles,
  ALL_COMPANIES_OPTS,
  getCompanyProfile,
} from "../../composables/profiles/company";
import {
  mountProfile as _mountCountryProfile,
  getCountryProfiles,
  COUNTRY_COMMODITIES,
  MOUNTED_COUNTRY_PROFILES,
  getCountryCommodities,
  COUNTRY_ID
} from "../../composables/profiles/country";
import { PRODUCT_GROUP_OPTS, MARKET_SERVICES_OPTS } from "../../utils/constants";
import TableHeader from "../../components/ProfileTable/TableHeader";
import TableCell from "../../components/ProfileTable/TableCell";
import TableCellType from "../../components/ProfileTable/TableCellType";
import TableCellSignificance from "../../components/ProfileTable/TableCellSignificance";
import TableCellStatus from "../../components/ProfileTable/TableCellStatus";
import TableCellProducts from "../../components/ProfileTable/TableCellProducts";
import CompanyProfile from "../../components/Profiles/CompanyProfile.vue"
import CountryProfile from "../../components/Profiles/CountryProfile.vue"
import ProfilesMenu from "../../components/ProfilesMenu.vue";
import { envs, log } from "../../utils";
import SummaryCard from "../../components/dumb/SummaryCard.vue";
import SelectElement from "./SelectElement.vue";
import { SUBSCRIPTION_INFO } from "../../composables/subscription";
import { sortBy, uniqBy } from "lodash";
import {
  SUBSCRIPTION_ELEMENTS_OPTS, SUBSCRIPTION_PACKAGES_OPTS, PARENT_SUBSCRIPTION_PACKAGES_OPTS
} from "../../composables/subscription";
import LatestNews from '../../components/LatestNews.vue'
let legendMenu = ref("ALL");
const selectedLegendOpts = ref("ALL");

const LEGEND_OPTS = [
  {
    label: "All",
    key: "ALL",
  },
  {
    label: "Type",
    key: "TYPE",
  },
  {
    label: "Status",
    key: "STATUS",
  },
];

const handleSelectLegend = (key) => {
  legendMenu.value = key;
  if (legendMenu.value === "TYPE") {
    updateMapMarkers("TYPE", [
      "Mine",
      "Recycler",
      "Recycling",
      "Tailings",
      "Tailings/Slag",
      "Factory",
      "Refinery",
    ]);
  } else if (legendMenu.value === "STATUS") {
    updateMapMarkers("STATUS", [
      "Closed",
      "Idle",
      "Unknown",
      "Project",
      "Operating",
    ]);
  } else {
    updateMapMarkers();
  }
};

const { t } = useI18n();
const router = useRouter();
const route = useRoute();
const mapView = ref(false);
const tableView = ref("PROFILES"); // TODO: needs improvements -> ex: PROFILES \ SUPPLY_CHAIN_AGREEMENTS
const showSupplyChainAgreementInfo = ref(false);
const showMarkerInfo = ref(false);
const PROFILE_TO_VIEW_IN_DRAWER = ref();
const grayScale = ref(true);
// const selectRef = ref(null);
const COMMODITY_ID = ref(null);
// const STATUS_ID = ref(null);
const PROFILE_TO_VIEW_DETAILS = ref(null);
const COMMODITY_OVERVIEW_DETAILS = ref(null);
let byProductCommodities = ref([]);
const agreementTypesFilter = ref([]);
const companyFilter = ref([]);
const productFilter = ref([]);
const companyProfileData = ref(null);
const countryProfileData = ref(null);


// let primaryCommodities = ref([]);
// const isRed = (index) => index >= byProductCommodities.length;

const columns = [
  {
    key: "country",
    defaultSortOrder: "ascend",
    sorter: "default",
    title(column) {
      return h(TableHeader, {
        text: t("ProximaList.profilesTable.countryHeader"),
      });
    },
    render: (row) => {
      return h(TableCell, {
        text: row.country,
        hasProfile: row.hasCountryProfile,
        profile: row.fullCountryProfile,
        onMountProfile: mountCountryProfile,
      });
    },
  },
  {
    key: "company",
    sorter: "default",
    title(column) {
      return h(TableHeader, {
        text: t("ProximaList.profilesTable.companyHeader"),
      });
    },
    render: (row) => {
      return h(TableCell, {
        text: row.company,
        hasProfile: row.hasCompanyProfile,
        profile: row.fullCompanyProfile,
        onMountProfile: mountCompanyProfile,
      });
    },
  },
  {
    key: "asset",
    sorter: "default",
    title(column) {
      return h(TableHeader, {
        text: t("ProximaList.profilesTable.assetHeader"),
      });
    },
    render: (row) => {
      return h(TableCell, {
        text: row.asset,
        hasProfile: true,
        profile: row.fullAssetProfile,
        onMountProfile: mountAssetProfile,
      });
    },
  },
  {
    key: "significance",
    title(column) {
      return h(TableHeader, {
        text: t("ProximaList.profilesTable.significanceHeader"),
      });
    },
    render: (row) => {
      return h(TableCellSignificance, {
        text: row.significance,
      });
    },
  },
  {
    key: "status",
    title(column) {
      return h(TableHeader, {
        text: t("ProximaList.profilesTable.statusHeader"),
      });
    },
    render: (row) => {
      return h(TableCellStatus, {
        text: row.status,
      });
    },
  },
  {
    key: "type",
    title(column) {
      return h(TableHeader, {
        text: t("ProximaList.profilesTable.typeHeader"),
      });
    },
    render: (row) => {
      return h(TableCellType, {
        text: row.type,
      });
    },
  },
  {
    key: "products",
    title(column) {
      return h(TableHeader, {
        text: t("ProximaList.profilesTable.productsHeader"),
      });
    },
    render: (row) => {
      return h(TableCellProducts, {
        texts: (row.products || []).map((nth) => formatProducts(nth)),
      });
    },
  },
];

// const elementOverviewCol = [{
//   key: "",
//   title(column) {
//     return h(TableHeader, {
//       text: "",
//     });
//   },
//   render: (row) => {
//     return h(TableCell, {});
//   },
// },
// {
//   key: "2018",
//   sorter: "default",
//   title(column) {
//     return h(TableHeader, {
//       text: 2018,
//     });
//   },
//   render: (row) => {
//     return h(TableCell, {});
//   },
// },
// {
//   key: "2019",
//   sorter: "default",
//   title(column) {
//     return h(TableHeader, {
//       text: 2019,
//     });
//   },
//   render: (row) => {
//     return h(TableCell, {});
//   },
// },
// {
//   key: "2020",
//   sorter: "default",
//   title(column) {
//     return h(TableHeader, {
//       text: 2020,
//     });
//   },
//   render: (row) => {
//     return h(TableCell, {});
//   },
// },
// {
//   key: "2021",
//   sorter: "default",
//   title(column) {
//     return h(TableHeader, {
//       text: 2021,
//     });
//   },
//   render: (row) => {
//     return h(TableCell, {});
//   },
// },
// {
//   key: "2022",
//   sorter: "default",
//   title(column) {
//     return h(TableHeader, {
//       text: 2022,
//     });
//   },
//   render: (row) => {
//     return h(TableCell, {});
//   },
// },
// {
//   key: "2023e",
//   sorter: "default",
//   title(column) {
//     return h(TableHeader, {
//       text: "2023e",
//     });
//   },
//   render: (row) => {
//     return h(TableCell, {});
//   },
// },];
// const elementOverviewData = ref([]);

// const patDataCol = [{
//   key: "",
//   title(column) {
//     return h(TableHeader, {
//       text: "",
//     });
//   },
//   render: (row) => {
//     return h(TableCell, {});
//   },
// },
// {
//   key: "2018",
//   sorter: "default",
//   title(column) {
//     return h(TableHeader, {
//       text: 2018,
//     });
//   },
//   render: (row) => {
//     return h(TableCell, {});
//   },
// },
// {
//   key: "2019",
//   sorter: "default",
//   title(column) {
//     return h(TableHeader, {
//       text: 2019,
//     });
//   },
//   render: (row) => {
//     return h(TableCell, {});
//   },
// },
// {
//   key: "2020",
//   sorter: "default",
//   title(column) {
//     return h(TableHeader, {
//       text: 2020,
//     });
//   },
//   render: (row) => {
//     return h(TableCell, {});
//   },
// },
// {
//   key: "2021",
//   sorter: "default",
//   title(column) {
//     return h(TableHeader, {
//       text: 2021,
//     });
//   },
//   render: (row) => {
//     return h(TableCell, {});
//   },
// },
// {
//   key: "2022",
//   sorter: "default",
//   title(column) {
//     return h(TableHeader, {
//       text: 2022,
//     });
//   },
//   render: (row) => {
//     return h(TableCell, {});
//   },
// },
// {
//   key: "2023e",
//   sorter: "default",
//   title(column) {
//     return h(TableHeader, {
//       text: "2023e",
//     });
//   },
//   render: (row) => {
//     return h(TableCell, {});
//   },
// },];
// const patData = ref([]);

const data = computed(() => {
  const countryMap = {};
  for (const nth of PUBLISHED_PROFILES.value?.countryProfiles || []) {
    countryMap[nth.countryCode] = nth;
  }

  const companyMap = {};
  const associatedCompanyMap = {};
  for (const nth of PUBLISHED_PROFILES.value?.companyProfiles || []) {
    companyMap[nth.companyId] = nth;
    for (const ith of nth.associatedCompanies || []) {
      associatedCompanyMap[ith] = nth;
    }
  }

  return (PUBLISHED_PROFILES.value?.assetProfiles || []).map((profile) => {
    const hasCompanyProfile =
      profile.companyId in companyMap ||
      profile.companyId in associatedCompanyMap;
    const companyProfile =
      companyMap?.[profile.companyId] ||
      associatedCompanyMap?.[profile.companyId];
    const countryProfile = countryMap?.[profile.countryCode];
    return {
      uuid: profile.uuid,
      hasCountryProfile: profile.countryCode in countryMap,
      countryProfile: countryProfile?.uuid,
      country: profile.countryName,
      hasCompanyProfile,
      companyProfile: companyProfile?.uuid,
      company: profile.companyName,
      significance: profile.significance,
      asset: profile.short_Name,
      type: profile.type,
      status: profile.status,
      products: profile.products,
      fullAssetProfile: profile,
      fullCompanyProfile: companyProfile,
      fullCountryProfile: countryProfile,
    };
  });
});

const mountedSupplyChainAgreement = ref(null);
const supplyChainAgreementsColumns = [
  {
    key: "dateAnnounced",
    sorter: 'default',
    title(column) {
      return h(TableHeader, {
        text: t("ProximaList.supplyChainAgreementsTable.dateAnnouncedHeader"),
      });
    },
    render: (row) => {
      return row.dateAnnounced;
    },
  },
  {
    key: "agreementType",
    sorter: 'default',
    title(column) {
      return h(TableHeader, {
        text: t("ProximaList.supplyChainAgreementsTable.agreementTypeHeader"),
      });
    },
    render: (row) => {
      return row.agreementType;
    },
  },
  {
    key: "supplyCompany",
    sorter: 'default',
    title(column) {
      return h(TableHeader, {
        text: t("ProximaList.supplyChainAgreementsTable.supplyCompanyHeader"),
      });
    },
    render: (row) => {
      return row.supplyCompany;
    },
  },
  {
    key: "partnerCompany",
    sorter: 'default',
    title(column) {
      return h(TableHeader, {
        text: t("ProximaList.supplyChainAgreementsTable.partnerCompanyHeader"),
      });
    },
    render: (row) => {
      return row.partnerCompany;
    },
  },
  {
    key: "products",
    sorter: 'default',
    title(column) {
      return h(TableHeader, {
        text: t("ProximaList.supplyChainAgreementsTable.productsHeader"),
      });
    },
    render: (row) => {
      return row.products;
    },
  },
  {
    key: "volumes",
    sorter: 'default',
    title(column) {
      return h(TableHeader, {
        text: t("ProximaList.supplyChainAgreementsTable.volumesHeader"),
      });
    },
    render: (row) => {
      return row.volumes;
    },
  },
  {
    key: "startDate",
    sorter: 'default',
    title(column) {
      return h(TableHeader, {
        text: t("ProximaList.supplyChainAgreementsTable.startDateHeader"),
      });
    },
    render: (row) => {
      return row.startDate;
    },
  },
  {
    key: "endDate",
    sorter: 'default',
    title(column) {
      return h(TableHeader, {
        text: t("ProximaList.supplyChainAgreementsTable.endDateHeader"),
      });
    },
    render: (row) => {
      return row.endDate;
    },
  },
];

const mappedSupplyChainAgreementsData = computed(() => {
  return (PUBLISHED_PROFILES.value?.dealAndContractProfiles || []).map(
    (profile) => {
      return {
        uuid: profile.uuid,
        dateAnnounced: profile.announcedDate,
        agreementType: profile.type,
        supplyCompany: (profile.suppliersAndReceivers || [])
          .filter((nth) => nth.partyType === "SUPPLIER")
          .map((nth) => nth.companyName)
          .join(", "),
        partnerCompany: (profile.suppliersAndReceivers || [])
          .filter((nth) => nth.partyType === "RECEIVER")
          .map((nth) => nth.companyName)
          .join(", "),
        products: profile.product,
        volumes:
          profile.unit && profile.capacity
            ? `${profile.capacity} ${profile.unit}`
            : "",
        startDate: profile.announcedStartDate || profile.actualStartDate,
        endDate: profile.endDate,
        full: profile,
      };
    }
  )
})

const supplyChainAgreementsData = computed(() => {

  if (agreementTypesFilter.value.length || companyFilter.value.length || productFilter.value.length) {
    return mappedSupplyChainAgreementsData.value.filter(r =>
      (agreementTypesFilter.value.length === 0 || agreementTypesFilter.value.includes(r.agreementType)) &&
      (companyFilter.value.length === 0 || companyFilter.value.includes(r.supplyCompany) || companyFilter.value.includes(r.partnerCompany)) &&
      (productFilter.value.length === 0 || productFilter.value.includes(r.products))
    );
  } else {
    return mappedSupplyChainAgreementsData.value;
  }
});

const suppliers = computed(() => {
  return (
    mountedSupplyChainAgreement.value?.full?.suppliersAndReceivers || []
  ).filter((nth) => nth.partyType === "SUPPLIER");
});
const receivers = computed(() => {
  return (
    mountedSupplyChainAgreement.value?.full?.suppliersAndReceivers || []
  ).filter((nth) => nth.partyType === "RECEIVER");
});
const capacity = computed(() => {
  return mountedSupplyChainAgreement.value?.full.capacity &&
    mountedSupplyChainAgreement.value?.full.unit
    ? `${mountedSupplyChainAgreement.value?.full.capacity} ${mountedSupplyChainAgreement.value?.full.unit}`
    : "";
});

const rowProps = (row) => {
  return {
    style: "cursor: pointer;",
    onClick: () => {
      showSupplyChainAgreementInfo.value = true;
      mountedSupplyChainAgreement.value = row;
    },
  };
};

const mountAssetProfile = (profile) => {
  _mountAssetProfile(router, {
    profile,
    element: LAST_SELECTED_ELEMENT_TO_VIEW.value,
  });
};

const mountCountryProfile = (profile) => {
  // _mountCountryProfile(router, {
  //   profile,
  //   element: LAST_SELECTED_ELEMENT_TO_VIEW.value,
  // });

  tableView.value = 'COUNTRY_PROFILES';
  if (LAST_SELECTED_ELEMENT_TO_VIEW.value) {
    profile.selectedElement = LAST_SELECTED_ELEMENT_TO_VIEW.value
  }
  countryProfileData.value = profile;

};

const mountCompanyProfile = async (profile) => {
  // _mountCompanyProfile(router, {
  //   profile,
  //   element: LAST_SELECTED_ELEMENT_TO_VIEW.value,
  // });

  let data = {
    uuid: profile.uuid,
    selectedElement: LAST_SELECTED_ELEMENT_TO_VIEW.value
  }
  await showCompanyProfile(data)

};

const COMPANY_OPTS = computed(() => {
  return ALL_COMPANIES_OPTS.value || []
});

// const companyName = computed(() => {
//   return COMPANY_OPTS.value.filter(r => r.value === PROFILE_ID.value).map(r => r.label)[0]
// })

// const companyProfile = computed(() => {
//   return MOUNTED_COMPANY_PROFILE.value || {}
// })

const assetProfiles = computed(() => {
  return PUBLISHED_PROFILES.value?.assetProfiles || [];
})

const countryProfiles = computed(() => {
  return MOUNTED_COUNTRY_PROFILES.value?.profiles || [];

})

const isSubscribedToAssetProfile = (nth) => {
  const element = LAST_SELECTED_ELEMENT_TO_VIEW.value;
  const allElements = [
    ...new Set([
      ...nth.byProductCommodities || [],
      ...nth.nonCommercialCommodities || [],
      ...nth.primaryCommodities || [],
    ]),
  ];
  return (
    allElements.includes(element) &&
    SUBSCRIPTION_INFO.elements?.map((nth) => nth.code).includes(element)
  );
};

const goToList = () => {
  router.push({ name: ROUTES.PROXIMA_PROFILES.name });
};

const getProfiles = async () => {
  await getPublishedProfiles(LAST_SELECTED_ELEMENT_TO_VIEW.value);
};

const resetFilters = async () => {
  ASSET_TO_VIEW.value = null;
  COMPANY_TO_VIEW.value = null;
  COUNTRY_TO_VIEW.value = null;
  STATUS_TO_VIEW.value = null;
  TYPE_TO_VIEW.value = null;
  PRODUCT_GROUP_TO_VIEW.value = null;
  PRODUCT_TO_VIEW.value = null;
  setTimeout(() => {
    nextTick(() => {
      const maps = [
        document.getElementById("interactiveMap1"),
        document.getElementById("interactiveMap2"),
      ];
      for (const map of maps) {
        if (map) {
          map.src = map.src.split("&")?.[0] + "&_=" + Math.random();
        }
      }
    });
  }, 500);
  await getPublishedProfiles(LAST_SELECTED_ELEMENT_TO_VIEW.value);
};

watch(PUBLISHED_PROFILES, () => {
  legendMenu.value = "ALL";
  updateMapMarkers();
});


// watch(PROFILE_ID, async () => {
//   if (!PROFILE_ID.value) {
//     PROFILE_TO_VIEW_DETAILS.value = null
//   } else {
//     COMMODITY_ID.value = null
//     COMMODITY_OVERVIEW_DETAILS.value = null
//     // await getCompanyProfiles({ id: PROFILE_ID.value, country: null, commodity: null, keyword: null })
//   }
// });

// watch(COUNTRY_ID, async () => {
//   if (!COUNTRY_ID.value) {
//     PROFILE_TO_VIEW_DETAILS.value = null
//   } else {
//     COMMODITY_ID.value = null
//     COMMODITY_OVERVIEW_DETAILS.value = null
//     await getCountryProfiles(COUNTRY_ID.value)
//   }
// });

const updateMapMarkers = (type_or_status, groups) => {
  let marks = [];
  if (PUBLISHED_PROFILES.value?.assetProfiles) {
    for (let nth of PUBLISHED_PROFILES.value.assetProfiles || []) {
      let group = null;
      if (type_or_status === "TYPE") group = nth.type;
      else if (type_or_status === "STATUS") group = nth.status;
      marks.push({
        selectedElement: LAST_SELECTED_ELEMENT_TO_VIEW.value,
        assetCount: PUBLISHED_PROFILES.value?.count,
        type: "Feature",
        properties: {
          description: formatAssetProfilePopup(nth),
          type: nth.type,
          country: nth.countryName,
          group: group,
        },
        geometry: {
          type: "Point",
          coordinates:
            nth.coordinates
              ?.split(",")
              .map((ith) => parseFloat(ith.trim()))
              .reverse() || [],
        },
      });
    }
  }
  if (PUBLISHED_PROFILES.value?.companyProfiles) {
    // TODO
  }
  if (PUBLISHED_PROFILES.value?.countryProfiles) {
    // TODO
  }

  if (type_or_status)
    marks = marks.filter((nth) => groups.includes(nth.properties.group));

  setTimeout(() => {
    nextTick(() => {
      const maps = [
        document.getElementById("interactiveMap1"),
        document.getElementById("interactiveMap2"),
      ];
      for (const map of maps) {
        if (map) {
          const dummyMarks = JSON.parse(JSON.stringify(marks))
          map.contentWindow.postMessage(
            {
              reset: false,
              marks: dummyMarks,
            },
            "*"
          );
          //map.contentWindow.console = console;
        }
      }
    });
  }, 1000);
};

const formatProducts = (product) => {
  let grossContained = product?.grossContained;
  const units = [product.capacity, product.unit].filter((nth) => nth !== null);
  return `${product.name || ""} ${units.length ? ` - ${units.join(" ")}` : ""
    } ${grossContained?.toLowerCase() === "gross weight" ? "" : grossContained || ""
    }`;
};

const formatStatusColor = (status) => {
  if (status === "Project") return "#E1B16A";
  else if (status === "Operating") return "#1C9099";
  else if (status === "Idle") return "#D83615";
  else if (status === "Closed") return "#D83615";
  else if (status === "Unknown") return "#BFBFBF";
  return "none";
};

const formatAssetProfilePopup = (nth) => {
  return `
      <div style="width: 100%">
        <div
          style="
            display: flex;
            padding: 10px;
            justify-content: space-between;
            align-items: center;
          "
        >
          <a href="javascript:window.parent.postMessage({ type: 'ASSET_PROFILE', uuid: '${nth.uuid
    }'}, '*');" style="text-decoration: none; font-size: 15px;cursor: pointer; color: #192E40;outline: none !important;" onmouseover="this.style.color='#f1ae31'"
   onmouseout="this.style.color='#192E40'" class="profile-link" id="asset-profile-${nth.uuid
    }">${nth.name || nth.alias}</a>
          <div
            style="
              font-size: 9px;
              background-color: ${formatStatusColor(nth.status)};
              color: white;
              border-radius: 5px;
              padding: 3px 6px;
            "
          >
            ${nth.status || ""}
          </div>
        </div>
        <div
          style="
            background-color: #f2f2f2;
            font-size: 12px;
            padding: 10px;
          "
        >
          <div style="display: block; width: 100%">
            ${nth.owners.length ? "<div><b>• Ownership</b></div>" : ""}
            ${nth.owners
      .map(
        (kth) =>
          `<a href="javascript:window.parent.postMessage({ type: 'COMPANY_PROFILE', id: ${kth.id
          }, asset_uuid: '${nth.uuid
          }'}, '*');" style="text-decoration: none;padding-left: 8px;color: #67748e; cursor: ${companyHasProfile(kth.id) ? "pointer" : "default"
          };" class="profile-link" id="company-profile-${kth.id}">${kth.companyName || ""
          } (${kth.percentage || ""}%)</a>`
      )
      .join("")}
          </div>
          <div style="display: block; width: 100%">
            <div><b>• Markets</b></div>
            <div style="padding-left: 8px;color: #67748e;">
              Primary:
              ${(nth.primaryCommodities || []).join(", ")}
            </div>
          </div>
          <div style="display: block; width: 100%">
            ${nth.products.length ? "<div><b>• Products</b></div>" : ""}
            ${nth.products
      .map(
        (kth) =>
          `
                <div style="display: block; padding-left: 8px;color: #67748e;">
                  ${kth.name || ""} - ${kth.capacity || ""} ${kth.unit || ""} ${kth.status ? "(" + kth.status + ")" : ""
          }
                </div>
                `
      )
      .join("")}
          </div>
        </div>
      </div>
    `;
};

const goToAssetProfile = (nth) => {
  if (nth?.assetUUID) {
    router.push({
      name: ROUTES.ASSET_PROFILE.name,
      params: { id: nth?.assetUUID },
      query: { element: LAST_SELECTED_ELEMENT_TO_VIEW.value },
    });
  }
};

const goToCompanyProfile = (companyUUID) => {
  if (companyUUID) {
    router.push({
      name: ROUTES.COMPANY_PROFILE.name,
      params: { id: companyUUID },
      query: { element: LAST_SELECTED_ELEMENT_TO_VIEW.value },
    });
  }
};

const scrollToTable = () => {
  const el = document.getElementById("info-table");
  el.scrollIntoView();
};

// Note: instead of watching mapview -> listen to FRAME_LOADED signal from iframe instead
// watch(mapView, () => {
//   updateMapMarkers();
// });
window.addEventListener("message", (event) => {
  if (event.data?.type === "ASSET_PROFILE") {
    const assetProfile = (PUBLISHED_PROFILES.value?.assetProfiles || [])?.find(
      (nth) => nth.uuid === event.data.uuid
    );
    PROFILE_TO_VIEW_IN_DRAWER.value = {
      type: event.data.type,
      uuid: assetProfile.uuid,
      title: assetProfile.name || assetProfile.alias,
      profile: assetProfile.profile,
      project: assetProfile.projects,
    };
    showMarkerInfo.value = true;
  } else if (event.data?.type === "COMPANY_PROFILE") {
    const owner = (PUBLISHED_PROFILES.value?.assetProfiles || [])
      ?.find((nth) => nth.uuid === event.data.asset_uuid)
      ?.owners?.find((nth) => nth.id === event.data.id);
    const companyProfile = (
      PUBLISHED_PROFILES.value?.companyProfiles || []
    )?.find((nth) => event.data.id === nth.id);

    if (companyProfile) {
      PROFILE_TO_VIEW_IN_DRAWER.value = {
        type: event.data.type,
        uuid: companyProfile.uuid,
        title: companyProfile.companyName || companyProfile.alias,
        overview: companyProfile.companyOverview,
        profile: companyProfile.profileOverview,
      };
      showMarkerInfo.value = true;
    }
  } else if (event.data === "FRAME_LOADED") {
    updateMapMarkers();
  }
});

const companyHasProfile = (id) => {
  const companyProfile = (
    PUBLISHED_PROFILES.value?.companyProfiles || []
  )?.find((nth) => id === nth.id);
  return companyProfile ? true : false;
};

const cleanup = () => {
  if (!showMarkerInfo.value) {
    PROFILE_TO_VIEW_IN_DRAWER.value = null;
  }
  PROFILE_ID.value = null;
  COMMODITY_ID.value = null;
  COUNTRY_ID.value = null;
  PROFILE_TO_VIEW_DETAILS.value = null;

  if (route.query.profileType) {
    router.push({ path: route.path, query: { _: Math.random() } });
    COMMODITY_ID.value = null;
    COMMODITY_OVERVIEW_DETAILS.value = null;
  }

};

const saveToPNG = () => {
  setTimeout(() => {
    nextTick(() => {
      const maps = [
        document.getElementById("interactiveMap1"),
        document.getElementById("interactiveMap2"),
      ];
      for (const map of maps) {
        if (map) {
          map.contentWindow.postMessage("DOWNLOAD_MAP", "*");
          map.contentWindow.console = console;
        }
      }
    });
  }, 1000);
};

const showTypes = () => {
  legendMenu.value = "TYPE";
  updateMapMarkers("TYPE", [
    "Mine",
    "Recycler",
    "Recycling",
    "Tailings",
    "Tailings/Slag",
    "Factory",
    "Refinery",
  ]);
};

const showStatus = () => {
  legendMenu.value = "STATUS";
  updateMapMarkers("STATUS", [
    "Closed",
    "Idle",
    "Unknown",
    "Project",
    "Operating",
  ]);
};

const showMineAndBeneficiation = () => {
  updateMapMarkers("TYPE", [
    "Mine",
    "Recycler",
    "Recycling",
    "Tailings",
    "Tailings/Slag",
  ]);
};

const showRefineryAndFactory = () => {
  updateMapMarkers("TYPE", ["Factory", "Refinery"]);
};

const showClosed = () => {
  updateMapMarkers("STATUS", ["Closed", "Idle", "Unknown"]);
};

const showProject = () => {
  updateMapMarkers("STATUS", ["Project"]);
};

const showOperating = () => {
  updateMapMarkers("STATUS", ["Operating"]);
};

const showCompanyProfile = (data) => {
  tableView.value = 'COMPANY_PROFILES';
  companyProfileData.value = data;
};

// SUPPLY CHAIN AGREEMENTS FILTERS //
const agreementTypeOpts = computed(() => {
  let opts = mappedSupplyChainAgreementsData.value.map(r => {
    return {
      label: r.agreementType,
      value: r.agreementType
    }
  })
  const sorted = sortBy(opts, "label");
  return uniqBy(sorted, "label");
})

const companyOpts = computed(() => {
  let supplyCompanies = mappedSupplyChainAgreementsData.value.filter(r => r.supplyCompany != '').map(r => {
    return {
      label: r.supplyCompany,
      value: r.supplyCompany
    }
  })

  let partnerCompanies = mappedSupplyChainAgreementsData.value.filter(r => r.partnerCompany != '').map(r => {
    return {
      label: r.partnerCompany,
      value: r.partnerCompany
    }
  })

  let companies = supplyCompanies.concat(partnerCompanies)

  const sorted = sortBy(companies, "label");
  return uniqBy(sorted, "label");
})

const productOpts = computed(() => {
  let opts = mappedSupplyChainAgreementsData.value.map(r => {
    return {
      label: r.products,
      value: r.products
    }
  })
  const sorted = sortBy(opts, "label");
  return uniqBy(sorted, "label");
})

watch(SUBSCRIPTION_ELEMENTS_OPTS, () => {
  if (LAST_SELECTED_ELEMENT_TO_VIEW.value) {
    return;
  }
  console.log(SUBSCRIPTION_ELEMENTS_OPTS);
  LAST_SELECTED_ELEMENT_TO_VIEW.value = SUBSCRIPTION_ELEMENTS_OPTS.value[0].value;
  getCountryProfiles(LAST_SELECTED_ELEMENT_TO_VIEW.value);
});

onMounted(async () => {
  if (route.query.element) {
    LAST_SELECTED_ELEMENT_TO_VIEW.value = route.query.element;
  }

  if (LAST_SELECTED_ELEMENT_TO_VIEW.value) {
    await getPublishedProfiles(LAST_SELECTED_ELEMENT_TO_VIEW.value);
    await getCountryProfiles(LAST_SELECTED_ELEMENT_TO_VIEW.value);
  }

  if (route.query.profileType) {
    if (route.query.profileType === "COMPANY") {

      tableView.value = "COMPANY_PROFILES"

    } else if (route.query.profileType === "COUNTRY") {

      tableView.value = "COUNTRY_PROFILES"

    }
  }

});
</script>

<style lang="scss" scoped>
.floating-menu {
  // width: 250px;
  // position: absolute;
  // margin: 10px 0 0 0px;
  width: 100%;
}

.mapbox-legend {
  margin-right: 5px;
  width: 10px;
  height: 10px;
  border-radius: 50px;
  display: inline-block;

  &--red {
    background-color: #d83615;
  }

  &--blue {
    background-color: #293c4f;
  }

  &--green {
    background-color: #1c9099;
  }

  &--yellow {
    background-color: #e1b16a;
  }

  &--white {
    background-color: #293c4f;
    border: 1px solid black;
  }
}

// ROUNDED SCROLLBAR - START //
::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-thumb {
  background: darkgrey;
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background: darkgrey;
}

::-webkit-scrollbar-thumb:active {
  background: darkgrey;
}

// ROUNDED SCROLLBAR - END //</style>