<template>
  <div class="py-4 container-fluid" v-if="!profile || inProgress">
    <div class="row">
      <div class="col">
        <div class="card h-100">
          <div class="card-body">
            <NSkeleton text :repeat="3" /> <NSkeleton text style="width: 60%" />
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="py-4 container-fluid" v-else>
    <!-- <ProfilesMenu /> -->
    <div class="row">
      <div class="col-8 mb-4">
        <div class="card">
          <div class="card-body">
            <div class="d-flex justify-content-between">
              <h3 class="mb-4 proxima-blue-text">
                {{ profile.companyName }}
              </h3>
              <div>
                <button class="btn bg-gradient-success btn-sm" @click="addNote">
                  {{
                    has_notes("COMPANY", route)
                      ? $t("CompanyProfile.viewNotesButton")
                      : $t("CompanyProfile.addNoteButton")
                  }}
                </button>
              </div>
            </div>
            <div class="row">
              <InfoText
                class="col-12 mb-3"
                v-if="profile.companyOverview"
                :title="$t('CompanyProfile.companyOverviewHeader')"
                :contents="profile.companyOverview"
              />
              <InfoText
                class="col-12 mb-3"
                v-if="profile.profileOverview"
                :title="$t('CompanyProfile.profileHeader')"
                :contents="profile.profileOverview"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="col-4 mb-4">
        <div class="row">
          <div class="col">
            <div class="card">
              <div class="card-body">
                <div class="row mb-4" v-if="profile.headquarterCountry">
                  <div class="col-12">
                    <h5 class="proxima-blue-text">
                      {{ $t("CompanyProfile.headquartersHeader") }}
                    </h5>
                  </div>
                  <div
                    class="col-12 text-sm"
                    @click="goToCountryProfile"
                    style="cursor: pointer"
                  >
                    {{ profile.headquarterCountry }}
                  </div>
                </div>
                <div class="row mb-4" v-if="profile.ownerType">
                  <div class="col-12">
                    <h5 class="proxima-blue-text">
                      {{ $t("CompanyProfile.ownershipHeader") }}
                    </h5>
                  </div>
                  <div
                    class="col-12 text-sm"
                    style="text-transform: capitalize"
                  >
                    {{ profile.ownerType?.toLowerCase() }}
                  </div>
                </div>
                <div class="row mb-4" v-if="stockExchanges.length">
                  <div class="col-12">
                    <h5 class="proxima-blue-text">
                      {{ $t("CompanyProfile.investorOrExchangeHeader") }}
                    </h5>
                  </div>
                  <div
                    class="col-12 text-sm"
                    v-for="nth of stockExchanges"
                    :key="nth.stockExchange"
                  >
                    {{
                      nth.stockExchange
                        ? `${nth.stockExchange}: ${(nth.tickerNames || []).join(
                            ", "
                          )}`
                        : ""
                    }}
                  </div>
                </div>
                <!-- <div class="row mb-4" v-if="subsidiaries.length">
                  <div class="col-12">
                    <h5 class="proxima-blue-text">
                      {{ $t("CompanyProfile.subsidiariesHeader") }}
                    </h5>
                  </div>
                  <div
                    class="col-12 text-sm"
                    v-for="nth of subsidiaries"
                    :key="`${nth.id}_${Math.random()}`"
                    @click="goToCompanyProfile(nth.id)"
                    style="cursor: pointer"
                  >
                    {{
                      $t("CompanyProfile.subsidiaryCompany", {
                        companyName: nth.companyName,
                        percentage: nth.percentage,
                      })
                    }}
                  </div>
                </div> -->
                <div class="row mb-4" v-if="relatedAssets.length">
                  <div class="col-12">
                    <h5 class="proxima-blue-text">
                      {{ $t("CompanyProfile.relatedAssetsHeader") }}
                    </h5>
                  </div>
                  <div
                    class="col-12 text-sm"
                    v-for="nth of relatedAssets"
                    :key="nth"
                    @click="goToAssetProfile(nth)"
                    :style="[
                      isSubscribedToAssetProfile(nth) ? 'cursor: pointer' : '',
                    ]"
                  >
                    {{
                      $t("CompanyProfile.relatedAsset", {
                        asset: nth.shortName || nth.name,
                        country: nth.countryName,
                      })
                    }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 mb-4">
        <div class="card">
          <div class="card-body px-0 pb-1">
            <iframe
              id="gmap_canvas"
              width="100%"
              height="400"
              :src="`https://maps.google.com/maps?q=${profile.coordinates}&t=k&z=5&ie=UTF8&iwloc=&output=embed`"
              frameborder="0"
              scrolling="no"
              marginheight="0"
              marginwidth="0"
            ></iframe>
            <div class="m-2 mx-3">
              {{
                $t("CompanyProfile.locationFooter", {
                  country: profile.headquarterCountry,
                })
              }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onBeforeUnmount, onMounted, computed, nextTick } from "vue";
import { useRoute, useRouter } from "vue-router";
import { sortBy, uniqBy } from "lodash";
import { ROUTES } from "../../routes/names";
import { NSkeleton } from "naive-ui";
import {
  MOUNTED_COMPANY_PROFILE as profile,
  getCompanyProfile,
  inProgress,
  MOUNTED_COMPANY_PROFILE,
} from "../../composables/profiles/company";
import InfoText from "../../components/dumb/ProfileInfoText";
import {
  NOTES_WINDOW,
  SHOW_ALL_NOTES,
  has_notes,
} from "../../composables/notes/list";
import ProfilesMenu from "../../components/ProfilesMenu.vue";
import {
  getCountryProfile,
  MOUNTED_COUNTRY_PROFILE,
} from "../../composables/profiles/country";
import { PUBLISHED_PROFILES } from "../../composables/profiles/list";
import {
  getAssetProfile,
  MOUNTED_ASSET_PROFILE,
} from "../../composables/profiles/asset";
import { SUBSCRIPTION_INFO } from "../../composables/subscription";

const route = useRoute();
const router = useRouter();

onMounted(async () => {
  const uuid = route.params?.id;
  const element = route.query?.element;
  if (!uuid) router.push({ name: ROUTES.PROXIMA_PROFILES.name });
  if (!profile.value) await getCompanyProfile({ element, uuid, id: null });
});

onBeforeUnmount(() => {
  profile.value = null;
});

const addNote = () => {
  NOTES_WINDOW.value = true;
  SHOW_ALL_NOTES.value = false;
};

const stockExchanges = computed(() => {
  return (profile.value?.stockExchanges || []).filter(
    (nth) => nth.stockExchange !== null
  );
});

const subsidiaries = computed(() => {
  const assetProfiles = PUBLISHED_PROFILES.value?.assetProfiles || [];
  const tmp = [];

  for (const nth of assetProfiles.filter((nth) => nth.companyId !== null)) {
    const owner = nth.owners?.find(
      (ith) => ith.id === profile.value?.companyId
    );
    if (
      !owner ||
      (profile.value?.companyId &&
        nth?.companyId &&
        profile.value?.companyId === nth?.companyId)
    )
      continue;
    tmp.push({
      id: nth?.companyId,
      companyName: nth?.companyName,
      percentage: owner.percentage || 0,
    });
  }
  const sorted = sortBy(tmp, "companyName");
  return uniqBy(sorted, "companyName");
});

const relatedAssets = computed(() => {
  const assetProfiles = PUBLISHED_PROFILES.value?.assetProfiles || [];
  const tmp = [];
  for (const nth of assetProfiles) {
    const ownerIds = (nth?.owners || []).map((ith) => ith.id);
    if (ownerIds.includes(profile.value?.companyId)) tmp.push(nth);
  }
  return sortBy(tmp, "name");
});

// const goToCountryProfile = async () => {
//   const element = route.query.element;
//   await getCountryProfile({
//     element,
//     uuid: null,
//     code: profile.value?.countryCode,
//   });
//   nextTick(() => {
//     if (MOUNTED_COUNTRY_PROFILE.value?.uuid) {
//       router.push({
//         name: ROUTES.COUNTRY_PROFILE.name,
//         params: { id: MOUNTED_COUNTRY_PROFILE.value?.uuid },
//         query: { element },
//       });
//     } else window.$message.info("No profile found");
//   });
// };

const goToCountryProfile = async () => {
  const profileType = 'COUNTRY'
  const element = route.query.element;
  await getCountryProfile({
    element,
    uuid: null,
    code: profile.value?.countryCode,
  });
  nextTick(() => {
    if (MOUNTED_COUNTRY_PROFILE.value?.uuid) {
      const uuid = MOUNTED_COUNTRY_PROFILE.value?.uuid
      router.push({
        name: ROUTES.PROXIMA_PROFILES.name,
        // params: { id: MOUNTED_COMPANY_PROFILE.value?.uuid },
        query: {  _: Math.random(), profileType, uuid, element },
      });
    } else window.$message.info("No profile found");
  });
};

const goToCompanyProfile = async (id) => {
  const element = route.query.element;
  await getCompanyProfile({
    element,
    uuid: null,
    id,
  });
  nextTick(() => {
    if (MOUNTED_COMPANY_PROFILE.value?.uuid) {
      router.push({
        name: ROUTES.COMPANY_PROFILE.name,
        params: { id: MOUNTED_COMPANY_PROFILE.value?.uuid },
        query: { element },
      });
    } else window.$message.info("No profile found");
  });
};

const isSubscribedToAssetProfile = (nth) => {
  const element = route.query.element;
  const allElements = [
    ...new Set([
      ...nth.byProductCommodities,
      ...nth.nonCommercialCommodities,
      ...nth.primaryCommodities,
    ]),
  ];
  return (
    allElements.includes(element) &&
    SUBSCRIPTION_INFO.elements?.map((nth) => nth.code).includes(element)
  );
};

const goToAssetProfile = async (nth) => {
  if (!isSubscribedToAssetProfile(nth)) {
    window.$message.info("No profile found");
    return;
  }
  const element = route.query.element;
  await getAssetProfile({
    element,
    uuid: nth.uuid,
  });
  nextTick(() => {
    if (MOUNTED_ASSET_PROFILE.value?.uuid) {
      router.push({
        name: ROUTES.ASSET_PROFILE.name,
        params: { id: MOUNTED_ASSET_PROFILE.value?.uuid },
        query: { element },
      });
    } else window.$message.info("No profile found");
  });
};
</script>
