import { ref, computed } from "vue";
import { query } from "gql-query-builder";
import HttpClient from "project-blue-http-client";
import { GQL_QUERIES } from "../../utils/APIs";
import { ROUTES } from "../../routes/names";
import { sortBy } from "lodash";


export const MOUNTED_ASSET_PROFILE = ref(null);
export const MOUNTED_ASSET_PROFILES = ref(null);
export const MOUNTED_ASSET_PROFILE_DETAILS = ref(null);
export const PROFILE_ID = ref(null);
export const inProgress = ref(false);
export const reqSignal = ref(new AbortController());

export const fields = [
  "id",
  "uuid",
  "alias",
  "name",
  "shortName",
  "profile",
  "projects",
  "type",
  "status",
  "stage",
  "pinAccuracy",
  "province",
  "nearestTown",
  "primaryCommodities",
  "nonCommercialCommodities",
  "byProductCommodities",
  "significance",
  "countryCode",
  "countryName",
  "companyId",
  "companyName",
  "coordinates",
  { owners: ["id", "companyName", "percentage"] },
  {
    products: [
      "productId",
      "name",
      "commission",
      "status",
      "capacity",
      "unit",
      "grossContained",
      "commodities",
      { grades: ["name", "unit", "value"] },
    ],
  },
];

export const getAssetProfile = async ({ element, uuid }) => {
  const gqlQuery = GQL_QUERIES.GET_SUBSCRIBED_ASSET_PROFILE;
  reqSignal.value.abort();
  reqSignal.value = new AbortController();
  inProgress.value = true;


  let client = new HttpClient(gqlQuery.baseUrl);
  client.get(`${gqlQuery.controller}/${gqlQuery.operation}/${uuid}`)
    .then(response => {
      MOUNTED_ASSET_PROFILE.value = response;
      inProgress.value = false;
    });
};


// TODO
export const getAssetProfiles = async ({ company, element, country }) => {
  const gqlQuery = GQL_QUERIES.GET_ASSET_PROFILES;
  reqSignal.value.abort();
  reqSignal.value = new AbortController();
  inProgress.value = true;
  const payload = query([
    {
      operation: gqlQuery.operation,
      variables: {
        input: {
          value: {
            company: company,
            keyword: null,
            element: element,
            primaryCommodity: null,
            byProductCommodity: null,
            nonCommercialCommodity: null,
            country: country,
            status: null,
            stage: null,
            products: [],
            type: null,
            page: {
              current: 1,
              size: 10000,
              sortColumn: null,
              sortOrder: null
            },
          },
          type: gqlQuery.input,
          required: true,
        },
      },
      fields: gqlQuery.fields,
    },
  ]);

  const response = await gqlRequest(payload, reqSignal.value.signal);
  inProgress.value = false;

  MOUNTED_ASSET_PROFILES.value = response.data?.[gqlQuery.operation] || {};
};

export const getAssetProfileDetails = async (uuid) => {
  const gqlQuery = GQL_QUERIES.GET_ASSET_PROFILE;
  reqSignal.value.abort();
  reqSignal.value = new AbortController();
  inProgress.value = true;
  const payload = query([
    {
      operation: gqlQuery.operation,
      variables: {
        uuid: { value: uuid, type: 'String!' },
      },
      fields: [
        "OEMSuppliers",
        "accessControls",
        "alternativeNames",
        "assignedTo",
        "attachmentLinks",
        "byProductCommodities",
        "changes",
        "commissioned",
        "commodities",
        "coordinates",
        "costed",
        "country",
        "countryName",
        "created",
        "createdBy",
        "editedBy",
        "feedStock",
        "geologicalRegion",
        "id",
        "installationType",
        "installationYear",
        "lom",
        "modified",
        "name",
        "nameShort",
        "nearestTown",
        "nonCommercialCommodities",
        "operatorId",
        "operatorName",
        "pinAccuracy",
        "profile",
        "projects",
        "province",
        "reservesNotes",
        "reservesTotalTons",
        "reservesTotalTonsUnit",
        "resourcesNotes",
        "resourcesTotalTons",
        "resourcesTotalTonsUnit",
        "sourceLinks",
        "stage",
        "status",
        "streamType",
        "totalTonsIndicated",
        "totalTonsInferred",
        "totalTonsMeasured",
        "totalTonsProbable",
        "totalTonsProven",
        "totalTonsReserves",
        "totalTonsResources",
        "type",
        "uuid",
        "versions"
      ]

      // fields: gqlQuery.fields,
    },
  ]);
  const response = await gqlRequest(payload, reqSignal.value.signal);
  inProgress.value = false;

  MOUNTED_ASSET_PROFILE_DETAILS.value = response.data?.[gqlQuery.operation] || {};
};

export const ASSET_STATUS_OPTS = computed(() => {
  const tmp = [
    ...new Set(
      (MOUNTED_ASSET_PROFILES.value?.profiles || []).map((nth) => nth.status)
    ),
  ];
  const opts = tmp
    .filter((status) => status?.length > 0)
    .map((status) => {
      return {
        label: status,
        value: status,
      };
    });
  return sortBy(opts, "label");
})

export const mountProfile = async (
  router,
  { profile, element, uuid = null } = {}
) => {
  MOUNTED_ASSET_PROFILE.value = profile;
  router.push({
    name: ROUTES.ASSET_PROFILE.name,
    params: { id: profile?.uuid || uuid },
    query: { element },
  });
};
