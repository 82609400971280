<template>
  <div
    class="px-3 text-sm text-wrap m-0"
    :class="{
      'font-weight-bold cursor-pointer': props.hasProfile,
      'text-muted': !props.hasProfile,
    }"
    style="line-height: 1.2"
    @click="goToProfilePage"
  >
    {{ props.text }}
  </div>
</template>

<script setup>
const props = defineProps({
  text: {
    type: String,
    default: null,
  },
  hasProfile: {
    type: Boolean,
    default: false,
  },
  profile: {
    type: Object,
    default: null,
  },
});
const emit = defineEmits(["mountProfile"]);

const goToProfilePage = () => {
  if (props.hasProfile) emit("mountProfile", props.profile);
};
</script>
