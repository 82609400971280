<template>
  <main class="mt-0 main-content">
    <section>
      <div class="page-header min-vh-100">
        <div class="container">
          <div class="row">
            <div
              class="mx-auto col-xl-4 col-lg-5 col-md-7 d-flex flex-column mx-lg-0"
            >
              <div class="card card-plain bg-light-gray">
                <div class="pb-0 card-header text-start bg-light-gray">
                  <h4 class="font-weight-bolder proxima-blue-text">
                    {{ $t("Login.title") }}
                  </h4>
                  <p class="mb-0">{{ $t("Login.subtitle") }}</p>
                </div>
                <div class="card-body">
                  <div role="form">
                    <div class="mb-3">
                      <input
                        type="email"
                        class="form-control form-control-lg"
                        v-model="credentials.email"
                        :placeholder="t('Login.emailPlaceholder')"
                        @keyup.enter="authorizeUser()"
                      />
                    </div>
                    <div class="mb-3">
                      <input
                        type="password"
                        class="form-control form-control-lg"
                        v-model="credentials.password"
                        :placeholder="t('Login.passwordPlaceholder')"
                        @keyup.enter="authorizeUser()"
                      />
                    </div>
                    <div class="text-center">
                      <button
                        class="btn btn-lg proxima-yellow-button text-white w-100 mb-0 mt-4"
                        :disabled="isNotReadyToSubmit"
                        @click="submitForCaptcha()"
                      >
                        {{ $t("Login.loginButton") }}
                      </button>
                      <VueHcaptcha
                        ref="hCaptcha"
                        sitekey="de74b7d8-cbfc-42fd-bd06-609e78d5868a"
                        data-size="invisible"
                        @verify="authorizeUser"
                      />
                    </div>
                  </div>
                </div>
                <div class="px-1 pt-0 text-center card-footer px-lg-2">
                  <p class="mx-auto text-sm">
                    {{ $t("Login.contactUs") }}&nbsp;
                    <a
                      href="mailto:info@theprojectblue.com"
                      target="_blank"
                      class="proxima-blue-bg-gradient text-gradient font-weight-bold"
                      >{{ $t("Login.supportEmail") }}</a
                    >
                  </p>
                  <!-- <NDivider dashed> <div class="text-sm">or</div> </NDivider>
                  <p class="mx-auto mb-4 text-sm">
                    {{ $t("Login.remindToRegister") }}&nbsp;
                    <RouterLink
                      :to="{ name: ROUTES.REGISTER.name }"
                      class="proxima-blue-bg-gradient text-gradient font-weight-bold"
                      >{{ $t("Login.registerLink") }}</RouterLink
                    >
                  </p> -->
                </div>
              </div>
            </div>
            <div
              class="top-0 my-auto text-center col-6 d-lg-flex d-none h-100 pe-0 position-absolute end-0 justify-content-center flex-column"
            >
              <div
                class="position-relative bg-gradient-primary h-100 m-3 px-7 border-radius-lg d-flex flex-column justify-content-center overflow-hidden"
                :style="{
                  backgroundImage:
                    'url(' + require('@/assets/login-bg.jpg') + ')',
                  backgroundSize: 'cover',
                }"
              >
                <span class="mask bg-gradient-dark opacity-6"></span>
                <h4
                  class="mt-5 text-white font-weight-bolder position-relative"
                >
                  {{ $t("Login.mainQuote") }}
                </h4>
                <p class="text-white position-relative">
                  {{ $t("Login.subQuote") }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script setup>
import { computed, onMounted, onUnmounted, reactive, ref } from "vue";
import { NDivider } from "naive-ui";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import { ROUTES } from "../routes/names";
import { login } from "../composables/auth";
import VueHcaptcha from "@hcaptcha/vue3-hcaptcha";

const { t } = useI18n();
const router = useRouter();
const credentials = reactive({
  email: null,
  password: null,
});
const isNotReadyToSubmit = computed(() => {
  return !credentials.email || !credentials.password;
});
const hCaptcha = ref(null);
const attempts = ref(0); // TODO: need to store this on db

const body = document.getElementsByTagName("body")[0];

onMounted(() => {
  body.classList.remove("bg-gray-100");
});

onUnmounted(() => {
  body.classList.add("bg-gray-100");
});

const authorizeUser = async () => {
  if (!isNotReadyToSubmit.value) {
    const isAuthorized = await login(credentials);
    if (isAuthorized) router.push({ name: ROUTES.DASHBOARD.name });
  }
};

const submitForCaptcha = async () => {
  if (!isNotReadyToSubmit.value && attempts.value >= 5)
    hCaptcha.value.execute();
  else await authorizeUser();
};
</script>

<style lang="scss" scope>
.bg-light-gray {
  background-color: #f2f2f2 !important;
}
</style>
