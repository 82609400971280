<template>
  <nav
    class="navbar navbar-main navbar-expand-lg px-0 mx-4 shadow-none border-radius-xl"
    id="navbarBlur"
    data-scroll="true"
  >
    <div class="px-0 py-1 container-fluid">
      <div
        class="mt-2 collapse navbar-collapse mt-sm-1 me-md-0 me-sm-4"
        id="navbar"
      >
        <div
          class="align-items-center d-none d-sm-flex me-3"
          v-if="
            [
              ROUTES.ASSET_PROFILE.name,
              ROUTES.COMPANY_PROFILE.name,
              ROUTES.COUNTRY_PROFILE.name,
              ROUTES.NOTES.name,
              ROUTES.FILES.name,
              ROUTES.STREAMS.name,
            ].includes(route.name)
          "
        >
          <button
            class="btn btn-md btn-white text-black mt-0 mb-0 px-3"
            @click="returnToList(ROUTES.PROXIMA_PROFILES.name)"
          >
            <NIcon size="20"><ArrowLeft /></NIcon>
          </button>
        </div>
        <div
          class="align-items-center d-none d-sm-flex me-3"
          v-if="[ROUTES.NOTE.name].includes(route.name)"
        >
          <button
            class="btn btn-md btn-white text-black mt-0 mb-0 px-3"
            @click="returnToList(ROUTES.NOTES.name)"
          >
            <NIcon size="20"><ArrowLeft /></NIcon>
          </button>
        </div>
        <div
          class="align-items-center d-none d-sm-flex me-3"
          v-if="[ROUTES.FILE_SERIES.name].includes(route.name)"
        >
          <button
            class="btn btn-md btn-white text-black mt-0 mb-0 px-3"
            @click="returnToList(ROUTES.FILES.name)"
          >
            <NIcon size="20"><ArrowLeft /></NIcon>
          </button>
        </div>
        <div
          class="align-items-center d-none d-sm-flex me-3"
          v-if="[ROUTES.FILE.name].includes(route.name)"
        >
          <button
            class="btn btn-md btn-white text-black mt-0 mb-0 px-3"
            @click="returnToList(ROUTES.FILE_SERIES.name)"
          >
            <NIcon size="20"><ArrowLeft /></NIcon>
          </button>
        </div>
        <div class="pe-md-3 d-flex align-items-center ms-md-auto">
          <!-- <div class="input-group">
            <span class="input-group-text text-body">
              <i class="fas fa-search" aria-hidden="true"></i>
            </span>
            <input
              type="text"
              class="form-control"
              placeholder="Type here..."
            />
          </div> -->
          <h2>&nbsp;</h2>
        </div>
        <ul class="navbar-nav justify-content-end">
          <!-- <li class="nav-item d-xl-none ps-3 d-flex align-items-center">
            <a
              href="javascript:;"
              @click="toggleSidebar"
              class="p-0 nav-link text-white"
              id="iconNavbarSidenav"
            >
              <div class="sidenav-toggler-inner">
                <i class="sidenav-toggler-line bg-white">Test 1</i>
                <i class="sidenav-toggler-line bg-white">Test 2</i>
                <i class="sidenav-toggler-line bg-white">Test 3</i>
              </div>
            </a>
          </li> -->
          <li class="nav-item dropdown d-flex align-items-center pe-3">
            <!-- TODO -->
            <NavItems />
          </li>
          <li class="nav-item dropdown d-flex align-items-center pe-3">
            <NotificationsMenu />
          </li>
          <li class="nav-item dropdown d-flex align-items-center pe-3">
            <Notes />
          </li>
          <li class="nav-item dropdown d-flex align-items-center pe-2">
            <Logout />
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script setup>
import { onMounted } from "vue";
import { NIcon } from "naive-ui";
import { ArrowLeft } from "@vicons/tabler";
import SettingsMenu from "./Settings.vue";
import NotificationsMenu from "./Notifications.vue";
import NavItems from './NavItems.vue'
import Logout from "./Logout.vue";
import Notes from "./Notes.vue";
import { useRouter, useRoute } from "vue-router";
import { ROUTES } from "../../routes/names";

const router = useRouter();
const route = useRoute();

const openSidebar = (sidenavShow) => {
  sidenavShow.classList.remove("g-sidenav-hidden");
  sidenavShow.classList.add("g-sidenav-pinned");
};
const closeSidebar = (sidenavShow) => {
  sidenavShow.classList.add("g-sidenav-hidden");
  sidenavShow.classList.remove("g-sidenav-pinned");
};
const toggleSidebar = () => {
  const sidenavShow = document.querySelector(".g-sidenav-show");
  if (sidenavShow.classList.contains("g-sidenav-hidden"))
    openSidebar(sidenavShow);
  else closeSidebar(sidenavShow);
};

const returnToList = (name) => {
  router.push({ name });
};

onMounted(() => {
  const sidenavShow = document.querySelector(".g-sidenav-show");
  openSidebar(sidenavShow);
});
</script>
