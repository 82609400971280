import { ref, computed, watch } from "vue";
import { query } from "gql-query-builder";
import HttpClient from "project-blue-http-client";
import { GQL_QUERIES } from "../../utils/APIs";
import { ROUTES } from "../../routes/names";

export const MOUNTED_COMPANY_PROFILE = ref(null);
export const MOUNTED_COMPANIES = ref(null);
export const inProgress = ref(false);
export const reqSignal = ref(new AbortController());

export const fields = [
  "id",
  "uuid",
  "alias",
  "companyId",
  "companyName",
  "companyOverview",
  "profileOverview",
  { profileOverviews: ["element", "links", "overview"] },
  "coordinates",
  "headquarterCountry",
  "countryCode",
  "ownerType",
  "associatedCompanies",
  { stockExchanges: ["stockExchange", "tickerNames"] },
  { owners: ["id", "companyName", "percentage"] },
];

export const getCompanyProfile = async ({ element, uuid, id }) => {
  const gqlQuery = GQL_QUERIES.GET_SUBSCRIBED_COMPANY_PROFILE;
  reqSignal.value.abort();
  reqSignal.value = new AbortController();
  inProgress.value = true;
  
  
  let client = new HttpClient(gqlQuery.baseUrl);
  client.get(`${gqlQuery.controller}/${gqlQuery.operation}/${id}`)
  .then(response => {
    MOUNTED_COMPANY_PROFILE.value = response;
    inProgress.value = false;
  });
};

export const getCompanyProfiles = async ({id,country,commodity,keyword}) => {
  const gqlQuery = GQL_QUERIES.GET_COMPANY_PROFILES;
  reqSignal.value.abort();
  reqSignal.value = new AbortController();
  inProgress.value = true;

  let client = new HttpClient(gqlQuery.baseUrl);
  client.get(`${gqlQuery.controller}/${gqlQuery.operation}/${commodity}`)
  .then(response => {
    MOUNTED_COMPANY_PROFILE.value = response;
    inProgress.value = false;
  });
};

export const getCompanies = async (element) => {

  const gqlQuery = GQL_QUERIES.GET_COMPANIES;
  reqSignal.value.abort();
  reqSignal.value = new AbortController();
  inProgress.value = true;

  let client = new HttpClient(gqlQuery.baseUrl);
  client.get(`${gqlQuery.controller}/${gqlQuery.operation}`)
  .then(response => {
    MOUNTED_COMPANIES.value = response;
    inProgress.value = false;
  });
};

// export const ALL_COMPANIES_OPTS = computed(() => {
//   return (MOUNTED_COMPANIES.value || []).map((nth) => {
//     return {
//       label: nth.name,
//       value: nth.id,
//     };
//   });
// });

export const mountProfile = async (
  router,
  { profile, element, uuid = null } = {}
) => {
  MOUNTED_COMPANY_PROFILE.value = profile;
  router.push({
    name: ROUTES.COMPANY_PROFILE.name,
    params: { id: profile?.uuid || uuid },
    query: { element },
  });
};
