<template>
  <div class="py-4 container-fluid" v-if="!profile || inProgress">
    <div class="row">
      <div class="col">
        <div class="card h-100">
          <div class="card-body">
            <NSkeleton text :repeat="3" /> <NSkeleton text style="width: 60%" />
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="py-4 container-fluid" v-else>
    <!-- <ProfilesMenu /> -->
    <div class="row">
      <div class="col-8 mb-4">
        <div class="card">
          <div class="card-body">
            <div class="d-flex justify-content-between">
              <h3 class="mb-4 proxima-blue-text">
                {{ profile.countryName }}
              </h3>
              <div>
                <button class="btn bg-gradient-success btn-sm" @click="addNote">
                  {{
                    has_notes("COUNTRY", route)
                      ? $t("CountryProfile.viewNotesButton")
                      : $t("CountryProfile.addNoteButton")
                  }}
                </button>
              </div>
            </div>
            <div class="row">
              <InfoText
                class="col-12 mb-3"
                v-if="profile.geology"
                :title="$t('CountryProfile.geologyHeader')"
                :contents="profile.geology"
              />
              <InfoText
                class="col-12 mb-3"
                v-if="profile.production"
                :title="$t('CountryProfile.productionHeader')"
                :contents="profile.production"
              />
              <InfoText
                class="col-12 mb-3"
                v-if="profile.ppd"
                :title="$t('CountryProfile.ppdHeader')"
                :contents="profile.ppd"
              />
              <InfoText
                class="col-12 mb-3"
                v-if="profile.other"
                :title="$t('CountryProfile.otherHeader')"
                :contents="profile.other"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="col-4 mb-4">
        <div class="row">
          <div class="col">
            <div class="card">
              <div class="card-body">
                <div
                  class="row mb-4"
                  v-if="Object.keys(assetsInCountry).length"
                >
                  <div class="col-12">
                    <h5 class="proxima-blue-text">
                      {{
                        $t("CountryProfile.assetsInCountryHeader", {
                          country: profile.countryName,
                        })
                      }}
                    </h5>
                  </div>
                  <div
                    class="col-12 text-sm"
                    v-for="(value, key) of assetsInCountry"
                    :key="key"
                  >
                    {{ key }}: {{ value.length }} Operating
                  </div>
                </div>
                <div class="row mb-4" v-if="companyInCountries.length">
                  <div class="col-12">
                    <h5 class="proxima-blue-text">
                      {{ $t("CountryProfile.listOfCompaniesHeader") }}
                    </h5>
                  </div>
                  <div
                    class="col-12 text-sm"
                    :class="{
                      'text-bold': companyProfilesInCountries.includes(
                        nth.companyId
                      ),
                    }"
                    v-for="nth of companyInCountries"
                    :key="nth.uuid"
                    @click="goToCompanyProfile(nth)"
                    style="cursor: pointer"
                  >
                    {{ nth.companyName }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 mb-4">
        <div class="card">
          <div class="card-body px-0 pb-1">
            <iframe
              id="gmap_canvas"
              width="100%"
              height="400"
              :src="`https://maps.google.com/maps?q=${profile.countryName}&t=k&z=5&ie=UTF8&iwloc=&output=embed`"
              frameborder="0"
              scrolling="no"
              marginheight="0"
              marginwidth="0"
            ></iframe>
            <div class="m-2 mx-3">
              {{ profile.countryName }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, nextTick, onBeforeUnmount, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import { ROUTES } from "../../routes/names";
import { NSkeleton } from "naive-ui";
import {
  MOUNTED_COUNTRY_PROFILE as profile,
  getCountryProfile,
  inProgress,
} from "../../composables/profiles/country";
import InfoText from "../../components/dumb/ProfileInfoText";
import { PUBLISHED_PROFILES } from "../../composables/profiles/list";
import {
  NOTES_WINDOW,
  SHOW_ALL_NOTES,
  has_notes,
} from "../../composables/notes/list";
import ProfilesMenu from "../../components/ProfilesMenu.vue";
import {
  getCompanyProfile,
  MOUNTED_COMPANY_PROFILE,
} from "../../composables/profiles/company";
import { sortBy, uniqBy } from "lodash";

const route = useRoute();
const router = useRouter();

onMounted(async () => {
  const uuid = route.params?.id;
  const element = route.query?.element;
  if (!uuid || !element) router.push({ name: ROUTES.PROXIMA_PROFILES.name });
  if (!profile.value) await getCountryProfile({ element, uuid });
});

onBeforeUnmount(() => {
  profile.value = null;
});

const addNote = () => {
  NOTES_WINDOW.value = true;
  SHOW_ALL_NOTES.value = false;
};

const assetsInCountry = computed(() => {
  const assets = (PUBLISHED_PROFILES.value?.assetProfiles || []).filter(
    (nth) => nth.countryCode === profile.value?.countryCode
  );
  const types = {};
  for (const nth of assets) {
    if (nth.status !== "Operating") continue;
    if (nth.type in types) types[nth.type].push(nth.status);
    else types[nth.type] = [nth.status];
  }
  return types;
});

const companyProfilesInCountries = computed(() => {
  return (PUBLISHED_PROFILES.value?.companyProfiles || []).map(
    (nth) => nth.companyId
  );
});
const companyInCountries = computed(() => {
  const opts = (PUBLISHED_PROFILES.value?.assetProfiles || [])
    .filter((nth) => nth.countryCode === profile.value?.countryCode)
    .map((nth) => nth);
  const sorted = sortBy(opts, "companyName");
  return uniqBy(sorted, "companyId");
});

const goToCompanyProfile = async (nth) => {
  if (!companyProfilesInCountries.value?.includes(nth.companyId)) {
    window.$message.info("No profile found");
    return;
  }
  const element = route.query.element;
  await getCompanyProfile({
    element,
    uuid: null,
    id: nth.companyId,
  });
  nextTick(() => {
    if (MOUNTED_COMPANY_PROFILE.value?.uuid) {
      router.push({
        name: ROUTES.COMPANY_PROFILE.name,
        params: { id: MOUNTED_COMPANY_PROFILE.value?.uuid },
        query: { element },
      });
    } else window.$message.info("No profile found");
  });
};
</script>
