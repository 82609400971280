import { ref, reactive } from "vue";
import { mutation, query } from "gql-query-builder";
import HttpClient from "project-blue-http-client";
import { GQL_QUERIES, GQL_MUTATION } from "../../utils/APIs";
import { getUserNotes } from "./list";

export const MOUNTED_USER_NOTE = reactive({
  id: null,
  title: null,
  element: null,
  profileUUID: null,
  profileType: null,
  taggedPersons: [],
  note: null,
  type: "PERSONAL",
  subscriptionPackageId: null,
  created: null,
  removed: false,
});
export const inProgress = ref(false);
export const reqSignal = ref(new AbortController());

export const getUserNote = async (id) => {
  const gqlQuery = GQL_QUERIES.GET_USER_NOTE;
  reqSignal.value.abort();
  reqSignal.value = new AbortController();
  inProgress.value = true;
  const payload = query([
    {
      operation: gqlQuery.operation,
      variables: {
        id: {
          value: id,
          required: true,
        },
      },
      fields: [
        "id",
        "title",
        "element",
        "profileUUID",
        "profileType",
        "taggedPersons",
        "note",
        "subscriptionPackage",
        "type",
        "created",
      ],
    },
  ]);
  const response = await gqlRequest(payload, reqSignal.value.signal);
  inProgress.value = false;

  const note = response.data?.[gqlQuery.operation] || {};
  mountNote(note);
};

export const updateOrCreateNote = async () => {
  const gqlQuery = GQL_MUTATION.MANAGE_PERSONAL_NOTE;
  reqSignal.value.abort();
  reqSignal.value = new AbortController();

  let data = {
    title: MOUNTED_USER_NOTE.title,
    profileType: MOUNTED_USER_NOTE.profileType,
    notes: MOUNTED_USER_NOTE.note,
    profileUuid: MOUNTED_USER_NOTE.profileUUID,
    type: MOUNTED_USER_NOTE.type,
    id : MOUNTED_USER_NOTE.id
  };

  let client = new HttpClient(gqlQuery.baseUrl);
  client.put(`${gqlQuery.controller}/${gqlQuery.operation}`, data)
    .then(() => {
      getUserNotes();
    });
};

export const deleteNote = async (id) => {
  const gqlQuery = GQL_MUTATION.DELETE_PERSONAL_NOTE;
  reqSignal.value.abort();
  reqSignal.value = new AbortController();
  inProgress.value = true;

  let client = new HttpClient(gqlQuery.baseUrl);
  client.delete(`${gqlQuery.controller}/${gqlQuery.operation}/${id}`)
    .then(() => {
      getUserNotes();
      inProgress.value = false;
    });
};

export const unMountNote = () => {
  MOUNTED_USER_NOTE.id = null;
  MOUNTED_USER_NOTE.title = null;
  MOUNTED_USER_NOTE.element = null;
  MOUNTED_USER_NOTE.profileUUID = null;
  MOUNTED_USER_NOTE.profileType = null;
  MOUNTED_USER_NOTE.taggedPersons = [];
  MOUNTED_USER_NOTE.note = null;
  MOUNTED_USER_NOTE.type = "PERSONAL";
  MOUNTED_USER_NOTE.subscriptionPackageId = null;
  MOUNTED_USER_NOTE.created = null;
  MOUNTED_USER_NOTE.removed = false;
};

export const mountNote = (note) => {
  MOUNTED_USER_NOTE.id = note.id;
  MOUNTED_USER_NOTE.title = note.title;
  MOUNTED_USER_NOTE.element = note.element;
  MOUNTED_USER_NOTE.profileUUID = note.profileUUID;
  MOUNTED_USER_NOTE.profileType = note.profileType;
  MOUNTED_USER_NOTE.taggedPersons = note.taggedPersons || [];
  MOUNTED_USER_NOTE.note = note.note;
  MOUNTED_USER_NOTE.type = note.type;
  MOUNTED_USER_NOTE.subscriptionPackageId = note.subscriptionPackageId;
  MOUNTED_USER_NOTE.created = note.created;
  MOUNTED_USER_NOTE.removed = false;
};
