import { ref } from "vue";
import jwtDecode from "jwt-decode";
import { apiRequest, isSuccess } from "./request";
import {
  setAuthToken,
  getAuthToken,
  removeAuthToken,
  setRefreshToken,
  getRefreshToken,
  removeRefreshToken,
} from "../utils";
import { AUTH } from "../utils/APIs";
import { ROUTES } from "../routes/names";
import { AUTHENTICATION_URL } from "../utils/constants";
import HttpClient from "project-blue-http-client";
import { envs } from "../utils";

export const isSigningIn = ref(false);
export const LoginReqController = ref(new AbortController());
export const isValidatingToken = ref(false);
export const ValidateTokenReqController = ref(new AbortController());
export const isRefreshingToken = ref(false);
export const RefreshTokenReqController = ref(new AbortController());
export const isSigningOut = ref(false);
export const LogoutReqController = ref(new AbortController());
export const isResetingPassword = ref(false);
export const ResetPasswordReqController = ref(new AbortController());
export const isSigningUp = ref(false);
export const RegisterReqController = ref(new AbortController());

export const register = async (credentials) => {
  RegisterReqController.value.abort();
  RegisterReqController.value = new AbortController();
  isSigningUp.value = true;
  const response = await apiRequest(
    AUTH.REGISTER,
    "POST",
    {
      fullname: credentials.name,
      email: credentials.email,
      password: credentials.password,
      company: credentials.company,
      country: credentials.country,
      contactNumber: credentials.contactNumber,
      interests: credentials.interests || [],
      sectors: credentials.sectors || [],
      marketingPromotions: true,
    },
    RegisterReqController.value.signal,
    false
  );
  isSigningUp.value = false;

  if (response?.status === 409) window.$message.error("Email already exists");
  else if (isSuccess(response?.status)) return true;

  return false;
};

export const login = async (credentials) => {
  LoginReqController.value.abort();
  LoginReqController.value = new AbortController();
  isSigningIn.value = true;

  let data = {
    email: credentials.email,
    password: credentials.password,
  }

  let isAuthenticated = false;

  let client = new HttpClient(AUTHENTICATION_URL);
  await client.post("authentication/authenticate", data).then(response => {

    isAuthenticated = (response?.permissions ?? null) != null || response.status == 1;
    if (!isAuthenticated) {
      window.$message.error("Invalid username and password combination");
    }
    isSigningIn.value = false;
  });
  return isAuthenticated;
};


const renewTokenOrLogout = async (router) => {
  const isAuthorized = localStorage.getItem("Jwt") != "undefined";
  if (!isAuthorized) {
    await logout();
    router.push({ name: ROUTES.LOGIN.name });
  }
};

export const refreshToken = async () => {
  RefreshTokenReqController.value.abort();
  RefreshTokenReqController.value = new AbortController();
  isRefreshingToken.value = true;
  //pop up to re-auth
  isRefreshingToken.value = false;

  if (isSuccess(response?.status)) {
    // Note:
    // there may be some other request that will run along this that may fail,
    // better to find a way for this to finish first before running other requests
    const data = response.data;
    setAuthToken(data.accessToken);
    setRefreshToken(data.refreshToken);
    return true;
  }

  return false;
};

export const logout = async (router) => {
  LogoutReqController.value.abort();
  LogoutReqController.value = new AbortController();
  isSigningOut.value = true;
  await apiRequest(AUTH.LOGOUT, "POST", {}, LogoutReqController.value.signal);
  isSigningOut.value = false;

  removeAuthToken();
  removeRefreshToken();

  // router.push({ name: ROUTES.LOGIN.name });
  window.location.href = "/";
};

export const resetPassword = async (code, password) => {
  ResetPasswordReqController.value.abort();
  ResetPasswordReqController.value = new AbortController();
  isResetingPassword.value = true;

  let data = {
    resetPasswordCode: code,
    password: password
  };

  let baseUrl = envs("AUTHENTICATION");
  let client = new HttpClient(`${baseUrl}`);
  await client.post("/user/resetPassword", data)
    .then(() => {
      isResetingPassword.value = false;
    });
};
