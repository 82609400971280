<template>
  <div class="col-lg-6 mb-lg-3 mb-sm-3">
    <div class="card p-2 proxima-bg-light z-index-2" style="height: 98%">
      <div class="text-start p-1">
        <h3 class="card-title">Using the Project Blue Portal:</h3>
      </div>
      <div class="mt-2 card-body p-2">
        <p class="mt-2 text-justify card-text">
          Navigate through the Portal by using the menu on the left-hand side.
        </p>
        <p class="text-justify card-text">
          Click on
          <a class="user-guide-link" href="/#/app/files">"My Subscriptions"</a>
          to view and download all <b>market overview</b> and
          <b>market outlook</b> analysis, as well as
          <b>interactive data</b> files.
        </p>
        <p class="text-justify card-text">
          For <b>market analysis</b> tools - click
          <a class="user-guide-link" href="/#/app/proxima">"Proxima"</a>
          to access Project Blue's interactive supply chain analytics platform
          and see asset profiles, country profiles, company profiles and more.
           An interactive map detailing Proxima coverage is shown at the bottom
          of this page. 
        </p>
        <p class="text-justify card-text">
          For <b>market support</b>, please  send us a message via the dedicated
          chat function on the
          <a class="user-guide-link" href="/#/app/files">"My Subscriptions"</a>
          page.  
        </p>

        <p class="text-justify card-text">Best wishes,</p>
        <p class="text-justify card-text">The Project Blue team</p>
      </div>
    </div>
  </div>
</template>
