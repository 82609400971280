import { ref } from "vue";
import { mutation } from "gql-query-builder";
import HttpClient from "project-blue-http-client";
import { GQL_MUTATION } from "../../utils/APIs";
import { MARKETING_URL } from "../../utils/constants";
import axios from 'axios';

export const USER_FILES = ref({});
export const inProgress = ref(false);
export const reqSignal = ref(new AbortController());
export const RESPONSE = ref({});
export let isFetching = ref(false);
export const createLeadResponse = ref(null);
export const createLeadPayload = ref({
  LeadEmailAddress: null,
  Subject: null,
  Body: null,
});
export const LEAD_TYPE = ref({
  INQUIRY: "INQUIRY",
  MARKETING_NEWSLETTER: "MARKETING_NEWSLETTER",
  REQUEST_FOR_DEMO: "REQUEST_FOR_DEMO",
});
export const emailSubject = ref(null);

// export const createHttpClient = () => {
//   const baseURL = MARKETING_URL || "";
//   return new HttpClient(`${baseURL}`);
// };



export const sendInquiry = async (input) => {

  let client = new HttpClient(MARKETING_URL);

    try {
        if(input.leadType === LEAD_TYPE.value?.INQUIRY) emailSubject.value = "Someone sent an inquiry";
        if(input.leadType === LEAD_TYPE.value?.MARKETING_NEWSLETTER) emailSubject.value = "Someone signed-up to BlueView";
        if(input.leadType === LEAD_TYPE.value?.REQUEST_FOR_DEMO) emailSubject.value = "Someone requested for a demo";
  
        createLeadPayload.value = {
          LeadEmailAddress: input.email,
          Subject: emailSubject.value,
          Body: `<p>Details: </p>
          <p>First name: ${input.firstname || ''}</p>
          <p>Last name: ${input.lastname || ''}</p>
          <p>Contact number: ${input.contactNumberPrefix || ''} ${input.contactNumber || ''}</p>
          ${input.type === LEAD_TYPE.value?.INQUIRY ? `<p>interests: ${input.interests?.join(', ')}</p> <p>sectors: ${input.sectors?.join(', ')}</p>`: '' }
          ${[LEAD_TYPE.value?.INQUIRY, LEAD_TYPE.value?.REQUEST_FOR_DEMO].includes(input.type) ? `<p>message: ${input.message || ''}</p>` : '' }
          ${input.type === LEAD_TYPE.value?.REQUEST_FOR_DEMO ? `<p>Website Page: ${input.websitePage || ''}</p> <p>link: ${input.link || ''}</p>` : ''}
          `,
        }

        isFetching.value = true;
        await client.post('lead/createLead', createLeadPayload.value).then(() => {
          window.$message.success("Your request has been submitted.");
          RESPONSE.value = 200;
        });
      } catch (error) {
        console.log("CREATE_NEW_LEAD ERROR:", error.message);
        // window.location.href = "/500";
      }
   
}